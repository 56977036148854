import FetchService from "../../api/FetchService";
import React, { useState, useEffect } from "react";

import "./AnalyticsActivityPage.scss";
import { isLoggedIn } from "../../api/NewLoginService";
import Input from "../../components/Input/Input";
import { getActivityAnalyticsSortFilters } from "../../commons/activity-filters/Helpers";
import Select from "../../components/Select/Select";
import { API } from "../../api/API-Url";

function AnalyticsActivityPage() {
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");

  const sortFilters = getActivityAnalyticsSortFilters();
  const [sortFilter, setSortFilter] = useState("default");

  const fetchData = () => {
    FetchService.GetAnalyticsActivity().then((data) => {
      setFilteredActivities(data.data);
      setAllData(data.data);
    });
  };

  useEffect(() => {
    const filter = allData.filter((x) =>
      x.ActivityName.toLowerCase().includes(search)
    );
    setFilteredActivities(filter);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let result = allData;

    if (sortFilter === "viewsAsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return a.ViewCount - b.ViewCount;
        }),
      ];
    } else if (sortFilter === "viewsDsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return b.ViewCount - a.ViewCount;
        }),
      ];
    } else if (sortFilter === "ordersAsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return a.TotalOrders - b.TotalOrders;
        }),
      ];
    } else if (sortFilter === "ordersDsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return b.TotalOrders - a.TotalOrders;
        }),
      ];
    } else if (sortFilter === "salesAsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return a.TotalSales - b.TotalSales;
        }),
      ];
    } else if (sortFilter === "salesDsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return b.TotalSales - a.TotalSales;
        }),
      ];
    } else {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return b.ActivityId - a.ActivityId;
        }),
      ];
    }

    setFilteredActivities(result);
  }, [sortFilter]);

  return (
    <div className="order_page_main partner_order">
      <div className="heading_filter_otr"></div>
      <div className="searchContainer">
        <h1 className=" heading-lb">Partnere</h1>
        <div className="filter_search_otr">
          <div className="search_otr">
            <div className="sort_filter_container">
              <Input
                InputClass="Theme_input_white search_input"
                Inputype="search"
                InputName="search"
                InputPlaceholder="SØK"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Select
                options={sortFilters}
                defaultText="Acitivity Type"
                value={sortFilter}
                setValue={setSortFilter}
              />
              <form id="frm">
                <div className="downloadButton">
                  <div className="analytics_card_inr">
                    <a
                      href={
                        API.url +
                        "DownloadAnalytics?type=AllActivity&key=www.funtlers.com/analyse"
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className={`card_icon ri-file-list-fill`}></i>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="order_table">
        <table className="data_table">
          <thead className="table_header">
            <tr>
              <th>
                <p className="heading-xsb header_text">Aktivitets-ID</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Aktivitetsnavn</p>
              </th>
              <th>
                <p className="heading-xsb header_text">
                  Totalt antall visninger
                </p>
              </th>
              <th>
                <p className="heading-xsb header_text">Totale bestillinger</p>
              </th>

              <th>
                <p className="heading-xsb header_text">Totalt salg (NOK)</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Gyldig til</p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {filteredActivities.map((activity) => (
              <tr className="partner_row">
                <td>
                  <p className="heading-xs body_text">{activity.ActivityId}</p>
                </td>

                <td>
                  <p className="heading-xs body_text">
                    {activity.ActivityName}
                  </p>
                </td>
                <td>
                  <p className="heading-xs body_text">{activity.ViewCount}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{activity.TotalOrders}</p>
                </td>
                <td>
                  <p className="heading-xsb body_text">{activity.TotalSales}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{activity.ExpiryDate}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AnalyticsActivityPage;

import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Select from "../../components/Select/Select";
import FetchService from "../../api/FetchService";
import "./ActivityPage.scss";
import SearchResultCard from "../../components/SearchResultCard/SearchResultCard";
import Input from "../../components/Input/Input";
import CardImg1 from "../../img/search-card-img1.png";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";

function ActivityPage({ setIsLoading }) {



  const [activities, setActivities] = useState([]);

  const [visible, setVisible] = useState(100);

  const [filteredActivities, setFilteredActivities] = useState([]);
  const [priorityAsc, setPriorityAsc] = useState(true);
   const [isGroupBy, setiIsGroupBy] = useState(false);

    const [search, setSearch] = useState("");


    const currentUrl = window.location.href;
    // Parse the URL to extract the query string

    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    // Get the value of the "cat" parameter
    const catValue = decodeURIComponent(urlParams.get('cat'));

    const [type, settype] = useState(catValue && catValue != "null" ? "category" : "giftcard");



    debugger;
    
    //if (catValue) {
    //    settype("category");

    //}


  const [activityType, setActivityType] = useState("active");

    const navigate = useNavigate();

    const handleGroupByClick = () => {

        setiIsGroupBy(!isGroupBy);
    }

    const fetchData = async () => {

    setIsLoading(true);


    debugger;
        try {
        let response = null;
        response = type === "category"
            ? await FetchService.getAllActivitiesByCategory("category", encodeURIComponent(catValue))
                : await FetchService.getAllActivities();

         const data = response.data;
        data.map(x => {

            if (x.giftCardFavPriority && type =="giftcard") {
                x.isFavourite = true;
                x.favIndex = x.giftCardFavPriority-1;
                setiIsGroupBy(true);
            }
            if (x.categoryFavPriority && type=="category") {
                x.isFavourite = true;
                x.favIndex = x.categoryFavPriority-1;
                setiIsGroupBy(true);
            }

        })

      setActivities(data.filter((x) => !x.isDeleted));
      setFilteredActivities(data.filter((x) => !x.isDeleted && x.isValid));
      setIsLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const scrollToActivity = () => {
    const element = document.querySelector('.scrollToActivity');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  const asyncScrollToFUn = async () => {
    try {
      await fetchData();
      setTimeout(function () {
        scrollToActivity();
      }, 500);
    } catch (error) {
      console.error('Error in asyncScrollToFUn:', error);
    }
  };

  useEffect(() => {
    asyncScrollToFUn();
  }, []);

  useEffect(() => {
    if (activityType === "active")
      setFilteredActivities(activities.filter((x) => x.isValid));
    else if (activityType === "inactive")
      setFilteredActivities(activities.filter((x) => !x.isValid));
  }, [activityType]);

  useEffect(() => {
    const filter = activities.filter((x) =>
      x.name.toLowerCase().includes(search)
    );
    setFilteredActivities(filter);
  }, [search]);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 100);
    };


    const setShuffleClick = (id) => {
        // Create a new copy of the activity object and update the isFavourite property
        const updatedActivities = filteredActivities.map(obj => {
            obj.id === id ? obj.isShuffle = true : obj.isShuffle = false;
            return obj;
        });

        // Update the state with the new array
        setFilteredActivities(updatedActivities);
    };


    const setFavClick = async (id) => {
        debugger;


        setiIsGroupBy(true);
        let favIndex = filteredActivities.filter(obj => obj?.isFavourite == true).length;
        // Create a new copy of the activity object and update the isFavourite property
        const updatedActivities = filteredActivities.map(obj =>
            obj.id === id ? { ...obj, isFavourite: true, favIndex: favIndex } : obj
        );



        const response = await FetchService.UpdateFavPriority(type == "category" ? catValue : type, id,favIndex);
        const data = response.data;



        // Update the state with the new array
        setFilteredActivities(updatedActivities);
    };

    const setFavSwitchClick = async (id, switchid) => {
        debugger;

        let filteredid = filteredActivities.filter(x => x.id === id);
        let switchidArr = filteredActivities.filter(x => x.id === switchid);

        // Ensure both objects exist in the array
        if (filteredid.length > 0 && switchidArr.length > 0) {
            // Destructure the objects
            const [filteredObj] = filteredid;
            const [switchObj] = switchidArr;

            // Switch favIndex values
            const updatedFilteredActivities = await Promise.all(
                filteredActivities.map(async obj => {
                    if (obj.id === filteredObj.id) {
                        await FetchService.UpdateFavPriority(type == "category" ? catValue : type, obj.id, switchObj.favIndex);
                        return { ...obj, favIndex: switchObj.favIndex };
                    } else if (obj.id === switchObj.id) {
                        await FetchService.UpdateFavPriority(type == "category" ? catValue : type, obj.id, filteredObj.favIndex);
                        return { ...obj, favIndex: filteredObj.favIndex };
                    } else {
                        return { ...obj };
                    }
                })
            );

            // Update the state or variable with the new array
            setFilteredActivities(updatedFilteredActivities);
        }
    };


    const setFavDelClick = async (id) => {
        debugger;

        let maxid = null;
        setiIsGroupBy(true);
        let favIndex = null;

        // Create a new copy of the activity object and update the isFavourite property
        let updatedActivities = await Promise.all(filteredActivities.map(async (obj, index) => {
            if (obj.id === id) {
                maxid = id;
                favIndex = obj.favIndex;
                const response = await FetchService.UpdateFavPriority(type == "category" ? catValue : type, id, null);
                const data = response.data;
                return { ...obj, isFavourite: false, favIndex: null };
            } else {
                return { ...obj };
            }
        }));

        // Fix index for the remove elements
        await Promise.all(updatedActivities.filter(obj => obj.isFavourite).map(async (obj, index) => {
            debugger;
            if (obj.favIndex > favIndex) {
                const response = await FetchService.UpdateFavPriority(type == "category" ? catValue : type, obj.id, index);
                const data = response.data;
            }
            obj.favIndex = index;

            return obj;
        }));

        // Update the state with the new array
        setFilteredActivities(updatedActivities);
    };


    const togglepriorityAscOrder = () => {
        setPriorityAsc(!priorityAsc);
    };


  return (
    <div className="activity_page_main">
      <br />
      <div className="heading_filter_otr">
        <p className="heading_activity heading-lb">
          Aktivitet ({filteredActivities.length})
        </p>
              <div className="filter_search_otr">
                  <div>
                      <button className="Theme_btn_primary p-12" onClick={handleGroupByClick}>
                          <svg width="23" height="28" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 1h2v1H2v12h1.5v1h-2l-.5-.5v-13l.5-.5zM14 3v4h-4V6H8v1H5V4h3v1h2V3h4zM7 5H6v1h1V5zm6-1h-2v2h2V4zm-3 5h4v4h-4v-2H8v1H5V9h3v1h2V9zm-3 1H6v1h1v-1zm4 2h2v-2h-2v2z" />
                          </svg>
                          Skjul / Vis favoritter
                  </button>
               </div>
          <div className="search_otr">
                   
            <Input
              InputClass="Theme_input_white search_input"
              Inputype="search"
              InputName="search"
              InputPlaceholder="SØK"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <ThemeBtn
            BtnClass="Theme_btn_primary"
            BtnText="Legg til aktivitiet"
            onClick={() => {
              navigate("/add-aktivitet");
            }}
          />
        </div>
      </div>
      <div className="radio-group">
        <label className="radio" onClick={() => setActivityType("active")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={activityType === "active"}
          />
          {"Aktive aktiviteter"}
        </label>
        <label className="radio" onClick={() => setActivityType("inactive")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={activityType === "inactive"}
          />
          {"Inaktive aktiviteter"}
        </label>
          </div>
          {(filteredActivities?.filter(obj => obj?.isFavourite === true && isGroupBy).length > 0 ) &&   <div className="row row_custom">
              <div class="activity_page_main elementor-element elementor-element-affcb75 elementor-widget elementor-widget-heading" data-id="affcb75" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container heading_filter_otr">
                      <h5 class="elementor-heading-title elementor-size-default"><i className="ri ri-star-line edit_icon"></i>
                          &nbsp;{type === "category" ? 'Kategori favoritter' : 'Gavetips favoritter' }</h5>
                      <button className="no-padding Theme_btn_primary" onClick={togglepriorityAscOrder}>
                          <svg xmlns="http://www.w3.org/2000/svg" style={{
                              height: '33px', width: '33px', transform: !priorityAsc ? 'rotate(180deg)' : 'rotate(0deg)'
                          }}> viewBox="0 0 32 40" x="0px" y="0px">
                              <g>
                                  <path fill="white" d="M6.17,2.45A5,5,0,0,0,1.25,7.51a5,5,0,0,0,4.92,5.06,5,5,0,0,0,4.92-5.06A5,5,0,0,0,6.17,2.45Zm0,8.62A3.5,3.5,0,0,1,2.75,7.51,3.5,3.5,0,0,1,6.17,4,3.5,3.5,0,0,1,9.59,7.51,3.5,3.5,0,0,1,6.17,11.07Z" />
                                  <path fill="white" d="M9.63,14.1A3.47,3.47,0,1,0,13,17.56,3.43,3.43,0,0,0,9.63,14.1Zm0,5.43a2,2,0,1,1,1.87-2A1.92,1.92,0,0,1,9.63,19.53Z" />
                                  <path fill="white" d="M13,26.08a3.38,3.38,0,1,0-6.75,0,3.43,3.43,0,0,0,3.38,3.47A3.43,3.43,0,0,0,13,26.08Zm-5.25,0a1.88,1.88,0,1,1,1.88,2A1.92,1.92,0,0,1,7.75,26.08Z" />
                                  <path fill="white" d="M13.39,7h3.4a.75.75,0,0,0,0-1.5h-3.4a.75.75,0,0,0,0,1.5Z" />
                                  <path fill="white" d="M13.39,9.53H20A.75.75,0,0,0,20,8H13.39a.75.75,0,0,0,0,1.5Z" />
                                  <path fill="white" d="M15.75,17h2.48a.75.75,0,0,0,0-1.5H15.75a.75.75,0,0,0,0,1.5Z" />
                                  <path fill="white" d="M20.55,17.71h-4.8a.75.75,0,0,0,0,1.5h4.8a.75.75,0,1,0,0-1.5Z" />
                                  <path fill="white" d="M15.75,25.5h2.48a.75.75,0,1,0,0-1.5H15.75a.75.75,0,0,0,0,1.5Z" />
                                  <path fill="white" d="M15.75,27.73h4.8a.75.75,0,0,0,0-1.5h-4.8a.75.75,0,0,0,0,1.5Z" />
                                  <path fill="white" d="M27.29,7.54A1.93,1.93,0,0,0,26,7a2,2,0,0,0-1.42.56l-2.89,2.85A1.95,1.95,0,0,0,24,13.48V24.23a2,2,0,1,0,3.9,0V13.48a1.94,1.94,0,0,0,2.3-3.09Zm1.83,4.55a.43.43,0,0,1-.63,0l-.85-.83a.74.74,0,0,0-1.27.53V24.23a.45.45,0,1,1-.9,0V11.79A.75.75,0,0,0,25,11.1a.72.72,0,0,0-.29-.06.79.79,0,0,0-.53.22l-.84.83a.45.45,0,1,1-.63-.63L25.6,8.61a.49.49,0,0,1,.33-.13h0a.43.43,0,0,1,.3.13l2.89,2.85A.44.44,0,0,1,29.12,12.09Z" />
                                  <path fill="white" d="M6.17,5.7a.74.74,0,0,0-.75.75V8.57a.75.75,0,0,0,1.5,0V6.45A.75.75,0,0,0,6.17,5.7Z" /></g></svg>

                      </button>
                  </div>
              </div>
              {filteredActivities
                  .filter(obj => obj?.isFavourite === true)
                  .sort((a, b) => (priorityAsc ? a.favIndex - b.favIndex : b.favIndex - a.favIndex)) // Sort by index
                  .slice(0, visible)
                  .map((activity) => (
                      <React.Fragment key={activity.id}>
                          <SearchResultCard
                              activity={activity}
                              IconDivClass="edit_icon_otr"
                              IconClass="ri-edit-fill"
                              isManualOrder="true"
                              CardImg={
                                  JSON.parse(activity.images)
                                      ? JSON.parse(activity.images)[0].imageURL
                                      : CardImg1
                              }
                              HandleFavClick={setFavClick}
                              HandleFavDelClick={setFavDelClick}
                              HandleFavSwitchClick={setFavSwitchClick }
                              FavouritesData={filteredActivities.filter(obj => obj?.isFavourite === true && obj.id != activity.id)}
                              CardHeading={activity.name}
                              CardDesc={
                                  activity.price +

                                  (activity.priceModel === "time" ? "kr per time" : activity.priceModel === "person"
                                      ? "kr per person"
                                      : "kr per aktivitet")
                              }
                          />
                      </React.Fragment>
                  ))}

          </div>}
          <div className="row row_custom">
              {(filteredActivities?.filter(obj => obj?.isFavourite === true && isGroupBy).length > 0  ) && <div class="elementor-element elementor-element-affcb75 elementor-widget elementor-widget-heading" data-id="affcb75" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                      <h5 class="elementor-heading-title elementor-size-default"><i className="ri ri-filter-line edit_icon"></i>
                          &nbsp;Alle andre</h5> </div>
              </div>}
              {filteredActivities.slice(0, visible).filter(obj => !obj?.isFavourite).map((activity) => (
                  <React.Fragment key={activity.id}>
                      <SearchResultCard
                          activity={activity}
                          IconDivClass="edit_icon_otr"
                          IconClass="ri-edit-fill"
                          isManualOrder="true"
                          CardImg={
                              JSON.parse(activity.images)
                                  ? JSON.parse(activity.images)[0].imageURL
                                  : CardImg1
                          }
                          HandleShuffleClick={() => setShuffleClick(true)}  

                          HandleFavClick={setFavClick}

                          CardHeading={activity.name}
                          CardDesc={
                              activity.price +
                              (activity.priceModel === "time" ? "kr per time" : activity.priceModel === "person"
                                  ? "kr per person"
                                  : "kr per aktivitet")
                          }
                      />
                  </React.Fragment>
              ))}

          </div>

         
      {/* Todo: implement pagination */}
      {/* <div className="action_otr">
        <ThemeBtn
          BtnClass="Theme_btn_primary load_more_btn"
          BtnText="Last mer"
        />
      </div> */}
      <div className="action_otr">
        {/* hide button when its last page or activities are less than 6 to fit on one page */}
        {/* {pageNumber < totalPages && (
          <button onClick={showMoreItems} className="Theme_btn_primary">
            {"Last mer"}
          </button>
        )} */}
      </div>
    </div>
  );
}

export default ActivityPage;

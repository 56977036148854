import React from "react";
import FeatureCard from "../FeatureCard/FeatureCard";
import SectionHeadingDesc from "../SectionHeadingDesc/SectionHeadingDesc";

import "./FeaturesSection.scss";

function FeaturesSection() {
  return (
    <section className="Features_main">
      <div className="container">
        <SectionHeadingDesc
          Heading="Slik fungerer det."
          Desc="Funtler drives av to gode venninner som tror på å skape lykke for deg selv og de man er glad i."
        />
        <div className="row row_custom">
          <FeatureCard
            IconClass="ri-heart-fill Icon"
            IconClass2="ri-heart-fill Icon2"
            CardHeading="Søk etter aktivitet"
            CardDesc="Se gjennom våre rabatterte forslag og les mer om de aktivitetene som virker interessante for deg."
          />
          <FeatureCard
            IconClass="ri-heart-fill Icon"
            IconClass2="ri-heart-fill Icon2"
            // CardTitle="Enjoy time"
            CardHeading="Kjøp aktivitet"
            CardDesc="Velg din aktivitet, og kjøp rabatten direkte hos oss. Du vil få en bookingbekreftelse på din mail med en unik kode fra Funtlers. Denne må du bruke for å booke dato direkte med aktivitetsleverandør. Steg til bestilling av tid ligger i bookingbekreftelsen. Koden fungerer som ditt betalingsbevis!"
          />
          <FeatureCard
            IconClass="ri-heart-fill Icon"
            IconClass2="ri-heart-fill Icon2"
            // CardTitle="Enjoy time"
            CardHeading="Gled deg"
            CardDesc="Nå er det bare å glede seg til enda et minne i opplevelsesbanken"
          />
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;

import React, { useState, useRef, useEffect } from 'react';
import "./SearchResultCard.scss";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../api/API-Url";



function SearchResultCard(props) {
  let activity;
  //the rerouting from this component will be different if it is coming from customer side.
  if (props.source === "customer") {
    activity = props.Data;
    activity.userType = "customer";
  } else activity = props.activity;

  let res = activity.name.replace(/ /g, '-');
  res = res.replace(/\//g, '-');

  const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isshuffle, setIsshuffle] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const inputRef = useRef(null);

    

    const handleMouseOver = () => {
        setIsHovered(true);
    }

    const handleMouseOut = () => {
        setIsHovered(false);
    }


    const handleDropdownChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };





    const toActivityDetails = () => {

    if (props.source === "customer" || props.source === "partner"){
      localStorage.setItem('aktiviteter', activity.id);
      return navigate("/aktivitet/"+res+"?id="+activity.id);
    }
    if(window.location.pathname === '/partner-aktiviteter')
      localStorage.setItem('partner-aktiviteter', props.partnerId);
    if(window.location.pathname === '/aktivitet-side')
      localStorage.setItem('aktivitet-side', activity.id);
    navigate("/redigere-aktivitet", { state: activity });
  };

    return (
        <div className={`col-lg-3 col-md-4 col-sm-6 col_searchCard_otr col_searchCard_inr ${(localStorage.getItem('aktivitet-side') === activity.id || localStorage.getItem('aktiviteter') === activity.id) &&
                (window.location.pathname === '/aktivitet-side' || window.location.pathname === '/aktiviteter')
                ? "scrollToActivity"
                : ""
            }`}>


            <div className="outer-elemt">
                {activity?.favIndex >= 0 && activity?.favIndex !== null && <div
                    className={`step edit_icon_otr icon_otr ${activity?.favIndex + 1 > 99 ? 'more-than-three' : ''}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    {activity?.favIndex >= 0 && activity?.favIndex !== null ? (
                        isHovered ? (
                            !isshuffle ? (
                                <i className="ri-shuffle-line edit_icon" onClick={() => {
                                    setIsshuffle(!isshuffle)

                                }}></i>
                            ) : (
                                    <i className="ri-shuffle-line edit_icon" onClick={() => {
                                        setIsshuffle(!isshuffle)

                                    }}></i>     
                            )
                        ) : (
                            <>{ activity?.favIndex + 1 < 10 ? `0${activity?.favIndex + 1}` : activity?.favIndex + 1}</>
                        )
                    ) : null}
                </div>


                }

                {isshuffle && <div className="heading_filter_otr input-control-swt">
              
                    <div className="w-10 p-1"></div>
                    <svg className="bg-white w-20" xmlns="http://www.w3.org/2000/svg"  height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16.05 12.05L21 17l-4.95 4.95l-1.414-1.415L17.172 18H4v-2h13.172l-2.536-2.535zm-8.1-10l1.414 1.414l-2.536 2.535H20v2H6.828l2.536 2.536L7.95 11.95L3 7z" /></svg>
                    <select className="Theme_select replace-texts w-50 bg-white" value={selectedValue} onChange={handleDropdownChange}>
                        <option value="0" disabled>
                            Bytt med verdiene
                        </option>
                        {
                            props.FavouritesData.map((option) => (
                                <option key={option.favIndex+1} value={option.id}>
                                    {option.favIndex+1}
                            </option>
                        ))}
                    </select>

                    <button className="w-25 Theme_btn_primary p-1" onClick={(e) => {
                        e.stopPropagation(); // Add this line to stop event propagation
                        setIsshuffle(!isshuffle);
                        debugger;
                        props.HandleFavSwitchClick(props.activity.id, Number(selectedValue));
                    }} >erstatte</button>
                </div>}

    {/* <Link class="outer-elemt"*/}
    {/*  onClick={() => {*/}
    {/*    if(props.source === "customer" || props.source === "partner") return localStorage.setItem('aktiviteter', activity.id);*/}
    {/*    toActivityDetails();*/}
    {/*  }}*/}
    {/*  to={props.source === "customer" || props.source === "partner"? "/aktivitet/"+res+"?id="+activity.id : ""}*/}
    {/*>*/}
                <div>

                    <div className="img_otr">

                        {props.source != "customer" &&
                            <div className="card-menu">

                                <div className={window.location.href.includes("partner-aktiviteter") ? 'm9 edit_icon_otr icon_otr' : 'edit_icon_otr icon_otr'} onClick={(e) => {
                                    e.stopPropagation(); // Add this line to stop event propagation
                                    if (props.source === "customer" || props.source === "partner") return localStorage.setItem('aktiviteter', activity.id);
                                    toActivityDetails();
                                }}>
                                    <i className={`ri-edit-fill edit_icon`}></i>
                                </div>


                                {activity?.favIndex == undefined && !window.location.href.includes("partner-aktiviteter") && <div className={`edit_icon_otr icon_otr`} onClick={(e) => {
                                    e.stopPropagation(); // Add this line to stop event propagation
                                    props.HandleFavClick(props.activity.id);
                                }}>
                                    <i className="ri-star-fill edit_icon"></i>
                                </div>
                                }


                                {activity?.favIndex >= 0 && activity?.favIndex !== null && <div className={`edit_icon_otr icon_otr`} onClick={(e) => {
                                    e.stopPropagation(); // Add this line to stop event propagation
                                    props.HandleFavDelClick(props.activity.id);
                                }}>
                                    <i className="ri-delete-bin-2-line edit_icon"></i>
                                </div>
                                }



                            </div>
                        }
          <img
            className="card_img"
             src={props.CardImg.replace("{baseApiURL}", "https://funtlers.com/")}
            alt="img"
          />
          {activity.discountPercent>0&&activity.discountPercent<100? <div><p className="rabat_card_info shadow">-{activity.discountPercent}%</p></div> : ""}
        </div>
        <div className="content">
          <Link className="heading-lb card_heading" to={props.source === "customer" || props.source === "partner"? "/aktivitet/"+res+"?id="+activity.id : ""}>{props.CardHeading}</Link>
            <p
                className="heading-s card_desc_activity text-center"
                            dangerouslySetInnerHTML={{ __html: props.CardDesc.replace(/<br\s*\/?>/gi, '').replace(/\n/g, '<br />') }}
            />
       </div>
     



      </div>
            {/*  </Link>*/}

    {
        props.isManualOrder === "true" && (
            <div>
                        <Link to={"/aktivitet/" + res + "?id=" + activity.id + "&isManualOrder=1"} class="Theme_btn_primary load_more_btn text-center" >
                    Manuell bestilling
                </Link>
            </div>
        )
                }

            </div>
        </div>
    )
}

export default SearchResultCard;

import InnerHeader from "../InnerHeader/InnerHeader";
import SearchResultCard from "../SearchResultCard/SearchResultCard";
import Select from "../Select/Select";
import React, { useEffect, useState, useMemo } from "react";
import FetchService from "../../api/FetchService";
import {
  getFilters,
  getMinMax,
  getBudget,
  getNumberOfPeopleOptions,
  getFilteredActivities,
  getLabelByValue,
  getValidActivities,
  getDescriptionByValue
} from "../../commons/activity-filters/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import "./SearchResultPage.scss";
import bookingImg from "../../img/booking-confirmation-img.png";
import LoadingOverlay from "react-loading-overlay";

import ReactGA from "react-ga4";

let filterModel = {
  category: "all",
  type: "all",
  city: "all",
  peopleNumber: "all",
  budget: "all",
};

function SearchResultPage() {


  const searchFilter = useLocation().state;
  const navigate = useNavigate();
  const filters = getFilters();
  const [city, setCity] = useState(searchFilter?.city);
  const [type, setType] = useState(searchFilter?.type);
  const [gettedFromSession, setGettedFromSession] = useState(false)

  let categoryLabel =
    getLabelByValue(filters.categories, searchFilter?.category!=""? searchFilter?.category : "gratis") ?? "";

  let categoryDescription = getDescriptionByValue(filters.categories, searchFilter?.category!=""? searchFilter?.category : "gratis") ?? "";

  const category = searchFilter?.category ?? "";
  const [peopleNumber, setPeopleNumber] = useState(searchFilter?.peopleNumber);
  const [budget, setBudget] = useState(searchFilter?.budget);
  const [isScrolled, setIsScrolled] = useState(false);

  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const [visible, setVisible] = useState(8);
  const [isLoading, setIsLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const [totalPages, setTotalPages] = useState(0);

  const scrollToActivity = () => {
    const element = document.querySelector('.scrollToActivity');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Możesz zmienić na 'end', jeśli chcesz przewinąć do końca elementu
      });
    }
  };

  //useMemo function that will set page number to 1 if any filter is changed.
  // because then we want the activities array to be reset to zero.
  const filterChanged = useMemo(
      () => {
          debugger;
      if((city!=undefined && city!="")|| (type!=undefined && type!="") || category!="" || (peopleNumber!=undefined && peopleNumber!="") || (budget!=undefined && budget!=""))
      {
        const sessionCheck = JSON.parse(sessionStorage.getItem("SearchResult"));
        //if(sessionCheck?.pages[0].category.category==category) return;
        clearSession();
        setPageNumber(1);
      }

      },
    [city, type, category, peopleNumber, budget]
  );

    function getPaginatedActivities() {
      ReactGA.event({
        action:'Search_action',
        category:'Search_category',
        label:'Search_label',
        value:"Click on Search Button"
      })

        let session = getSession();
        //console.log(session);
        if (session && !gettedFromSession) {
            setGettedFromSession(true)
            setActivities(session.activity);
            setActivitiesCount(session.pages[0]?.total);
            setTotalPages(session.pages[0]?.totalPages);
            setVisible(session.activity.length+1);
            setPageNumber(session.pageNo);

            setIsLoading(false);
            if(!isScrolled){
              setIsScrolled(true);
              setTimeout(function(){
                scrollToActivity();
              }, 500);
            }
        }
        else
        {
    setIsLoading(true);

    const filter = getFilterRequestModel();
    let apicall2 = FetchService.getActivitiesByPagination(filter);

    apicall2
      .then((response) => {
        return response.data;
      })
      .then((data) => {
          setActivitiesCount(data.pages[0]?.total);
          setTotalPages(data.pages[0]?.totalPages);
          //if pagenumber is 1, that means we need to emppty array instead of concat
          if (pageNumber === 1) {
          setActivities(data.activity);
        } else {
          //concat array on load more button click instead of creating a new list
          data.activity = activities.concat(data.activity);
           setActivities(data.activity);
          }
          data.pageNo=pageNumber;
          data.pages[0].category = filter;
          setSession(data);
          setIsLoading(false);
      });
     }

    }

    function clearSession ()
    {
      setSession(null);
    }

    function setSession(obj) {
      sessionStorage.setItem("SearchResult", JSON.stringify(obj));
    }

    function getSession() {
      return JSON.parse(sessionStorage.getItem("SearchResult"));
    }

  //This method return a filter object that will be needed to be passed to the backend
  //to return paginated data.
  function getFilterRequestModel() {
    //getting updated filtermodel from the respective states
    setFilterModel();

    let filter = {
      pageNo: 1,
      rowCountPerPage: 8,
      isValid: true,
      isLive: true,
      city: null,
      type: null,
      category: null,
      minPrice: -1,
      maxPrice: -1,
      minPerson: -1,
      maxPerson: -1,
    };

      debugger;
    filter.category = filterModel.category;
    filter.type = filterModel.type;
    filter.city = filterModel.city;

    [filter.minPerson, filter.maxPerson] = getMinMax(filterModel.peopleNumber);
    [filter.minPrice, filter.maxPrice] = getBudget(filterModel.budget);

    filter.pageNo = pageNumber;

    return filter;
  }

  function setFilterModel() {
    filterModel.category = category === "" ? "all" : category;
    filterModel.type = type === "" ? "all" : type;
    filterModel.city = city === "" ? "all" : city;
    filterModel.peopleNumber = peopleNumber === "" ? "all" : peopleNumber;
    filterModel.budget = budget === "" ? "all" : budget;
  }

  useEffect(() => {
    getPaginatedActivities("filter");
    console.log(searchFilter?.category)
  }, [city, type, category, peopleNumber, budget, pageNumber]);

    const showMoreItems = () => {
    clearSession();
    setPageNumber(pageNumber + 1);
    setVisible((prevValue) => prevValue + 8);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    filterModel.type = type;
    filterModel.category = category;
    filterModel.budget = budget;
    filterModel.peopleNumber = peopleNumber;
    filterModel.city = city;
    sessionStorage.setItem("SearchResult", null);
    navigate("/aktiviteter", { state: filterModel });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
    >
      <div className="searchRsult_page">
        <InnerHeader HeaderHeading={categoryLabel===""?"Søkeresultat":categoryLabel} PageText={categoryLabel!==""?"Søkeresultat":categoryLabel} qty={activitiesCount > 0 ? activitiesCount : 0} />
        {/* //todo: show the label not the actual value
        <h2 className="header_heading text-center">{categoryLabel}</h2> */}

        <div className="searchRsult_main">
          <div className="container">
            <div className="wrapper">
              <p className="heading filter_heading">
                              {categoryDescription.replace(/dagsevent/gi, "pa kontoret").replace(/julebord/gi, "firmafest")}
              </p>
              <div className="filter_main">
              <form onSubmit={handleSubmit} className="d-flex">
                <div className="activity_main">
                  <ul className="activity_ul">
                    <li className="activity_li" >
                      <Select
                        options={filters.types}
                        defaultText="Acitivity Type"
                        value={type}
                        setValue={setType}
                      />
                    </li>
                    <li className="activity_li" >
                      <Select
                        value={city}
                        setValue={setCity}
                        options={filters.cities}
                        defaultText="Cities"
                        disabled={type === "digital"}
                      />
                    </li>
                    <li className="activity_li" >
                      <Select
                        value={budget}
                        setValue={setBudget}
                        options={filters.budgetOptions}
                        defaultText="Budget Options"
                      />
                    </li>
                    <li className="activity_li" >
                      <Select
                        setValue={setPeopleNumber}
                        value={peopleNumber}
                        options={filters.peopleNumber}
                        defaultText="Number of people"
                      />
                    </li>
                  </ul>
                  <div className="search_icon_otr_local">
                    <button
                      className="ri-search-2-line search_icon_local search-button"
                      type="submit"
                    ></button>
                  </div>
                </div>
                </form>
                {/* todo: hide it for now */}
                {false && (
                  <div className="filter_otr">
                    <Select defaultText="Flere filtere" />
                  </div>
                )}
              </div>
            </div>
            <div className="row row_custom">
              {activities?.slice(0, visible).map((activity) => (
                <SearchResultCard
                  CardImg={
                    activity.images
                      ? JSON.parse(activity.images)?.[0].imageURL
                      : bookingImg
                  }
                  CardRabat={activity.discountPercent}
                  CardHeading={activity.name}
                      CardDesc={
                          activity.price>0?
                          activity.price +
                              (activity.priceModel === "time" ? "kr per time" : activity.priceModel === "person"
                                  ? "kr per person"
                                  : "kr per aktivitet") : "Gratis"
                      }
                  Data={activity}
                  source="customer"
                />
              ))}
            </div>

            <div className="action_otr">
              {/* hide button when its last page or activities are less than 6 to fit on one page */}
              {pageNumber < totalPages && activitiesCount > 6 && (
                <button onClick={showMoreItems} className="Theme_btn_primary">
                  {"Last mer"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}


export default SearchResultPage;

import React, { useState, useEffect } from "react";
import Select from "../../components/Select/Select";
import FetchService from "../../api/FetchService";

import "./OrderPage.scss";

import ProfileImg from "../../img/profile-img.png";
import { Link } from "react-router-dom";
import AnalyticsPage from "../Analytics/AnalyticsPage";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import { getOrderAnalyticsSortFilters } from "../../commons/activity-filters/Helpers";
import { set } from "react-ga";
import { API } from "../../api/API-Url";

function OrderPage({ setIsLoading }) {
  const navigate = useNavigate();

  const [filteredPartners, setFilteredPartners] = useState([]);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");

  const sortFilters = getOrderAnalyticsSortFilters();
  const [sortFilter, setSortFilter] = useState("default");

  const fetchData = () => {
    FetchService.GetOrderByPartnerId(1, "all").then((data) => {
      setFilteredPartners(data.data);
      setAllData(data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (search !== "") {
      const filter = allData.filter((x) => x.Id == search);
      setFilteredPartners(filter);
    } else setFilteredPartners(allData);
  }, [search]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    let result = allData;

    if (sortFilter === "priceAsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return a.price - b.price;
        }),
      ];
    } else if (sortFilter === "priceDsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return b.price - a.price;
        }),
      ];
    } else {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return b.Id - a.Id;
        }),
      ];
    }

    setFilteredPartners(result);
  }, [sortFilter]);

  return (
    <div className="order_page_main">
      <div className="heading_filter_otr">
        <p className="heading_activity heading-lb">Salgshistorikk</p>
        {/* <div className="filter_main">
          <p className="sort_text heading-xs">Sort By:</p>
          <Select />
        </div> */}
      </div>
      <AnalyticsPage setIsLoading={setIsLoading} />
      <div className=" mt-5">
        <div className="searchContainer">
          <p className="heading_activity heading-lb mb-2">Ordre</p>
          <div className="filter_search_otr">
            <div className="search_otr">
              <div className="sort_filter_container">
                <Input
                  InputClass="Theme_input_white search_input"
                  Inputype="search"
                  InputName="search"
                  InputPlaceholder="SØK"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
                <Select
                  options={sortFilters}
                  defaultText="Acitivity Type"
                  value={sortFilter}
                  setValue={setSortFilter}
                />
                <form id="frm">
                  <div className="downloadButton">
                    <div className="analytics_card_inr">
                      <a
                        href={
                          API.url +
                          "DownloadAnalytics?type=Orders&key=www.funtlers.com/analyse"
                        }
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className={`card_icon ri-file-list-fill`}></i>
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="order_table">
          <table className="data_table">
            <thead className="table_header">
              <tr>
                <th>
                  <p className="heading-xsb header_text">Ordre Nummer</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Kundenavn</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Partner</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Kode</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Aktivitet</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">By</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Pris</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Handling</p>
                </th>
              </tr>
            </thead>
            <tbody className="table_body">
              {filteredPartners.map((obj) => (
                <tr
                  className="partner_row"
                  onClick={() => {
                    navigate("/bestilt-aktivitet", { state: obj.Id });
                  }}
                >
                  {" "}
                  <td>
                    <p className="heading-xs body_text">{obj.Id}</p>
                  </td>
                  <td>
                    <div className="profile_otr">
                      <img className="profile_img" src={ProfileImg} alt="img" />
                      <p className="heading-xs profile_name">
                        {obj.FirstName} {obj.LastName}
                      </p>
                    </div>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{obj.PartnerName}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{obj.Code}</p>
                  </td>
                  <td>
                    <p className="heading-xsb body_text">{obj.ActivityName}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{obj.City}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{obj.price} nok</p>
                  </td>
                  <td>
                    <div className="icon_otr">
                      <Link to="/bestilt-aktivitet" className="icon_inr">
                        <button class="Theme_btn_primary partner-page-button activity">
                          Utsikt
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderPage;

import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/nb'; // Import Norwegian locale for dayjs
import './CalenderComponent.css'; // Import your CSS file

export default function CalendarComponent({ setSelectedDate, selectedDate, setShowCalender }) {
    dayjs.locale('nb'); // Set the locale for dayjs to Norwegian

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setShowCalender(false);
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        console.log(formattedDate);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="nb-NO">
            <DateCalendar
                onChange={handleDateChange}
                value={selectedDate}
            />
        </LocalizationProvider>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import "./InnerHeader.scss";

function InnerHeader(props) {
    console.log(props?.HeaderHeading);
    return (
        <>  {(window.location.pathname.split('/')[1] == "aktiviteter" || window.location.pathname.split('/')[1] == "bestilt-aktivitet") &&
            <div className="container">
                <div className="inner_header_main"> <div className="header_wrapper">
                    {window.location.pathname.split('/')[1] == "aktiviteter" || window.location.pathname.split('/')[1] == "bestilt-aktivitet" ? <>{props?.HeaderHeading.replace("Dagsevent", "Pa kontoret").replace("Julebord", "Firmafest")} {props?.qty ? "(" + props?.qty + ")" : ""} </> : ""}
                    {/* Todo: removing inner headr for now
          {props.hideHome !== true && (
            <p className="heading-m header_desc_link">
              <span>{props.PageText}</span>
              |
              <Link to="/" className="linkk_home">
                {" "}
                Home
              </Link>
            </p>
          )} */}
                </div>
                </div>

            </div>
        }
        </>

    );
}

export default InnerHeader;

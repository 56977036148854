import React,{useState} from 'react'
import AboutSection from '../AboutSection/AboutSection'
import CampaignPage from '../CampaignPage/CampaignPage'
import ContactSection from '../ContactSection/ContactSection'
import FeaturesSection from '../FeaturesSection/FeaturesSection'
import Hero from '../Hero/Hero'
import TeamBuildingSection from '../TeamBuildingSection/TeamBuildingSection'
import "./LandingPage.scss"
import PartnerImg from "../../img/Stripe_icon_-_square.svg";

function LandingPage({setIsLoading}) {

    const [extendVar, setExtend] = useState(false);

    const extend = () =>{
        setExtend(!extendVar);
    }

    return (
        <>
            {/*<div className={`partnere ${extendVar? "extend_css" : ""}`} onClick={extend}>
                <p className={"vare_patnere"}>klarert betalingspartner</p>
                <div className="partere-image">
                    <p>STRIPE</p>
                    <img src={PartnerImg} alt="Stripe"/>
                </div>
                  <div className='landing_contact_otr'>
                <ContactSection />
            </div>
    </div> */}


          

            <Hero setIsLoading={setIsLoading} />
          
        </>
    )
}

export default LandingPage
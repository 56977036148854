import React from "react";
import "./ActivityCard.scss";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../api/API-Url";

export function ActivityCard(props) {
  let activity = props.data;

  const navigate = useNavigate();
  const toActivityDetails = () => {
    navigate("/activity-details", { state: activity });
  };

  return (
    <>
      <a
        onClick={() => {
          toActivityDetails();
        }}
      >
        <div className="activityCard">
          <img src={activity.imagePath.replace("{baseApiURL}", API.apiURLs)}  alt="activity-image"/>{" "}
          <div className="activityInfo"> {activity.name} </div>
        </div>
      </a>
    </>
  );
}

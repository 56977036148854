import React from "react";
import { Link } from "react-router-dom";

import "./PartnerDashboardSidebar.scss";

import BrandLogo from "../../img/brand-logo-primary.png";
import BrandLogoIcon from "../../img/logo-icon.svg";
import CloseIcon from "../../img/close-icon.svg";
function PartnerDashboardSidebar(props) {
  return (
    <div
      className={
        props.SidebarAddClass
          ? "Dashboard_Sidebar Dashboard_Sidebar_strech"
          : "Dashboard_Sidebar"
      }
    >
      <div className="sidebar_close_icon" onClick={props.SidebarStrech}>
        <img className="close_icon" src={CloseIcon} alt="img" />
      </div>
      <Link to="/" className="logo_otr">
        <img className="logo_icon" src={BrandLogoIcon} alt="logo" />
        <img className="brand_logo" src={BrandLogo} alt="logo" />
      </Link>
      <div className="sidebar_menu_main">
        <ul className="sidebar_menu_ul">
          <li className="sidebar_menu_li" onClick={props.CloseSideBar}>
            <Link to="/" className="sidebar_menu_link ">
              <i class="ri-home-4-fill menu_icon"></i>
                          <p className="menu_text heading-m">Hjem</p>
            </Link>
          </li>
          <li className="sidebar_menu_li" onClick={props.CloseSideBar}>
            <Link to="/orderplan/add1" className="sidebar_menu_link ">
              <i class="ri-home-4-fill menu_icon"></i>
                          <p className="menu_text heading-m">Enkel bestilling</p>
            </Link>
          </li>
          <li className="sidebar_menu_li" onClick={props.CloseSideBar}>
                      <Link to="/orderplan/add2" className="sidebar_menu_link">
              <i class="ri-shopping-cart-fill menu_icon"></i>
                          <p className="menu_text heading-m">Sosial Kalender</p>
            </Link>
          </li>

          <li className="sidebar_menu_li" onClick={props.CloseSideBar}>
                      <Link to="/orderplans/singlestatus" className="sidebar_menu_link">
              <i class="ri-service-fill menu_icon"></i>
                          <p className="menu_text heading-m">Bestillingsinformasjon</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

}

export default PartnerDashboardSidebar;

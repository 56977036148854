import React, { useState } from "react";

import Input from "../../components/Input/Input";
import FetchService from "../../api/FetchService";
import { useLocation } from "react-router-dom";
import "./EditPartner.scss";
import LoadingOverlay from "react-loading-overlay";
import DialogueBox from "../../components/DialogueBox/DialogueBox";

//userTypeID = 1 because its for customers
//Todo: edit this object model
let partnerRequest = {
  id: 0,
    firstname: "",
    lastname: "",
    address: "",
    email: "",
    phone: "",
};

function EditPartner2() {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  
  const location = useLocation();
    const partner = location.state;

    debugger;

    const [firstname, setFirstName] = useState(partner.firstname);
    const [lastname, setLastName] = useState(partner.lastname);
    const [address, setAddress] = useState(partner.address);
    const [email, setEmail] = useState(partner.email);
    const [phone, setPhone] = useState(partner.phone);

  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
      partnerRequest = partner;
      partnerRequest.firstname = firstname;
      partnerRequest.lastname = lastname;
      partnerRequest.address = address;
      partnerRequest.phone = phone;
      partnerRequest.partnerSince = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      partnerRequest.email = email;



    FetchService.UpdateCompany(partnerRequest).then((response) => {
      if (response) {
        setResponse("Success");
        clearForm();
      } else {
        setResponse("Failed");
      }
    });
  };
  const DeletePartner = () => {
    setIsLoading(true);

    FetchService.DeleteCompany(partner.id).then((response) => {
      if (response) {
        setTimeout(() => {
          setResponse("Success");
          clearForm();
          setIsLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setResponse("Failed");
          setIsLoading(false);
        }, 500);
      }
    });
  };


  function clearForm() {
      setFirstName("");
      setLastName("");
      setAddress("");
      setPhone("");
      
      setEmail("");
      setIsLoading(false);

  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
    >
      <div className="Register_page">
        <section className="">
          {showConfirmDelete && (
            <DialogueBox
              title={"Bekreft sletting"}
              onConfirm={DeletePartner}
              onConfirmState={null}
              confirmText={"Slette"}
              cancelText={"Avbryt"}
              open={showConfirmDelete}
              setOpen={setShowConfirmDelete}
              stayOnPage={true}
            >
              Er du sikker på at du vil slette denne bedriftskunden?{" "}
            </DialogueBox>
          )}
          <div className="container">
            <div className="row w-100">
              <div className="px-2 col_form_otr">
                <div className="col_form_inr">
                   <h3 className="heading-h3 form_heading">Rediger Bedriftskunder</h3>
                  {response === "Failed" && (
                    <div className="error_message">
                      {
                        "Feil: Kunne ikke oppdatere bedriftskunder. Vær så snill, prøv på nytt"
                      }
                    </div>
                  )}
                  {response === "Success" && (
                    <div className="success_message">
                      {"Bedriftskunder er oppdatert"}
                    </div>
                                  )}


                                  <form onSubmit={handleSubmit} className="form_main">
                                      <div className="Input_otr">
                                          <Input
                                              InputClass="Theme_input_white form_input"
                                              Inputype="text"
                                              InputName="First name"
                                              label="Fornavn"
                                              value={firstname}
                                              required={false}

                                              onChange={(event) => setFirstName(event.target.value)}
                                          />
                                      </div>
                                      <div className="Input_otr">
                                          <Input
                                              InputClass="Theme_input_white form_input"
                                              Inputype="text"
                                              InputName="Last name"
                                              label="Etternavn"
                                              value={lastname}
                                              required={false}

                                              onChange={(event) => setLastName(event.target.value)}
                                          />
                                      </div>

                                      <div className="Input_otr">
                                          <Input
                                              InputClass="Theme_input_white form_input"
                                              Inputype="text"
                                              InputName="address"
                                              label="Adresse"
                                              value={address}
                                              required={false}

                                              onChange={(event) => setAddress(event.target.value)}
                                          />
                                      </div>


                                      <div className="Input_otr">
                                          <Input
                                              InputClass="Theme_input_white form_input"
                                              label="Email"
                                              value={email}
                                              required={false}

                                              onChange={(event) => setEmail(event.target.value)}
                                          />
                                      </div>

                            


                                      <div className="Input_otr">
                                          <Input
                                              InputClass="Theme_input_white form_input"
                                              Inputype="number"
                                              InputName="phone"
                                              label="Telefonnummer"
                                              value={phone}
                                              required={false}

                                              onChange={(event) => setPhone(event.target.value)}
                                          />
                                      </div>



                                      <div className="Input_otr action_otr">
                                          <button
                                              type="submit"
                                              className="Theme_btn_primary form_btn"
                                          >
                                              Legg til
                                          </button>
                                      </div>


                                      <div className="m-2">
                                              <button
                                                type="button"
                                                onClick={() => setShowConfirmDelete(true)}
                                                className="Theme_btn_primary form_btn bg-danger"
                                              >
                                              Slette Bedriftskunder
                                          </button>
                                    </div>

                                  </form>

                  {/*<form onSubmit={handleSubmit} className="form_main">*/}
                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="text"*/}
                  {/*      InputName="name"*/}
                  {/*      label="Navn"*/}
                  {/*      value={name}*/}
                  {/*      onChange={(event) => setName(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="text"*/}
                  {/*      InputName="address"*/}
                  {/*      label="Adresse"*/}
                  {/*      value={address}*/}
                  {/*      onChange={(event) => setAddress(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="text"*/}
                  {/*      InputName="city"*/}
                  {/*      label="By"*/}
                  {/*      value={city}*/}
                  {/*      onChange={(event) => setCity(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="text"*/}
                  {/*      InputName="zip"*/}
                  {/*      label="Postkode"*/}
                  {/*      value={zip}*/}
                  {/*      onChange={(event) => setZip(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      label="Email"*/}
                  {/*      value={email}*/}
                  {/*      onChange={(event) => setEmail(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="number"*/}
                  {/*      InputName="phone"*/}
                  {/*      label="Telefon"*/}
                  {/*      value={phone}*/}
                  {/*      onChange={(event) => setPhone(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr">*/}
                  {/*    <Input*/}
                  {/*      InputClass="Theme_input_white form_input"*/}
                  {/*      Inputype="text"*/}
                  {/*      InputName="URL"*/}
                  {/*      label="Nettsted"*/}
                  {/*      value={URL}*/}
                  {/*      onChange={(event) => setURL(event.target.value)}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/*  <div className="Input_otr ">*/}
                  {/*    <button*/}
                  {/*      onClick={GenerateCodes}*/}
                  {/*      type="button"*/}
                  {/*      className=" "*/}
                  {/*    >*/}
                  {/*      generere koder*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="Input_otr action_otr">*/}
                  {/*    <button*/}
                  {/*      type="submit"*/}
                  {/*      className="Theme_btn_primary form_btn"*/}
                  {/*    >*/}
                  {/*      Oppdatert partner*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="m-2">*/}
                  {/*    <button*/}
                  {/*      type="button"*/}
                  {/*      onClick={() => setShowConfirmDelete(true)}*/}
                  {/*      className="Theme_btn_primary form_btn bg-danger"*/}
                  {/*    >*/}
                  {/*      Slette partner*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*</form>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LoadingOverlay>
  );
}

export default EditPartner2;

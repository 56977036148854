import axios from "axios";

class APIServices {


    GetSecretToken() {
        let authstring = 'ZnVudGxlcnMuY29tOkNAdHMtYW5kLURvZ3MtTGl2aW5nLXRvZ2V0aGVy'
        let datestring = new Date().toUTCString()

        return btoa(authstring + ',' + datestring);
    }

    /**
     * Get - METHOD WILL CALL GET URL
     * @param {*} url - url passed on the get
     */
    GET(url) {
        return axios.get(url, {
            "headers": {
                Authorization: this.GetSecretToken() //the token is a variable which holds the token
            }
        } );
    }

    /**
     * POST - METHOD WILL CALL POST API
     * @param {*} url - url that data will be posted on.
     * @param {*} message - message passed on the url.
     * @returns - response from the post api.
     */
    POST(url, message) {
        return axios.post(url, message, {
            "headers": {
                Authorization: this.GetSecretToken() //the token is a variable which holds the token
            }
        });
    }

    /**
     * PUT - Updating data to the URL
     * @param {*} url - api url
     * @param {*} message - data that will be updated
     * @returns - response contains status for update
     */
    PUT(url, message) {
        return axios.put(url, message, {
            "headers": {
                Authorization: this.GetSecretToken() //the token is a variable which holds the token
            }
        });
    }

    /**
     * DELETE - METHOD WILL DELETE GET URL
     * @param {*} url - url passed on the get
     */
    DELETE(url) {
        return axios.delete(url, {
            "headers": {
                Authorization: this.GetSecretToken() //the token is a variable which holds the token
            }
        });
    }

}

export default new APIServices();
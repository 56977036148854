export const API = {
    //live url
    //const url ="https://891127-www.web.tornado-node.net/api/";

    apiurl: window.location.origin+"/",
    url: window.location.origin + "/api/",
    //apiURLs: window.location.origin + "/",
    // local dev url
    //url: "http://localhost:27409/api/",
    //apiURL: "http://localhost:27409/",
    apiURLs: window.location.origin + "/",
    baseURL: window.location.origin

};
import React, { useEffect, useState } from 'react';
import "./Header.scss";
import { Link } from "react-router-dom";
import ThemeBtn from "../ThemeBtn/ThemeBtn";

import BrandLogo from "../../img/brand-logo.png";
import BrandLogo2 from "../../img/brand-logo-primary.png";

import { isLoggedIn, logOut } from "../../api/NewLoginService";
import LoginPopup from "../LoginPopup/LoginPopup";
import DialogueBox from "../DialogueBox/DialogueBox";

import { useLocation, useNavigate } from "react-router-dom";
import CustomerDashboardHeader from "../../Dashboard/CustomerDashboardHeader/CustomerDashboardHeader";

function Header({ setUser, userObject, setShowLogin, showLogin }) {

  const navigate = useNavigate();
  const [SideBar, setSideBar] = useState(false);

  // const [userObject, setUser] = useState(isLoggedIn());

  const SideMenuClick = () => {
    setSideBar(!SideBar);
  };

  const [showConfirmDialogue, setConfirmDialogue] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
    };
 const handleToggle = () => {
     setOpenMenu(!openMenu);
    };



    useEffect(() => {
        const dropdown = document.getElementsByClassName('dropdown');
        const handleClose = (event) => {
            // Check if the click target is outside the dropdown
            if (dropdown && !dropdown[0]?.contains(event.target)) {
                // If the click is outside, close the dropdown
                handleCloseMenu();
            }
        }

        // Add a click event listener to the document
        document.addEventListener('click', handleClose);


        // Remove the click event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, []);




  if (showLogin) {
    sessionStorage.removeItem("loggin")
    return (
      <div>
        <div className="backdrop">
          <LoginPopup setShowLogin={setShowLogin} setUser={setUser} />
        </div>
      </div>
    );
  }
  if (showConfirmDialogue) {
    return (
      <DialogueBox
        title={"Bekreft utlogging"}
        onConfirm={logOut}
        onConfirmState={setUser}
        confirmText={"Logg ut"}
        cancelText={"Avbryt"}
        open={showConfirmDialogue}
        setOpen={setConfirmDialogue}
      >
        Er du sikker på at du vil logge ut?{" "}
      </DialogueBox>
    );
  }
  return (
      <>
    
    
          <nav class="navbar top-navbar sticky-top navbar-expand">
              <div class="container-fluid fn-container">
                  <Link to="/" className="navbar-brand">
                      <img src={BrandLogo2} alt="Funtlers" />
                  </Link>
                  <button  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav ms-auto">
                          {!userObject ? (
                              <>
                                  <li className="nav-item">
                                          <ThemeBtn
                                              onClick={() => setShowLogin(true)}
                                              BtnClass="nav-link"
                                              BtnText="Bedriftsportal"
                                          />
                                  </li>
                              <li className="nav-item">
                                  <ThemeBtn
                                      onClick={() => setShowLogin(true)}
                                      BtnClass="nav-link"
                                          BtnText="Partnerportal"
                                  />
                                  </li>
                                
                              </>
                          ) : (
                              <div className="action_otr">
                                  <button
                                      className="Theme_btn_primary"
                                      onClick={() => {
                                          navigate("/my-page");
                                      }}
                                  >
                                      <u> {userObject?.user?.firstName}</u>{" "}
                                  </button>
                                  <Link className="action">
                                      <ThemeBtn
                                          onClick={() => setConfirmDialogue(true)}
                                          BtnClass="Theme_btn_white"
                                          BtnText="Logg ut"
                                      />
                                  </Link>
                              </div>
                          )}
                          <li class="nav-item">
                              <div class="dropdown">
                                  <button onClick={handleToggle} class="btn d-flex justify-content-center without-focus" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24">
                                          <rect width="38" height="4" rx="2" fill="#fff"></rect>
                                          <rect width="38" height="4" rx="2" transform="translate(0 10)" fill="#fff"></rect>
                                          <rect width="38" height="4" rx="2" transform="translate(0 20)" fill="#fff"></rect>
                                      </svg>
                                  </button>
                                  <ul className={`dropdown-menu dropdown-menu-end ${openMenu ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
                                      <li>
                                          <Link onClick={handleToggle} to="/hvorfor-velge-oss" className="dropdown-item">
                                              Slik fungerer det
                                          </Link>
                                      </li>
                                      <li>
                                          <Link onClick={handleToggle} to="/vilkar" className="dropdown-item">
                                              Vilkår
                                          </Link>
                                      </li>
                                      <li>
                                          <Link onClick={handleToggle}  to="/omtrent" className="dropdown-item">
                                              Om oss
                                          </Link>
                                      </li>
                                      <li>
                                          <Link onClick={handleToggle} to="/kontakt" className="dropdown-item">
                                              Kontakt
                                          </Link>
                                      </li>
                                      <li>
                                          <Link onClick={handleToggle} to="/partneren" className="dropdown-item">
                                              Bli partner
                                          </Link>
                                      </li>
                                      <li>
                                          <Link onClick={handleToggle}  to="/faq" className="dropdown-item">
                                              FAQ
                                          </Link>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
    </>
  );
}

export default Header;

import FetchService from "../../api/FetchService";
import React, { useState, useEffect } from "react";

import "./AnalyticsCouponsPage.scss";
import { isLoggedIn } from "../../api/NewLoginService";
import Input from "../../components/Input/Input";
import { getActivityAnalyticsSortFilters } from "../../commons/activity-filters/Helpers";
import Select from "../../components/Select/Select";
import { API } from "../../api/API-Url";

function AnalyticsCouponsPage() {
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");

  const sortFilters = [{value:"viewsAsc",label:"Bruker stigende"},{value:"viewsDsc",label:"Bruk i fallende rekkefølge"}]
  const [sortFilter, setSortFilter] = useState("default");

  const fetchData = () => {
    FetchService.GetCouponsAnalitics().then((data) => {
      setFilteredActivities(data.data);
      setAllData(data.data);
    });
  };

  useEffect(() => {
    const filter = allData.filter((x) =>
      x.activityName.toLowerCase().includes(search) || x.code.toLowerCase().includes(search)
    );
    setFilteredActivities(filter);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let result = allData;

    if (sortFilter === "viewsAsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return a.totalHitUsed - b.totalHitUsed;
        }),
      ];
    } else if (sortFilter === "viewsDsc") {
      result = [
        ...filteredActivities.sort(function (a, b) {
          return b.totalHitUsed - a.totalHitUsed;
        }),
      ];
    }

    setFilteredActivities(result);
  }, [sortFilter]);

  return (
    <div className="order_page_main partner_order">
      <div className="heading_filter_otr"></div>
      <div className="searchContainer">
        <h1 className=" heading-lb">Kuponger</h1>
        <div className="filter_search_otr">
          <div className="search_otr">
            <div className="sort_filter_container">
              <Input
                InputClass="Theme_input_white search_input"
                Inputype="search"
                InputName="search"
                InputPlaceholder="SØK"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Select
                options={sortFilters}
                defaultText="Acitivity Type"
                value={sortFilter}
                setValue={setSortFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="order_table">
        <table className="data_table">
          <thead className="table_header">
            <tr>
              <th>
                <p className="heading-xsb header_text">KUPONG-ID</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Navn på aktivitet</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Kode</p>
              </th>
              <th>
                <p className="heading-xsb header_text">
                  Treff
                </p>
              </th>
              <th>
                <p className="heading-xsb header_text">
                  Verdt
                </p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {filteredActivities.map((activity) => (
              <tr className="partner_row">
                <td>
                  <p className="heading-xs body_text">{activity.id}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">
                    {activity.activityName}
                  </p>
                </td>
                <td>
                  <p className="heading-xs body_text">
                    {activity.code}
                  </p>
                </td>
                <td>
                  <p className="heading-xs body_text">{activity.totalHitUsed}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{activity.worth}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AnalyticsCouponsPage;

import React from "react";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
import { useNavigate } from "react-router-dom";
import "./PartnerLandingPage.scss";

function PartnerLandingPage() {
    const navigate = useNavigate();
    sessionStorage.removeItem("SearchResult");
    sessionStorage.removeItem("filter");



    return (
        <div className="container-landing admin_landingpage">
            <h1 className="text-center admin_heading fw-bold">SOSIAL PLANLEGGINGSVERKTØY</h1>
            <div className="d-flex justify-content-center" >
                <p className="text-center col-md-10 mb-4">ET GODT SOSIAL TILBUD TILTREKKER TALENT, FORBEDRER ARBEIDSMILJØET OG ØKER TRIVSELEN BLANT DE ANSATTE. <br />NÅ ER DET ENKELT Å PLANLEGGE GØYE AKTIVITETER Å GJØRE SAMMEN MED TEAMET, LEDERGRUPPEN ELLER HELE KONTORET!</p>
            </div>

            <div className="mb-4" >
                <img style={{width:"100%"}}  src="partnerDash.png" />
            </div>

            <div className="body-landing">
                <div className="item-landing">
                    <h3 className="text-center fw-bolder">PLANLEGG NÅ</h3>
                    <ThemeBtn
                        onClick={() => {
                            navigate("/orderplan/add1");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="FINN ENGANGSAKTIVITET"
                    />
                    <ThemeBtn
                        onClick={() => {
                            navigate("/orderplan/edit1");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="REDIGER ENGANGSAKTIVITET"
                    />
                    {/*<ThemeBtn*/}
                    {/*    onClick={() => {*/}
                    {/*        navigate("/orderplan/all");*/}
                    {/*    }}*/}
                    {/*    BtnClass="Theme_btn_primary "*/}
                    {/*    BtnText="SE ALLE "*/}
                    {/*/>*/}
                </div>
                <div className="item-landing">
                    <h3 className="text-center fw-bolder">PLANLEGG FREMOVER </h3>
                    <ThemeBtn
                        onClick={() => {
 

                            navigate("/orderplan/add2");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="LAG SOSIAL KALENDER"
                    />
                    <ThemeBtn
                        onClick={() => {
                           
                            navigate("/orderplan/edit2");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="REDIGER SOSIAL KALENDER"
                    />
                    {/*<ThemeBtn*/}
                    {/*    onClick={() => {*/}
                    {/*     navigate("/orderplan/all");*/}
                    {/*    }}*/}
                    {/*    BtnClass="Theme_btn_primary "*/}
                    {/*    BtnText="SE ALLE"*/}
                    {/*/>*/}
                </div>
                <div className="item-landing">
                    <h3 className="text-center fw-bolder">STATUS BESTILLINGER</h3>
                    <ThemeBtn
                        onClick={() => {
                            
                            navigate("/orderplans/singlestatus");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="SE ENKEL BESTILLINGER"
                    />
                    <ThemeBtn
                        onClick={() => {
                            
                            navigate("/orderplans/multistatus");
                        }}
                        BtnClass="Theme_btn_primary "
                        BtnText="SE ÅRSHJUL BESTILLINGER"
                    />
                    {/*<ThemeBtn*/}
                    {/*    onClick={() => {*/}
                          
                    {/*        navigate("/orderplan");*/}
                    {/*    }}*/}
                    {/*    BtnClass="Theme_btn_primary "*/}
                    {/*    BtnText="SE ALLE "*/}
                    {/*/>*/}
                </div>
                </div>
        </div>
    );
}

export default PartnerLandingPage;
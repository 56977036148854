import React,{useEffect, useState} from "react"
import { useLocation, useNavigate } from "react-router-dom";
import FetchService from "../../api/FetchService";
import "./SearchEditFunctional.scss"
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
const SearchEditFunctional = ({setIsLoading}) =>{
    const location = useLocation();
    const EditCategory = location.state
    const [categoryActivities, setCategoryActivities] = useState([])

    const navigate = useNavigate();

    const fetchData = () => {
        setIsLoading(true)
        let apicall = FetchService.GetSearchInfo(EditCategory);
        apicall
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            console.log(data)
            setCategoryActivities(data)
            setIsLoading(false)
          });

    };

    useEffect(()=>{
        fetchData();
    },[])

    const handleInput = (props) =>{
        const index = parseInt(props.target.dataset.index)
        const val = parseInt(props.target.value);
        const newActivites = [...categoryActivities];

        newActivites[index].activity_id = val

        setCategoryActivities(newActivites)
    }

    const handleUpdate = () =>{
        setIsLoading(true)

        let apicall = FetchService.UpdateSearch(categoryActivities);
        apicall
          .then((response) => {
            alert('Status: '+response.data)
            return window.location.reload()
          })

    }

    return (
        <div className="edit_functional">
            <h2>Kategori: {EditCategory}</h2>
            <table>
                <tr>
                    <th>Posisjon</th>
                    <th>Navn</th>
                    <th>Aktivitets-ID</th>
                </tr>
                {categoryActivities.map((position,index)=>(
                    <tr key={index}>
                        <td>{position.position}</td>
                        <td>{position.activity_name}</td>
                        <td><input type="text" value={position.activity_id} data-index={index} onChange={handleInput} /></td>
                    </tr>
                ))}
            </table>
            <button
              type="button"
              className="Theme_btn_primary h-50 bg-danger mt-2"
              onClick={handleUpdate}
            >Oppdater</button>

        </div>
    )
}

export default SearchEditFunctional;
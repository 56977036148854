import React from "react";
import AccordionFaq from "../AccordionFaq/AccordionFaq";
import SectionHeadingDesc from "../SectionHeadingDesc/SectionHeadingDesc";
import Accordion from "react-bootstrap/Accordion";

import "./FaqPage.scss";
import InnerHeader from "../InnerHeader/InnerHeader";

function FaqPage() {
  return (
    <div className="faq_page">
      <InnerHeader HeaderHeading="FAQ" PageText="FAQ" />
      <div className="faq_main">
        <div className="container">
          <SectionHeadingDesc
            Heading="Ofte stilte spørsmål"
            Desc=" Her har vi samlet nyttig informasjon. Dersom ditt spørsmål ikke blir besvart her, er du hjertelig velkommen til å kontakte oss direkte på"
            LinkPath="/kontakt"
            LinkClass="contact_link"
            LinkText="contact@funtlers.com "
          />
          <div className="row row_custom">
            <div className="col-lg-8 col_faq_otr">
              <div className="col_faq_inr">
                <Accordion defaultActiveKey="0" className="accordion_main">
                  <AccordionFaq
                    AccordionKey="0"
                    AccordionHeading="Hvordan bestiller man hos dere som privatperson? "
                    AccordionDesc="Du ser gjennom utvalget og klikker deg inn på den aktiviteten som er av interesse for deg. Du velger antall personer du ønsker å kjøpe til og godkjenner kjøpsvilkår. Du vil se hvor mye penger du sparer, og hvor mye du skal betale. Du klikker deretter på kjøpsknappen dersom kjøpet er til deg selv, og fyller inn din epost adresse. Du vil da bli sendt til vår betalingspartner Stripe, og etter gjennomført betaling få en bookingbekreftelse på din epost."
                  />
                  <AccordionFaq
                    AccordionKey="1"
                    AccordionHeading="Hvordan kjøper man gavekort hos dere?"
                    AccordionDesc="Du ser gjennom utvalget og klikker deg inn på den aktiviteten som du ønsker å gi i gave. Du velger antall personer du ønsker å kjøpe til og godkjenner kjøpsvilkår. Du vil se hvor mye penger du sparer, og hvor mye du skal betale for gaven. Du klikker deretter på Kjøp gavekort knappen, og fyller inn din epost adresse. Du vil da bli sendt til vår betalingspartner Stripe, og etter gjennomført betaling få en gavekortet sendt på din epost. Dette kan du laste ned/printe ut eller videresende til ønsket mottaker."
                  />
                  <AccordionFaq
                    AccordionKey="2"
                    AccordionHeading="Hvordan bestiller man hos dere som bedrift?"
                    AccordionDesc={`Du ser gjennom utvalget og velger ut aktiviteten du ønsker å gjennomføre. Du ser over kjøpsvilkår og kontakter <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a>. Kundeservice vil hjelpe deg med å skreddersy din opplevelse ift antall personer og hvilken aktivitet du ønsker å gjennomføre. Har du en spesifikk dato du ønsker å gjennomføre på, gjerne inkluder dette i mailen også. Vi tilbyr faktura til våre bedriftskunder, og rabatten gjelder selvsagt bedrifter også.`}
                  />
                  <AccordionFaq
                    AccordionKey="3"
                    AccordionHeading="Kan jeg få gavekortet jeg kjøpte tilsendt før jul?"
                    AccordionDesc="Hos oss får du gavekortet tilsendt på din epost øyeblikkelig etter kjøpet er godkjent. Du kan derfor bestille gavekort 24/7 hos oss."
                  />
                  <AccordionFaq
                    AccordionKey="4"
                    AccordionHeading="Kan dere hjelpe med firmagaver?"
                    AccordionDesc={`Selvsagt. Vi hjelper dere gjerne med å gi en hyggelig oppmerksomhet til deres ansatte. Ta kontakt med oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a>, så hjelper vi dere raskt.`}
                  />
                  <AccordionFaq
                    AccordionKey="5"
                    AccordionHeading="Hvordan betaler jeg?"
                    AccordionDesc={`Vi er samarbeidspartner med betalingsløsningen Stripe. Stripe oppfyller alle krav til sikker betaling på nett, og godtar bla Visa, Mastercard og American Express. <br />
                    Til våre bedriftskunder tilbyr vi også faktura. Ta isåfall kontakt på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a> med aktiviteten du ønsker å kjøpe og antall personer. Vi tilbyr per dags dato ikke faktura til våre privatkunder. `}
                  />
                  <AccordionFaq
                    AccordionKey="6"
                    AccordionHeading="Hvordan booker jeg tid til aktiviteten?"
                    AccordionDesc={`I bookingsbekreftelsen eller gavekortet ditt får du stegvis forklaring til hvordan booke tid med leverandøren. Dersom du vil sikre ledig tid på en spesifik dato/klokkeslett før du gjennomfører kjøpet hos oss, tar du kontakt med oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a> så hjelper vi deg gjerne.`}
                  />
                  <AccordionFaq
                    AccordionKey="7"
                    AccordionHeading="Må jeg booke tid når jeg kjøper opplevelsen?"
                    AccordionDesc="Nei, du kan også velge å ikke booke tid med leverandøren med en gang. Rabatten vil være gyldig i hele gyldighetsperioden. Gyldighetsperioden står på bookingbekreftelsen din eller på gavekortet du har fått."
                  />
                  <AccordionFaq
                    AccordionKey="8"
                    AccordionHeading="Hva er frist for booking?"
                    AccordionDesc={`Gyldighetsperioden står på bookingbekreftelsen din eller på gavekortet du har fått. Dersom gyldighetsperioden snart løper ut, kan du kontakte oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a> slik at vi kan bistå deg i bookingen. Vi hjelper deg gjerne.`}
                  />
                  <AccordionFaq
                    AccordionKey="9"
                    AccordionHeading="Hvor gjennomføres aktiviteten?"
                    AccordionDesc={`Aktivitetsted finner du på aktivitetssiden før kjøp, under kjøp og på din bookingbekreftelse etter kjøp. Dersom du likevel skulle bli usikker på adressen til aktiviteten, så kan du kontakte oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a>. Oppgi da din unike kode og aktivitetsnavn, så hjelper vi deg gjerne.`}
                  />
                  <AccordionFaq
                    AccordionKey="10"
                    AccordionHeading="Kan jeg angre mitt kjøp?"
                    AccordionDesc={`Ja selvsagt. Vi tilbyr åpent kjøp i 14 dager, så du kan fritt benytte angreretten din inntil 14 dager etter du gjennomførte kjøpet ditt. Merk at aktiviteten ikke kan ha blitt booket tid til eller ha blitt brukt. Isåfall må du ta kontakt med leverandøren direkte. Er du usikker på noe så kan du gjerne ta kontakt med oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a>, så hjelper vi deg gjerne. Du kan også laste ned angrerettskjema her (insert url that activates: it is an  angrerettskjema as attached to this email. When a customer clicks on this url, it should automatically downloads to their computer) `}
                  />
                  <AccordionFaq
                    AccordionKey="11"
                    AccordionHeading="Kan jeg utsette gyldigheten som står på min bookingbekreftelse?"
                    AccordionDesc={`Nei, aktivitetene er gyldig i den perioden levernandøren tilbyr aktiviteten til rabattert pris. Etter gyldighetsperioden har gått ut vil ikke leverandøren kunne tilby samme rabatt lenger. Vi oppfordrer derfor til å benytte aktiviteten før perioden utløper. Hvis du er sent ute med bookingen, så tar du kontakt med oss - så hjelper vi deg raskt på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a> `}
                  />
                  <AccordionFaq
                    AccordionKey="12"
                    AccordionHeading="Jeg fikk ikke min bookingbekreftelse, hva gjør jeg?"
                    AccordionDesc={`Sjekk gjerne spam-innboksen din. Dersom den ikke har kommet i innboksen eller spam boksen din mot formodning, må du gjerne ta kontakt med oss på <a style="display: inline;" href='${window.location.origin}/kontakt'>support@funtlers.com</a>. Oppgi hvilken aktivitet du kjøpte, når du kjøpte og til hvor mange personer, så finner vi deg i internsystemet - og sender bookingbekreftelsen i retur.`}
                  />
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;

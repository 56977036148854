import React from "react";
import "./OrderPlanSearchResultCard.scss";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../api/API-Url";
import ThemeBtn from "../ThemeBtn/ThemeBtn";


function OrderPlanSearchResultCard(props) {
  let activity;
  //the rerouting from this component will be different if it is coming from customer side.
  if (props.source === "customer") {
    activity = props.Data;
    activity.userType = "customer";
  } else activity = props.activity;

  let res = activity.name.replace(/ /g, '-');
    res = res.replace(/\//g, '-');
    res = res.replace(/\?/g, '');


  const navigate = useNavigate();

  const toActivityDetails = () => {
    if (props.source === "customer" || props.source === "partner"){
      localStorage.setItem('aktiviteter', activity.id);
      return navigate("/aktivitet/"+res+"?id="+activity.id+"&type=B2B");
    }
    if(window.location.pathname === '/partner-aktiviteter')
      localStorage.setItem('partner-aktiviteter', props.partnerId);
    if(window.location.pathname === '/aktivitet-side')
      localStorage.setItem('aktivitet-side', activity.id);
    navigate("/redigere-aktivitet", { state: activity });
  };

    return (
        <div  draggable onDragStart={props.handleDrag} className={` col-lg-3 col-md-4 col-sm-6 col_searchCard_otr col_searchCard_inr ${(localStorage.getItem('aktivitet-side') === activity.id || localStorage.getItem('aktiviteter') === activity.id) &&
                (window.location.pathname === '/aktivitet-side' || window.location.pathname === '/aktiviteter')
                ? "scrollToActivity"
                : ""
            }`}>
            
     <div class="outer-elemt" >
      <div>
                    <div className="position-relative img_otr">

                        <div class="card-menu">

                            <div className={`m9 edit_icon_otr icon_otr`} onClick={(e) => {
                                debugger;
                            e.stopPropagation(); // Add this line to stop event propagation
                                if (props.source === "customer" || props.source === "partner") {
                                    toActivityDetails();
                                    return localStorage.setItem('aktiviteter', activity.id);
                                }
                           
                        }}>
                                <i className={`ri-search-line  edit_icon`}></i>
                            </div>

                            </div>
          <img
            className="card_imgS"
                            src={props.CardImg.replace("{baseApiURL}", "https://funtlers.com/")}
            /*{src={props.CardImg.replace("{baseApiURL}", API.apiURLs)}}*/
            alt="img"
          />
          {activity.discountPercent>0&&activity.discountPercent<100? <div><p className="rabat_card_info shadow">-{activity.discountPercent}%</p></div> : ""}
        </div>
        <div className="content">
                        <Link className="heading-lb card_heading" to={props.source === "customer" || props.source === "partner" ? "/aktivitet/" + res + "?id=" + activity.id + "&type=B2B" : ""}>{props.CardHeading}</Link>
          <p className="heading-s card_desc_activity text-center">
            {props.CardDesc}
          </p>
       </div>

        <div>
            <ThemeBtn
            BtnClass={`Theme_btn_primary w-100 send_btn ${!activity.isVelgClick ? '' : 'Theme_btn_black'} ${!props.isVelgDisable ? 'disabled' : ''}`}
            BtnText={!activity.isVelgClick ? "VELG" :"VALGT" }   
            onClick={props.clickFire}

            />

        </div>



      </div>
      </div>
    {
        props.isManualOrder === "true" && (
            <div>
                        <Link to={"/aktivitet/" + res + "?id=" + activity.id + "&isManualOrder=1"} class="Theme_btn_primary load_more_btn text-center" >
                    Manuell bestilling
                </Link>
            </div>
        )
            }
            </div>
    )



}

export default OrderPlanSearchResultCard;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import InnerHeader from "../InnerHeader/InnerHeader";
import Input from "../Input/Input";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import { register } from "../../api/LoginService";

import LoadingOverlay from "react-loading-overlay";

import "./ResetPassword.scss";
import { isLoggedIn } from "../../api/NewLoginService";
import FetchService from "../../api/FetchService";

let user = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  oldPassword: "",
  Password: "",
  phone: "",
  userTypeId: 1,
};

function ResetPassword() {
  const userObject = isLoggedIn()?.user;

  const [Password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [response, setResponse] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  var guidId = params.get("id");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Password !== repeatPassword) {
      setResponse("Failed");
      setResponseMessage("Feil: Passord stemmer ikke");

      setIsLoading(false);
    } else {
      setIsLoading(true);
      user.Password = Password;

      FetchService.ResetPassword(guidId, Password).then((response) => {
        if (response.data === true) {
          setResponse("Success");
          setResponseMessage("Passordet ble endret");
          setShowLoginButton(true);
          clearForm();
        } else {
          setResponse("Failed");
          setResponseMessage("Feil: Kan ikke endre passord. Linken er utløpt.");
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      });
    }
  };

  function clearForm() {
    setRepeatPassword("");
    setPassword("");
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
    >
      <div className="Register_page">
        <InnerHeader HeaderHeading="Angi nytt passord" />
        <section className="Register_main">
          <div className="container">
            <div className="row row_custom">
              <div className="col-lg-6 col_form_otr">
                <div className="col_form_inr">
                  <h3 className="heading-h3 form_heading">Nytt passord</h3>
                  {response !== "" && (
                    <div
                      className={
                        response === "Failed"
                          ? "error_message"
                          : "success_message"
                      }
                    >
                      {responseMessage}
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="form_main">
                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        Inputype="password"
                        InputName="password"
                        InputPlaceholder="Nytt passord"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        Inputype="password"
                        InputName="password"
                        InputPlaceholder="Gjenta passord"
                        onChange={(event) =>
                          setRepeatPassword(event.target.value)
                        }
                      />
                    </div>
                    {showLoginButton ? (
                      <div className="Input_otr action_otr">
                        <button
                          type="button"
                          className="Theme_btn_primary form_btn"
                        >
                          <Link to="/login" className="text-white">
                            Logg inn
                          </Link>
                        </button>
                      </div>
                    ) : (
                      <div className="Input_otr action_otr">
                        <button
                          type="submit"
                          className="Theme_btn_primary form_btn"
                        >
                          Oppdater
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LoadingOverlay>
  );
}

export default ResetPassword;

import React, { useState, useEffect } from "react";
import InnerHeader from "../InnerHeader/InnerHeader";
import "./OrderedActivity.scss";
import { useLocation } from "react-router-dom";
import FetchService from "../../api/FetchService";

import LoadingOverlay from "react-loading-overlay";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { API } from "../../api/API-Url";

function OrderedActivity() {
  
  const location = useLocation();

  //Getting only order Id which is being passed from the calling screen
  const orderId = location.state;

  let [order, setOrder] = useState();
  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

    function ActualDescs() {

        return (
            <>
                {order && order?.Description ? (
                    <div style={{ textAlign: "justify", display: "inline-block", wordBreak: "break-word", hyphens: "auto" }}>
                        <span dangerouslySetInnerHTML={{ __html: order?.Description }} />
                        {order?.Description.length > 700 ? (
                            <p>
                                <strong  style={{ wordBreak: "keep-all", cursor: "pointer" }}>
                                    ... Vis mindre
                                </strong>
                            </p>
                        ) : null}
                    </div>
                ) : null}
            </>
        );
    }

  const fetchData = () => {
    let apicall = FetchService.GetOrderByOrderId(orderId);

    apicall
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        //console.table(data[0]);
        setOrder(data[0]);
        setImages(JSON.parse(data[0].images));
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
      >



      <div className="campaign_Page order_activity">
        <InnerHeader HeaderHeading={order?.Name} PageText={order?.Name} />
        <div className="campaign_main">
          <div className="container">
                      <div className="row row_custom">
                          <div className="col-lg-6 col_content_otr">
                              <div className="col_content_inr">
                                <br/>
                                  <div className="content ">
                                    <div className="column_activity">
                                        <h4 className="text-uppercase font-weight-bold text-center">Om opplevelsen</h4>
                                        <hr />
                                          <div className="wrap_rabat_with_text font25">
                                            <ActualDescs />
                                        </div>
                                      </div>
                                      <br />
                                      <br />

                                      <div className="column_activity">
                                          <div className="column_activity vilkar">
                                              <h4 className="text-uppercase font-weight-bold text-center" style={{ cursor: "pointer", userSelect: "none" }} data-section="vilkar">Viktig informasjon</h4>
                                              <hr />
                                              <p className="heading-m campaign_text" >
                                                   
                                                  {!order?.ChildPrice>0 && <p>Pris per{" "}
                                                      {order?.PriceModel === "activity"
                                                          ? "activity"
                                                          : "person"}{" "}
                                                      <b> {order?.Price + " NOK"} </b>
                                                      {order?.PriceModel === "activity"
                                                          ? " per aktivitet"
                                                          : " per person"}

                                                  </p>}

                                                  {order?.ChildPrice > 0 && <p>Pris per{" :"}
                                                      <b> {order?.Price + " nok per voksen"}     {order?.ChildPrice + " nok per barn"} </b>
                                                      

                                                  </p>}
                                                <br /> 
                                                 Gyldig for:{" "}
                                                  <b>
                                                      {order?.MinPerson} - {order?.MaxPerson}
                                                  </b>{" "} Personer<br />
                                                  Aktivitet må bookes mellom:{" "}
                                                  <b>
                                                      {formatDate(order?.ValidPeriodStart)} -{" "}
                                                      {formatDate(order?.ValidPeriodEnd)}
                                                  </b>
                                              </p>
                                              <div className="heading-m campaign_text" >
                                                  <div dangerouslySetInnerHTML={{ __html: order?.Terms }} />
                                              </div>
                                          </div>

                                      </div>


                                  </div>
                              </div>
                          </div>
              <div className="col-lg-6 col_img_otr">
                <div className="col_img_inr">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={24}
                    effect={"fade"}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay, Pagination]}
                    className="mySwiper hero_swiper campaign_swiper"
                  >
                    {images &&
                      images.map((image) => (
                        <SwiperSlide>
                          <div className="img_otr">
                            <img
                              className="campaign_img"
                              height="50"
                              width="70"
                              src={image.imageURL.replace(
                                "{baseApiURL}",
                                API.apiURL
                              )}
                              alt="img"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>

                  <div className="social_text_otr">
                    <ul className="text_ul">
                      <h2 className="heading-h2">Ordre detaljer</h2>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"Dato for bestilling: " +
                            order?.CreatedDate.slice(0, 10)}
                        </h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"Leverandør: " + order?.PartnerName}
                        </h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"Total kostnad: " + order?.TotalAmount + " NOK"}
                        </h3>
                        <h3 className="text_heading "></h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          <div className="icon_text_otr">
                            <p>{order?.Quantity}</p>
                          </div>
                          personer
                        </h3>
                      </li>

                      <li className="text_li">
                        <h3 className="text_heading">
                          {"Rabatt: " + order?.DiscountPercent + "%"}
                        </h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"Spart beløp: " +
                            (order?.OriginalPrice - order?.Price) *
                              order?.Quantity +
                            " NOK"}
                        </h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"Brukt: " +
                            (order?.IsConsumed === true ? "Ja" : "Nei")}
                        </h3>
                      </li>
                      <li className="text_li">
                        <h3 className="text_heading ">
                          {"bekreftelseskode: " + order?.Code}
                        </h3>
                      </li>
                    </ul>

                    {/* Todo: here we can put some more information */}
                    <ul className="social_ul"></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default OrderedActivity;

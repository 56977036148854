import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminActivated(pros) {
  const navigate = useNavigate();

  useEffect(()=>{
    sessionStorage.setItem("loggin",true);
    navigate("/");
  },[])


  return "Hi";
}

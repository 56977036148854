import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Check from "../Check/Check";
import "./LoginComponent.scss";
import { loginUser } from "../../api/NewLoginService";
import FetchService from "../../api/FetchService";
import CryptoJS from 'crypto-js';


export default function LoginComponent({
    setUser,
    setShowLogin,
    setIsLoading
}) {
    const [pageMode, setPageMode] = useState("login"); //other option could be forgetPassword
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    // Function to decrypt the query string parameters
    const decryptQueryString=(encryptedData)=> {
        // Decode the base64 encoded encrypted data
        const decodedData = atob(decodeURIComponent(encryptedData));

        // Decrypt the data using AES decryption with the secret key
        const decryptedData = CryptoJS.AES.decrypt(decodedData, 'secret key').toString(CryptoJS.enc.Utf8);

        // Parse the decrypted JSON string to a JavaScript object
        const decryptedObject = JSON.parse(decryptedData);

        return decryptedObject;
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedData = urlParams.get('data');

        // Decrypt the encrypted data
        const decryptedParams = encryptedData ? decryptQueryString(encryptedData) : null;

        const emailParam = decryptedParams?.email;
    const passParam = decryptedParams?.password;

        debugger;
        if (emailParam && passParam) {
            setEmail(emailParam);
            setPassword(atob(passParam));

            const form = document.querySelector('.form_main'); // Access the form element by its class name

            if (form) {
                handleSubmit(); // Trigger the form submission
            } else {
                console.error('Form not found');
            }

        }
    }, [email,password]); // Empty dependency array to run this effect only once on component mount



    const [responseMessage, setResponseMessage] = useState("");
    const [message, setMessage] = useState("");

    const [headerText, setHeaderText] = useState("Logg inn hos Funtlers");

    const handleSubmit = async (e) => {
        debugger;
        setIsLoading(true);
        e && e.preventDefault();

        if (pageMode === "login")
            loginUser({
                email,
                password,
            }).then((response) => {
                if (response) {
                    localStorage.setItem('partner-aktiviteter', response.user.id);
                    //Setting state for parent component to redirect to home (/) page

                    setResponseMessage("Success");
                    setMessage("Logget på vellykket");

                    setTimeout(() => {
                        debugger;
                        setIsLoading(false);
                        setUser && setUser(response) ;
                        setShowLogin(false);

                        if (!setUser) {
                            window.location.href = "/";

                        }


                    }, 500);
                } else {
                    setResponseMessage("Failed");
                    setMessage("Feil brukernavn eller passord.");
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                }
            });
        else if (pageMode === "forgetPassword") {
            FetchService.ForgetPassword(email).then((response) => {
                if (response?.data) {
                    setPageMode("login");

                    setResponseMessage("Success");
                    setMessage(
                        "Tilbakestill passord e-post sendt til din registrerte e-post. Vennligst sjekk."
                    );
                } else {
                    setResponseMessage("Failed");
                    setMessage("Ugyldig epost. Skriv igjen");
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            });
        }
    };

    return (
        <>
            <h3 className="heading-h3 form_heading">{headerText}</h3>
            {responseMessage === "Failed" && (
                <div className="error_message">{message}</div>
            )}
            {responseMessage === "Success" && (
                <div className="success_message">{message}</div>
            )}
            {pageMode === "login" && (
                <form onSubmit={handleSubmit} className="form_main">
                    <div className="Input_otr">
                        <input
                            required
                            className="Theme_input_white form_input"
                            type="email"
                            name="email"
                            value={email}
                            placeholder="E-post"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                        />
                    </div>
                    <div className="Input_otr">
                        <input
                            required
                            className="Theme_input_white form_input"
                            type="password"
                            name="password"
                            value={password}
                            placeholder="Passord"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="Input_otr check_forget_otr">
                        <Check CheckLabel="Husk meg" />
                        <Link
                            onClick={() => {
                                setPageMode("forgetPassword");
                                setHeaderText("Glemt passord");
                                setMessage("");
                                setResponseMessage("");
                            }}
                            className="heading-s forgot_text"
                        >
                            Glemt passord?
                        </Link>
                    </div>
                    <div className="Input_otr action_otr">
                        <button className="Theme_btn_primary form_btn" type="submit">
                            Logg inn
                        </button>
                    </div>
                </form>
            )}
            {pageMode === "forgetPassword" && (
                <form onSubmit={handleSubmit} className="form_main">
                    <div className="Input_otr">
                        <input
                            required
                            className="Theme_input_white form_input"
                            type="email"
                            name="email"
                            placeholder="E-post"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                        />
                    </div>

                    <div className="Input_otr action_otr">
                        <button className="Theme_btn_primary form_btn" type="submit">
                            Send e-post
                        </button>
                    </div>
                </form>
            )}
        </>
    );
}

import React from "react";
import "./OrderPlanSearchResultCard.scss";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../api/API-Url";
import ThemeBtn from "../ThemeBtn/ThemeBtn";


function OrderPlanSearchResultCardRemove(props) {
    let activity;
    //the rerouting from this component will be different if it is coming from customer side.
    if (props.source === "customer") {
        activity = props.Data;
        activity.userType = "customer";
    } else activity = props.activity;

  let res = activity.name.replace(/ /g, '-');
    res = res.replace(/\//g, '-');
    res = res.replace(/\?/g, '');



    return (
        <div draggable onDragStart={props.handleDrag} className={`outer-elemt col_custom col_searchCard_otr col_searchCard_inr ${(localStorage.getItem('aktivitet-side') === activity.id || localStorage.getItem('aktiviteter') === activity.id) &&
                (window.location.pathname === '/aktivitet-side' || window.location.pathname === '/aktiviteter')
                ? "scrollToActivity"
                : ""
            }`}>
            
 
      <div>
                    <div className="position-relative img_otr">

          <img
            className="card_imgS"
                            src={props.CardImg.replace("{baseApiURL}", "https://funtlers.com/")}
            /*{src={props.CardImg.replace("{baseApiURL}", API.apiURLs)}}*/
            alt="img"
          />
          {activity.discountPercent>0&&activity.discountPercent<100? <div><p className="rabat_card_info shadow">-{activity.discountPercent}%</p></div> : ""}
        </div>
        <div className="content">
          <p className="heading-s card_desc_activity text-center">
                        <b>{props.CardHeading}</b>
          </p>
       </div>

        <div>
                    {props.isDelete && <ThemeBtn
                        BtnClass={`Theme_btn_primary send_btn  w-100`}
                        BtnText="FJERN"
                        onClick={props.Delete}

                    />}

        </div>



      </div>
  
            </div>
    )



}

export default OrderPlanSearchResultCardRemove;

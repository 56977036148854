import { React, useEffect, useState, useRef } from "react";
import InnerHeader from "../InnerHeader/InnerHeader";
import SectionHeadingDesc from "../SectionHeadingDesc/SectionHeadingDesc";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../api/NewLoginService";

import "./BookingConPage.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import ThemeBtn from "../ThemeBtn/ThemeBtn";
import apiURL, { API } from "./../../api/API-Url";
import FetchService from "../../api/FetchService";
import { getBookingSession } from "./../../api/NewLoginService";


const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
};




function BookingConPage() {
    const scriptRef = useRef(null);

    const location = getBookingSession();
    const activity = location?.activity;
    debugger;
    const peopleNumber = location?.peopleNumber;
    const childpeopleNumber = location?.childpeopleNumber;
    const user = location?.user;
    const activityImages = location?.images ? location?.images : [];
    let search = window.location.search;
    let params = new URLSearchParams(search);
    var id = params.get("id");
    var type = params.get("type");
    const [userObject, setUser] = useState(isLoggedIn()?.user);
    const [wait, setWait] = useState(false);

    useEffect(() => {
        BookActivity();
        // Check if the script element already exists, if not, create it
        if (!scriptRef.current) {
            scriptRef.current = document.createElement('script');
            scriptRef.current.src = './app.js';
            scriptRef.current.async = true;

            scriptRef.current.onload = () => {
                // Script has loaded, you can execute any code that depends on it here
            };

            // Append the script to the document body
            document.head.appendChild(scriptRef.current);
        }

        // Optional: If you need to do cleanup when the component unmounts
        return () => {
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current);
            }
        };
    }, []);



    const getBookingConfirmationDesc = () => {
        const guestEmail = sessionStorage.getItem('guestEmail') ? sessionStorage.getItem('guestEmail') : activity.partnerEmail;
        const userEmail = user?.email; // Assuming user is defined somewhere in the component's scope.
        const dynamicContent = `Din booking bekreftelse er sendt til ${guestEmail == null ? userEmail : guestEmail
            }`;
        return { __html: dynamicContent }; // Wrapping the content in an object with "__html" key.
    };

    const BookActivity = async () => {
        const calculatedPeopleNumber = !activity?.childStripePaymentID
            ? peopleNumber
            : peopleNumber + childpeopleNumber;

        const bookActivity = {
            activity: activity,
            peopleNumber: calculatedPeopleNumber,
            user: userObject,
        };

        var request = {
            userID: userObject ? userObject?.id : 0,
            guestEmail: sessionStorage.getItem('guestEmail') != null ? sessionStorage.getItem('guestEmail') : "",
            totalAmount: (activity?.price * peopleNumber),
            additionalDetails: "No details right now.",
            address: activity.city,
            quantity: peopleNumber,
            childquantity: childpeopleNumber,
            childtotalAmount: (activity?.childPrice * childpeopleNumber),
            activityId: activity.id,
            StripeOrderID: id,
            type: type
        };

        var response = FetchService.BookAcitvity(request);

        response.then((data) => {
            console.log(data)
            setWait(true)
        });
    };

    return (

        <section class="confirmation-section">
            <div class="container-lg">
                <div class="content-block">
                    <div class="title">Hurra! Nå er det bare å glede seg!</div>
                    <p class="sub-text" dangerouslySetInnerHTML={getBookingConfirmationDesc()}></p>
                    <div class="confirmation-card">
                        <div class="banner">
                            <div class="img-container">
                                <div className="col_img_inr ">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={24}
                                        effect={"fade"}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[EffectFade, Autoplay, Pagination]}
                                        className="mySwiper hero_swiper"
                                    >
                                        {activityImages && (
                                            <SwiperSlide>
                                                <div className="img_otr">
                                                    <img
                                                        className="booking_conf_img"
                                                        src={activityImages.imageURL.replace("{baseApiURL}", API.apiURLs)}
                                                        alt="img"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                </div>                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="info-list">
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(0.055 0.005)">
                                                    <rect width="24" height="24" rx="12" transform="translate(-0.055 -0.005)" fill="#fff0e2"></rect>
                                                    <g transform="translate(4.344 4.344)">
                                                        <path d="M26.924,49.058l.167-1.219a3.487,3.487,0,0,1-1.1-.239L25.8,48.987v.191l.311,1.578a.529.529,0,0,0,.645.43.54.54,0,0,0,.43-.646Z" transform="translate(-19.859 -36.985)" fill="#f68446"></path>
                                                        <path d="M32.924,48.939l.191-1.339a3.306,3.306,0,0,1-1.171.239L31.8,48.867v.191l.311,1.578a.535.535,0,0,0,.645.43.529.529,0,0,0,.43-.645Z" transform="translate(-24.425 -36.985)" fill="#f68446"></path>
                                                        <circle cx="1.028" cy="1.028" r="1.028" transform="translate(11.535 0)" fill="#f68446"></circle>
                                                        <circle cx="1.028" cy="1.028" r="1.028" transform="translate(1.828 1.458)" fill="#f68446"></circle>
                                                        <path d="M28.78,19.76a.985.985,0,0,0,.98-.98.98.98,0,0,0-1.96,0A.985.985,0,0,0,28.78,19.76Z" transform="translate(-21.381 -14.31)" fill="#f68446"></path>
                                                        <path d="M44.483,15.28c-.98-1.674-.908-1.674-1.076-1.769a2.485,2.485,0,0,0-3.18-.311l-.072.048a10.454,10.454,0,0,0-.765.861l-.574-.454a.449.449,0,0,0-.622.072.417.417,0,0,0-.1.287.608.608,0,0,1,.311-.12.562.562,0,0,1,.6.526,3.532,3.532,0,0,1-.024.5l.191.143a.474.474,0,0,0,.622-.048l.359-.406-.024,3.49a.124.124,0,0,0,.048.1l-.239,1.745a.475.475,0,0,0,.024.263l1.052,2.845a.578.578,0,0,0,.717.335.569.569,0,0,0,.335-.717l-1-2.725.215-1.65.143.024-.215,1.626a.478.478,0,0,0,.048.311L42.523,23.1a.562.562,0,0,0,1.028-.454l-1.219-2.7.191-1.53c.191,0,.072-.072.669-3.514l.406.693-.263,1.148a.441.441,0,0,0,.861.191l.311-1.315a.411.411,0,0,0-.024-.335Z" transform="translate(-29.219 -10.475)" fill="#f68446"></path>
                                                        <path d="M6.175,18.454a.333.333,0,1,0-.622-.239l-.263.717-.335-.239-.024-.024A1.945,1.945,0,0,0,2.4,18.43a.522.522,0,0,0-.12.1c-.024.024-.861,1.1-.813,1.028a.3.3,0,0,0-.048.311,5.242,5.242,0,0,0,.406,1.028.341.341,0,0,0,.478.048.329.329,0,0,0,.072-.43,5.869,5.869,0,0,1-.263-.717c.024-.024.239-.311.215-.287v.239c.1.287.215.574.239.645a.561.561,0,0,1-.143.717c-.024.024-.072.048-.1.072V22.3c0,.024,0,.024.024.048l-.383,1.721-.143-.1a.68.68,0,0,0-.789.239.553.553,0,0,0,.191.765l.813.5a.566.566,0,0,0,.837-.359l.43-1.913a18.923,18.923,0,0,1,.574,3.18A.563.563,0,0,0,5,26.463a20.981,20.981,0,0,0-.693-3.9q.036,0,.072-.072L4.86,19.41l.406.287a.336.336,0,0,0,.5-.143l.072-.167a1.261,1.261,0,0,1-.072-.287.5.5,0,0,1,.406-.645Z" transform="translate(-0.952 -14.461)" fill="#f68446"></path>
                                                        <path d="M26,18.492a.359.359,0,0,0-.311.359,2.97,2.97,0,0,1-.645,1.769,2.26,2.26,0,0,0-1.721.024,2.547,2.547,0,0,1-.861-1.315.351.351,0,0,0-.406-.263.361.361,0,0,0-.263.383v.024a3.429,3.429,0,0,0,1.267,1.841v1.1l-.55,2.63a3.347,3.347,0,0,0,3.347,0l-.55-2.63V21.337a3.735,3.735,0,0,0,1.028-2.51A.3.3,0,0,0,26,18.492Z" transform="translate(-16.806 -14.834)" fill="#f68446"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Aktivitet : {activity?.name}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(0.055 0.098)">
                                                    <rect width="24" height="24" rx="12" transform="translate(-0.055 -0.098)" fill="#fff0e2"></rect>
                                                    <path d="M124.444,0a3.444,3.444,0,1,0,3.444,3.444A3.448,3.448,0,0,0,124.444,0Z" transform="translate(-112.188 5.43)" fill="#f68446"></path>
                                                    <path d="M41.026,301.485A4.91,4.91,0,0,0,37.506,300H35.975a4.91,4.91,0,0,0-3.52,1.485A5.019,5.019,0,0,0,31,305.026a.383.383,0,0,0,.383.383H42.1a.383.383,0,0,0,.383-.383A5.019,5.019,0,0,0,41.026,301.485Z" transform="translate(-24.485 -286.917)" fill="#f68446"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Leverandør : {activity?.partnerName}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(0.055 0.105)">
                                                    <rect width="24" height="24" rx="12" transform="translate(-0.055 -0.105)" fill="#fff0e2"></rect>
                                                    <path d="M10.166,1A5.166,5.166,0,0,0,5,6.166c0,2.729,4.6,8.5,4.8,8.75a.47.47,0,0,0,.733,0c.2-.249,4.8-6.021,4.8-8.75A5.166,5.166,0,0,0,10.166,1Zm0,6.575A1.879,1.879,0,1,1,12.045,5.7,1.879,1.879,0,0,1,10.166,7.575Z" transform="translate(1.515 4.43)" fill="#f68446"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Adresse: {activity?.partnerAddress}</p>
                                    </div>
                                </li>
                                <hr />
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(-898 -487)">
                                                    <rect width="24" height="24" rx="12" transform="translate(898 487)" fill="#fff0e2"></rect>
                                                    <path d="M13.312,0H8.282A1.685,1.685,0,0,0,7.249.43l-6.9,6.9a1.213,1.213,0,0,0,0,1.711l5.134,5.134a1.213,1.213,0,0,0,1.711,0l6.9-6.895a1.686,1.686,0,0,0,.428-1.033V1.212A1.214,1.214,0,0,0,13.312,0Zm-2.42,4.841a1.21,1.21,0,1,1,1.21-1.21A1.211,1.211,0,0,1,10.891,4.843Z" transform="translate(903 491.998)" fill="#f68446"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Pris : {!activity?.childStripePaymentID ? activity?.price + " NOK / Person " : activity?.price + " nok per voksen, " + activity?.childPrice + " nok per barn"}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(-898 -523)">
                                                    <rect width="24" height="24" rx="12" transform="translate(898 523)" fill="#fff0e2"></rect>
                                                    <path d="M399.617,273.32h-1.123a2.921,2.921,0,0,1,.177,1v4.244a1.252,1.252,0,0,1-.072.419h1.857a1.259,1.259,0,0,0,1.258-1.258v-2.314A2.1,2.1,0,0,0,399.617,273.32Z" transform="translate(515.547 262.171)" fill="#f68446"></path>
                                                    <path d="M3.042,274.325a2.921,2.921,0,0,1,.177-1H2.1a2.1,2.1,0,0,0-2.1,2.1v2.314a1.259,1.259,0,0,0,1.258,1.258H3.114a1.252,1.252,0,0,1-.072-.419Z" transform="translate(902.74 262.17)" fill="#f68446"></path>
                                                    <path d="M141.493,234.815h-2.567a2.1,2.1,0,0,0-2.1,2.1v4.244a.419.419,0,0,0,.419.419h5.921a.419.419,0,0,0,.419-.419v-4.244A2.1,2.1,0,0,0,141.493,234.815Z" transform="translate(769.791 299.584)" fill="#f68446"></path>
                                                    <path d="M169.635,38.84a2.521,2.521,0,1,0,2.521,2.521A2.524,2.524,0,0,0,169.635,38.84Z" transform="translate(740.365 490.001)" fill="#f68446"></path>
                                                    <path d="M35.328,121.689a1.884,1.884,0,1,0,1.885,1.885A1.887,1.887,0,0,0,35.328,121.689Z" transform="translate(870.245 409.502)" fill="#f68446"></path>
                                                    <path d="M347.492,121.689a1.885,1.885,0,1,0,1.885,1.885A1.887,1.887,0,0,0,347.492,121.689Z" transform="translate(566.935 409.502)" fill="#f68446"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Antall : {!activity?.childStripePaymentID ? peopleNumber + " personer" : peopleNumber + " voksne , " + childpeopleNumber + " barn"}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="info-item">
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g transform="translate(-886 -556)">
                                                    <rect width="24" height="24" rx="12" transform="translate(886 556)" fill="#fff0e2"></rect>
                                                    <path d="M14.034,2.408A8.221,8.221,0,0,0,2.408,14.034,8.221,8.221,0,0,0,14.034,2.408Zm-5.813,5.3a2.312,2.312,0,0,1,.632,4.536.161.161,0,0,0-.118.155v.433a.523.523,0,0,1-.489.528.514.514,0,0,1-.539-.513V12.4a.161.161,0,0,0-.118-.155,2.316,2.316,0,0,1-1.681-2.209.522.522,0,0,1,.5-.528.514.514,0,0,1,.529.514A1.285,1.285,0,1,0,8.221,8.735,2.312,2.312,0,0,1,7.589,4.2a.161.161,0,0,0,.118-.155V3.611A.523.523,0,0,1,8.2,3.084a.514.514,0,0,1,.539.513v.448a.161.161,0,0,0,.118.155,2.316,2.316,0,0,1,1.681,2.209.522.522,0,0,1-.5.528.514.514,0,0,1-.529-.514A1.285,1.285,0,1,0,8.221,7.707Z" transform="translate(889.779 559.779)" fill="#f68446"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <p class="text">Total Beløp : {location.total} NOK</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="offer-info"> Følg oss for flere gode tilbud! </div>
                    <ul class="social-list">
                        <li>
                            <a href="https://www.facebook.com/people/Funtlers/100087485037767/" target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                    <path d="M53.751,26.876A26.876,26.876,0,1,0,26.876,53.751c.157,0,.315,0,.472-.011V32.828H21.574V26.1h5.774V21.144c0-5.743,3.506-8.871,8.63-8.871a46.9,46.9,0,0,1,5.176.262V18.54H37.626c-2.782,0-3.328,1.323-3.328,3.265v4.283h6.666l-.871,6.729H34.3V52.712A26.884,26.884,0,0,0,53.751,26.876Z" fill="#f68446"></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/funtlers/?hl=en" target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                    <circle cx="5.289" cy="5.289" r="5.289" transform="translate(21.586 21.586)" fill="#f68446"></circle>
                                    <path d="M32.876,6A26.876,26.876,0,1,0,59.751,32.876,26.876,26.876,0,0,0,32.876,6Zm16.6,33.706a10.169,10.169,0,0,1-2.709,6.917A10.356,10.356,0,0,1,39.78,49.3H25.972a10.353,10.353,0,0,1-6.991-2.68,10.168,10.168,0,0,1-2.709-6.917V26.045a10.169,10.169,0,0,1,2.709-6.917,10.355,10.355,0,0,1,6.991-2.68H39.78a10.353,10.353,0,0,1,6.991,2.68,10.169,10.169,0,0,1,2.709,6.917Z" transform="translate(-6 -6)" fill="#f68446"></path>
                                    <path d="M151.2,131.559c-3.571-.086-10.717-.086-14.288,0a8.1,8.1,0,0,0-5.288,1.7,6.26,6.26,0,0,0-1.948,4.619c-.093,3.078,0,12.317,0,12.317a6.29,6.29,0,0,0,1.948,4.619,8.1,8.1,0,0,0,5.288,1.7c3.571.086,10.717.086,14.288,0a8.1,8.1,0,0,0,5.288-1.7,6.26,6.26,0,0,0,1.948-4.619V137.88a6.26,6.26,0,0,0-1.948-4.619A8.1,8.1,0,0,0,151.2,131.559Zm-7.144,20.678c-5.129,0-9.287-3.671-9.287-8.2s4.158-8.2,9.287-8.2,9.287,3.671,9.287,8.2S149.189,152.237,144.059,152.237Zm9.327-14.7a1.653,1.653,0,1,1,1.858-1.64,1.759,1.759,0,0,1-1.858,1.64Z" transform="translate(-117.181 -117.163)" fill="#f68446"></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/funtlers/" target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                    <path d="M26.876,0A26.876,26.876,0,1,0,53.751,26.876,26.879,26.879,0,0,0,26.876,0Zm-7.81,40.628H12.52V20.936h6.545ZM15.793,18.247h-.043a3.411,3.411,0,1,1,.086-6.8,3.413,3.413,0,1,1-.043,6.8ZM42.667,40.628H36.123V30.094c0-2.648-.948-4.453-3.316-4.453a3.583,3.583,0,0,0-3.358,2.394,4.482,4.482,0,0,0-.215,1.6v11H22.688s.086-17.845,0-19.692h6.545v2.788a6.5,6.5,0,0,1,5.9-3.25c4.306,0,7.535,2.814,7.535,8.863Zm0,0" fill="#f68446"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>


    );
}

export default BookingConPage;

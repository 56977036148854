import React, { useState, useEffect } from "react";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import FetchService from "../../api/FetchService";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';


import "./PartnerPage.scss";

import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";

function PartnerPage2({ setIsLoading }) {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [search, setSearch] = useState("");

    const [partnerType, setPartnerType] = useState("active");

    const encryptQueryString = (email, password) => {
        // Convert email and password to a JSON object
        const data = { email, password };

        // Convert JSON object to a string and encrypt using AES encryption with a secret key
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key').toString();

        // Encode the encrypted data to base64
        return encodeURIComponent(btoa(encryptedData));
    };


  const fetchData = () => {
      let apicall = FetchService.GetCompany();

    apicall
      .then((response) => {
        return response.data;
      })
      .then((data) => {
          setPartners(data);
          debugger;
        setFilteredPartners(data.filter((x) => x.isActive));
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    const filter = partners.filter(
      (x) =>
        x.name?.toLowerCase().includes(search) ||
        x.id.toString().includes(search)
    );
    setFilteredPartners(filter);
  }, [search]);

  useEffect(() => {
    //by default all partners are coming from backend, including the ones which are inactive
    if (partnerType === "active")
      setFilteredPartners(partners.filter((x) => x.isActive));
    else if (partnerType === "inactive")
      setFilteredPartners(partners.filter((x) => !x.isActive));
  }, [partnerType]);

  const sendEmail = (partnerEmail, partnerid) => {
    setIsLoading(true);
      let apicall = FetchService.InviteCompanyEmail(partnerEmail, partnerid);

    apicall
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          alert("E-post er sendt til: " + partnerEmail);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          alert("Feil: Kunne ikke sende e-post. Prøv igjen");
          setIsLoading(false);
        }
      });
  };

  return (
    <div className="partner_page_main partner_page_main_da">
      <div className="heading_filter_otr">
              <p className="heading_activity heading-lb">Bedriftskunder</p>
        <div className="filter_search_otr">
          {/* Todo: implement filter by solution */}
          {/* <div className="filter_main">
            <p className="sort_text heading-xs">Sort By:</p>
            <Select />
          </div> */}
          <div className="search_otr">
            <i class="ri-search-2-line search_icon"></i>
            <Input
              InputClass="Theme_input_white search_input"
              Inputype="search"
              InputName="search"
              InputPlaceholder="SØK"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <ThemeBtn
            BtnClass="Theme_btn_primary"
            BtnText="Legg til Bedriftskunder"
            onClick={() => {
                navigate("/legge-til-bedriftskunde");
            }}
          />
        </div>
      </div>
      <div className="radio-group">
        <label className="radio" onClick={() => setPartnerType("active")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={partnerType === "active"}
          />
                  {"Aktive bedriftskunder"}
        </label>
        <label className="radio" onClick={() => setPartnerType("inactive")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={partnerType === "inactive"}
          />
                  {"⁠Inaktive bedriftskunder"}
        </label>
      </div>
      <div className="partner_table">
        <div className="partner_table_inr">
          <table className="data_table">
            <thead className="table_header">
              <tr>
                <th>
                                  <p className="heading-xsb header_text">⁠BedriftsID</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Navn</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">E-post</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Telefon</p>
                </th>
              
                <th>
                                  <p className="heading-xsb header_text">⁠Kunde siden</p>
                </th>

                <th>
                  <p className="heading-xsb header_text">Log-in</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Handling</p>
                </th>
              </tr>
            </thead>

            <tbody className="table_body">
              {filteredPartners.map((partner) => (
                <tr className="partner_row">
                  <td>
                    <p className="heading-xs body_text">{partner.id}</p>
                  </td>
                  <td>
                          <p className="heading-xs body_text">{partner.firstname + " " + partner.lastname}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{partner.email}</p>
                  </td>
                  <td>
                    <p className="heading-xsb body_text">{partner.phone}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">
                      {partner?.partnerSince?.slice(0, 10)}
                    </p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">
                      {partner.isLoggedIn == true ? "Ja" : "Nei"}
                    </p>
                  </td>
                  <td>
                    <div className="action_otr">
                      <ThemeBtn
                        BtnClass="Theme_btn_primary partner-page-button activity"
                        BtnText="Aktiviteter"
                                  onClick={() => {
                                      localStorage.setItem('partner-aktiviteter', partner.id);

                                      const encryptedParams = encryptQueryString(partner.email, partner.partnerid);
                                      const url = `/login?data=${encryptedParams}`;
                                      window.open(url, '_blank');
                                  }}

                      />
                      <ThemeBtn
                        BtnClass="Theme_btn_primary partner-page-button order"
                        BtnText="Ordre"
                                  onClick={() => {
                                      localStorage.setItem('partner-aktiviteter', partner.id);
                                      const encryptedParams = encryptQueryString(partner.email, partner.partnerid);
                                      const url = `/login?data=${encryptedParams}`;
                                      window.open(url, '_blank');                                  }}
                      />
                      <ThemeBtn
                        BtnClass="Theme_btn_primary partner-page-button edit"
                        BtnText="Rediger"
                        onClick={() => {
                          navigate("/rediger-partner2", { state: partner });
                        }}
                      />
                      <ThemeBtn
                        BtnClass="Theme_btn_primary partner-page-button email"
                        BtnText="Invitere"
                        onClick={() => {
                          sendEmail(partner.email, partner.id);
                        }}
                      />
                      {/* <div className="icon_otr">
                        <i class="ri-eye-fill view_icon icon"></i>
                      </div>
                      <div className="icon_otr">
                        <i class="ri-edit-fill edit_icon icon"></i>
                      </div>
                      <div className="icon_otr">
                        <i class="ri-delete-bin-6-fill delete_icon icon"></i>
                      </div> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PartnerPage2;

import React, { useState, useEffect } from "react";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import FetchService from "../../api/FetchService";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";

import "./UniqueCodes.scss";

import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
import { API } from "../../api/API-Url";

function UniqueCodes({ setIsLoading }) {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [search, setSearch] = useState("");
  const [codeFile, setCodeFile] = useState();
  const [fileData, setFileData] = useState();

  const [showPartnerId, setShowPartnerId] = useState(false);

  const fetchData = () => {
    let apicall = FetchService.GetPartner();

    apicall
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setPartners(data);
        setFilteredPartners(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const DownloadCodes = (partnerId) => {
    FetchService.DownloadCodes(partnerId).then((data) => {});
  };

  const UploadCodes = (codeFile, partnerId) => {
    readXlsxFile(codeFile[0]).then((rows) => {
        let data=[];
      for(let row of rows)
      {
         data.push(...row);
      }
      data=data.filter((x)=>x!=null)

      
      FetchService.UploadCodes(partnerId, data).then((response) => {
        if (response) {
          alert("Koder ble lastet opp");
        } else {
          alert("Feil: Kan ikke laste opp koder. Prøv igjen.");
        }
      });
    });
  };

  const GenerateCodes = (partnerId) => {
    FetchService.GenerateCodes(partnerId).then((response) => {
      if (response) {
        alert("200 koder ble generert");
      } else {
        alert("Feil: Kunne ikke generere koder. Prøv igjen.");
      }
    });
  };

  useEffect(() => {
    const filter = partners.filter(
      (x) =>
        x.name.toLowerCase().includes(search) ||
        x.id.toString().includes(search)
    );
    setFilteredPartners(filter);
  }, [search]);

  return (
    <div className="partner_page_main partner_page_main_da">
      <div className="heading_filter_otr">
        <p className="heading_activity heading-lb">Unik kodebehandler</p>
        <div className="filter_search_otr">
          {/* Todo: implement filter by solution */}
          {/* <div className="filter_main">
            <p className="sort_text heading-xs">Sort By:</p>
            <Select />
          </div> */}
          <div className="search_otr">
            <i class="ri-search-2-line search_icon"></i>
            <Input
              InputClass="Theme_input_white search_input"
              Inputype="search"
              InputName="search"
              InputPlaceholder="SØK"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="partner_table">
        <div className="partner_table_inr">
          <table className="data_table">
            <thead className="table_header">
              <tr>
                <th>
                  <p className="heading-xsb header_text">Partner ID</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Navn</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Email</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Telefon</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">By</p>
                </th>

                <th>
                  <p className="heading-xsb header_text">Partner side</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Handling</p>
                </th>
              </tr>
            </thead>

            <tbody className="table_body">
              {filteredPartners.map((partner) => (
                <tr className="partner_row">
                  <td>
                    <p className="heading-xs body_text">{partner.id}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{partner.name}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">{partner.email}</p>
                  </td>
                  <td>
                    <p className="heading-xsb body_text">{partner.phone}</p>
                  </td>

                  <td>
                    <p className="heading-xs body_text">{partner.city}</p>
                  </td>
                  <td>
                    <p className="heading-xs body_text">
                      {partner.partnerSince}
                    </p>
                  </td>
                  <td>
                    <div className="action_otr">
                      <ThemeBtn
                        BtnClass="Theme_btn_primary unique-code-button activity"
                        BtnText="Generer koder"
                        onClick={() => {
                          GenerateCodes(partner.id);
                        }}
                      />
                      <ThemeBtn
                        BtnClass="Theme_btn_primary unique-code-button order"
                        BtnText="Last opp koder"
                        onClick={() => {
                          setShowPartnerId(partner.id);
                        }}
                      />
                      <form>
                        <button
                          className="Theme_btn_primary unique-code-button edit"
                          onClick={() => {
                            DownloadCodes(partner.id);
                          }}
                        >
                          <a
                            href={
                              API.url + "PartnerCodes?partnerId=" + partner.id
                            }
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Last ned koder
                          </a>
                        </button>
                      </form>
                      {showPartnerId === partner.id && (
                        <input
                          type="file"
                          id="input"
                          accept=".xlsx"
                          onChange={(event) =>
                            UploadCodes(event.target.files, partner.id)
                          }
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UniqueCodes;

import React,{useState} from 'react';
import './ImagesEdit.scss'

const ImagesEdit = () =>{

    const [images, setImages] = useState(['album1','album2','album3','album4'])

      return (
        <div>
            {images.map((images,index) => (
                <>
                    rdg
                </>
            ))}
        </div>
      );
};


export default ImagesEdit;
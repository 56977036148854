import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import InnerHeader from "../InnerHeader/InnerHeader";
import Input from "../Input/Input";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import { register } from "../../api/LoginService";
import FetchService from "../../api/FetchService";
import LoadingOverlay from "react-loading-overlay";

import "./AddPartner.scss";

//userTypeID = 1 because its for customers
//Todo: edit this object model
let partner = {
    firstname: "",
    lastname: "",
    address: "",
    email: "",
    password: "",
    confirmpassword: "",
    phone: "",

};

function PartnersPageBusiness() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
    const targetRef = useRef(null);



  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("");

    const scrollToDiv = () => {
        targetRef.current.scrollIntoView({
            behavior: 'smooth', // Optional: smooth scrolling behavior
            block: 'start' // Optional: scroll to the top of the element
        });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setResponse("");
    partner.firstname = firstname;
    partner.lastname = lastname;
      partner.address = address;
      partner.phone = phone;
    partner.partnerSince = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    partner.email = email;
    partner.password = password;
      partner.confirmpassword = confirmpassword;

      scrollToDiv();

      if (partner.password.length > 0 && partner.password != partner.confirmpassword) {
          setIsLoading(false);
          setResponse("FailedPassword");

      }
      else {
          FetchService.AddCompany(partner).then((response) => {
              if (response?.data === 1) {
                  //todo: fix this timer of loader issue
                  // setTimeout(() => {
                  setResponse("Success");
                  clearForm();
                  setIsLoading(false);
                  // }, 5);

                  clearForm();
              } else {
                  // setTimeout(() => {
                  setIsLoading(false);
                  setResponse("Failed");
                  // }, 0);
              }
          });

      }


  };

  function clearForm() {
    setFirstName("");
    setLastName("");
      setAddress("");
      setPhone("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setIsLoading(false);
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
    >
      <div className="Register_page">
        <section className="add_partner_main">
          <InnerHeader HeaderHeading="Add Partner" PageText="Add Partner" />
          <div className="container">
            <div className="row row_custom">
              <div className="col-lg-8 col_form_otr">
                              <div className="col_form_inr" ref={targetRef}>
                  <h3 className="heading-h3 form_heading">
                                      Legg til bedriftskunde
                  </h3>
                  {response === "Failed" && (
                    <div className="error_message">
                                          {"Feil: Bedriftskunde ikke registrert. Prøv igjen."}
                    </div>
                                  )}
                                  {response === "FailedPassword" && (
                                      <div className="error_message">
                                          {"Feil: Bedriftskundepassordet samsvarer ikke."}
                                      </div>
                                  )}

                                  
                  {response === "Success" && (
                    <div className="success_message">
                                          {"Bedriftskunde registrert"}
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="form_main">
                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="First name"
                        label="Fornavn"
                        value={firstname}
                        required
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </div>
                    <div className="Input_otr">
                        <Input
                            InputClass="Theme_input_white form_input"
                            Inputype="text"
                            InputName="Last name"
                            label="Etternavn"
                                              value={lastname}
                                              required
                            onChange={(event) => setLastName(event.target.value)}
                        />
                    </div>

                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="address"
                        label="Adresse"
                        value={address}
                        required
                        onChange={(event) => setAddress(event.target.value)}
                      />
                    </div>


                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        label="Email"
                        value={email}
                        required
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>

                    <div className="Input_otr">
                      <Input
                        InputClass="Theme_input_white form_input"
                        Inputype="password"
                        InputName="Password"
                        label="Passord"
                        required
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>


                    <div className="Input_otr">
                        <Input
                            InputClass="Theme_input_white form_input"
                            Inputype="password"
                            InputName="Confirm passord"
                            label="bekreft passord"
                            required
                            value={confirmpassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                    </div>


                    <div className="Input_otr">
                        <Input
                            InputClass="Theme_input_white form_input"
                            Inputype="number"
                            InputName="phone"
                            label="Telefonnummer"
                                              value={phone}
                            required
                            onChange={(event) => setPhone(event.target.value)}
                        />
                    </div>



                    <div className="Input_otr action_otr">
                      <button
                        type="submit"
                        className="Theme_btn_primary form_btn"
                      >
                        Legg til
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LoadingOverlay>
  );
}

export default PartnersPageBusiness;

import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import FetchService from "../../api/FetchService";
import "./SearchEdit.scss";
import {getAllActivityCategories} from "../../commons/activity-filters/Helpers"
import edit from "../../img/edit.png"

function ActivityPage({ setIsLoading }) {
  const [categories, setCategories] = useState(getAllActivityCategories());

  const navigate = useNavigate();

  const handleEdit = (label) =>{
      navigate('/aktivitet-side?cat=' + encodeURIComponent(label))
  }

  useEffect(() => {
      //fetchData();
      setCategories(categories.filter(category => category.value != "all" && category.value != "default" ))
  }, []);

  return (
    <div className="search_edit_main">
        <table>
            <tr>
                <th>Navn</th>
                <th>Tiltak</th>
            </tr>
            {
                categories.map((category)=>(
                    <tr>
                        <td>{category.label.replace("Dagsevent", "Pa kontoret").replace("Julebord", "Firmafest")}</td>
<td>
    <img src={edit} className="edit_img" alt="edit" data-edit={category.value} onClick={() => handleEdit(category.value)} />
</td>
                    </tr>
                ))
            }

        </table>
    </div>
  );
}

export default ActivityPage;

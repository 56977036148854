import APIServices from "./API-Services";
import { API } from "./API-Url";

class FetchService {
    /**Activity Conteroller */

    UpdateFavPriority(type ,activityID,priority) {
        let apicall = APIServices.POST(API.url + "ActivityPriority", {type:type,activityID:activityID,priority:priority});
        return apicall;
    }

    //Todo: make it all PascalCase
    getAllActivities(ownerid) {
        let apicall = APIServices.GET(API.url + "Activity?ownerid=" + ownerid);
        return apicall;
    }


    getAllActivitiesByCategory(type, userType) {
        let apicall = APIServices.GET(
            API.url + "Activity?userType=" + userType + "&type=" + type
        );
        return apicall;
    }



    getActivitiesByPagination(filter) {
        var sfds = API.url + "ActivityPaginated";
        let apicall = APIServices.POST(API.url + "ActivityPaginated", filter);
        return apicall;
    }



    GetActivityById(id, userType) {
        let apicall = APIServices.GET(
            API.url + "Activity?userType=" + userType + "&type=id&id=" + id
        );
        return apicall;
    }
    GetAllActivitiesByPartnerId(id) {
        let apicall = APIServices.GET(API.url + "Activity?type=partner&id=" + id);
        return apicall;
    }

    AddActivity(activity) {
        let apicall = APIServices.POST(API.url + "Activity", activity);

        return apicall;
    }

    UpdateActivity(activity) {
        let apicall = APIServices.PUT(API.url + "Activity", activity);
        return apicall;
    }

    DeleteActivity(activity) {
        let apicall = APIServices.DELETE(
            API.url + "Activity?activityId=" + activity.id
        );
        return apicall;
    }

    BookAcitvity(activity) {
        let apicall = APIServices.POST(API.url + "Order", activity);
        return apicall;
    }

    //User entity API calls
    RegisterUser(user) {
        let apicall = APIServices.POST(API.url + "User", user);
        return apicall;
    }
    UpdateUser(user) {
        let apicall = APIServices.PUT(API.url + "User", user);
        return apicall;
    }

    UpdateUserType(user) {
        let apicall = APIServices.PUT(API.url + "User?type=admin", user);
        return apicall;
    }

    VerifyUser(user) {
        let apicall = APIServices.POST(API.url + "Login", user);
        return apicall;
    }

    GetPartner() {
        let apicall = APIServices.GET(API.url + "Partner");
        return apicall;
    }


    GetCompany() {
        let apicall = APIServices.GET(API.url + "Company");
        return apicall;
    }

    AddPartner(partner) {
        let apicall = APIServices.POST(API.url + "Partner", partner);
        return apicall;
    }


    AddCompany(partner) {
        let apicall = APIServices.POST(API.url + "Company", partner);
        return apicall;
    }

    UpdatePartner(partner) {
        let apicall = APIServices.PUT(API.url + "Partner", partner);
        return apicall;
    }

    UpdateCompany(partner) {
        let apicall = APIServices.PUT(API.url + "Company", partner);
        return apicall;
    }

    DeleteCompany(partnerId) {
        let apicall = APIServices.DELETE(
            API.url + "Company?CompanyId=" + partnerId
        );
        return apicall;
    }


    DeletePartner(partnerId) {
        let apicall = APIServices.DELETE(
            API.url + "Partner?partnerId=" + partnerId
        );
        return apicall;
    }

    //This api call will return entire order object which is basically a join in the
    // the database of user, partner, order and activity object
    GetOrderByOrderId(orderId) {
        let apicall = APIServices.GET(
            API.url + "Order?id=" + orderId + "&type=orderactivity"
        );
        return apicall;
    }

    GetOrdersByUserId(userId) {
        let apicall = APIServices.GET(API.url + "Order?id=" + userId);
        return apicall;
    }

    GetOrderByPartnerId(id, type) {
        let apicall = APIServices.GET(
            API.url + "Order?id=" + id + "&type=" + type + ""
        );
        return apicall;
    }

    GetCouponsByPartnerId(id) {
        let apicall = APIServices.GET(
            API.url + "CouponsAnalitics?id=" + id
        );
        return apicall;
    }

    GetAnalytics(request) {
        let apicall = APIServices.GET(
            API.url +
            "Analytics?city=" +
            request.city +
            "&startDate=" +
            request.startDate +
            "&endDate=" +
            request.endDate
        );

        return apicall;
    }

    GetAnalyticsById(id) {
        let apicall = APIServices.GET(API.url + "Analytics?type=Partner&id=" + id);
        return apicall;
    }

    GetUsers() {
        let apicall = APIServices.GET(API.url + "User");
        return apicall;
    }

    GetAnalyticsPartner() {
        let apicall = APIServices.GET(API.url + "Analytics?type=AllPartner&id=0");
        return apicall;
    }

    GetAnalyticsActivity() {
        let apicall = APIServices.GET(API.url + "Analytics?type=AllActivity");
        return apicall;
    }

    DownloadAnalytics(type) {
        const body = { type: type, key: "www.funtlers.com/analyse" };
        let apiResponse = APIServices.POST(API.url + "Analytics", body);
        return apiResponse;
    }

    GetAnalyticsActivitypartner(partnerid) {
        let apicall = APIServices.GET(
            API.url + "Analytics?type=AllActivity&id=" + partnerid
        );
        return apicall;
    }

    VerifyOrder(order) {
        let apicall = APIServices.PUT(API.url + "Order", order);
        return apicall;
    }

    SendEmailContactUs(email) {
        let apicall = APIServices.POST(API.url + "Email?type=support", email);
        return apicall;
    }

    UpdateSearch(newCategories) {
        let apicall = APIServices.POST(API.url + "Search", newCategories);
        return apicall;
    }
    GetSearchInfo(category) {
        let apicall = APIServices.GET(API.url + "Search?category=" + category);
        return apicall;
    }
    SendEmailBecomePartner(email) {
        let apicall = APIServices.POST(API.url + "Email?type=partner", email);
        return apicall;
    }
    SendEmailRequest(email) {
        let apicall = APIServices.POST(API.url + "Email?type=request", email);
        return apicall;
    }
    SendEmailNewsletter(email) {
        let apicall = APIServices.POST(API.url + "Email?type=subscription", email);
        return apicall;
    }
    ForgetPassword(email) {
        const request = { email: email, baseURL: API.baseURL };
        let apicall = APIServices.POST(API.url + "ResetPassword", request);
        return apicall;
    }
    ResetPassword(guid, newPassword) {
        const request = { passwordSessionGuid: guid, newPassword: newPassword };
        let apicall = APIServices.PUT(API.url + "ResetPassword", request);
        return apicall;
    }
    GenerateCodes(partnerID) {
        const params = partnerID + "";
        let apicall = APIServices.POST(API.url + "PartnerCodes", {
            partnerID: params,
        });
        return apicall;
    }
    //array of codes.
    UploadCodes(partnerID, codes) {
        let requestCode = [];
        for (let code of codes) {
            let temp = {
                codes: code + "",
                isConsumed: "false",
                partnerID: partnerID + "",
            };

            requestCode.push(temp);
        }
        let apicall = APIServices.PUT(API.url + "PartnerCodes", requestCode);
        return apicall;
    }
    DownloadCodes(partnerID) {
        let apicall = APIServices.GET(
            API.url + "PartnerCodes?partnerId=" + partnerID
        );
        return apicall;
    }
    InvitePartnerEmail(email, partnerid) {
        const request = {
            email: email,
            id: partnerid,
            baseurl: API.baseURL,
        };
        let apicall = APIServices.POST(API.url + "Email?type=addpartner", request);
        return apicall;
    }


    InviteCompanyEmail(email, partnerid) {
        const request = {
            email: email,
            id: partnerid,
            baseurl: API.baseURL,
        };
        let apicall = APIServices.POST(API.url + "Email?type=addcompany", request);
        return apicall;
    }



    
    getActivitiesImages(array2) {
        ;
        let array = array2.flat().map(obj => obj.id);

        let idListParam = array.length > 0 ? "idList=" + array.toString() : '';

        let apicall = APIServices.GET(API.url + "HomeActivities" + (idListParam ? `?${idListParam}` : ''));
        return apicall;


    }
    GetCouponCode(code, activityId) {
        let apicall = APIServices.POST(API.url + "Coupons", { Code: code, ActivityId: activityId });
        return apicall;
    }
    getAllCoupons() {
        let apicall = APIServices.GET(API.url + "Coupons");
        return apicall;
    }
    GetAllValidActivities() {
        let apicall = APIServices.POST(API.url + "HomeActivities");
        return apicall;
    }
    AddNewCoupon(request) {
        let apicall = APIServices.PUT(API.url + "Coupons", request);
        return apicall;
    }
    DeleteCoupon(couponId) {
        let apicall = APIServices.DELETE(API.url + "Coupons?couponId=" + couponId);
        return apicall;
    }
    EditCoupon(request) {
        let apicall = APIServices.PUT(API.url + "HomeActivities", request);
        return apicall;
    }
    GetCouponsAnalitics() {
        let apicall = APIServices.GET(API.url + "CouponsAnalitics");
        return apicall;
    }




    getAllCircles(circlestatus, circletype) {
        let apicall = APIServices.GET(API.url + "Circle?circlestatus=" + circlestatus + "&circletype=" + circletype);
        return apicall;
    }
    getAllCircless(circlestatus, circletype) {
        let apicall = APIServices.GET(API.url + "Circle/GetAllCircles?circlestatus=" + circlestatus + "&circletype=" + circletype);
        return apicall;
    }

    GetCircleById(circlestatus, circletype, type, id) {
        let apicall = APIServices.GET(
            API.url + "circle?circleStatus=" + circlestatus + "&circleType=" + circletype + "&id=" + id + "&type=" + type
        );
        return apicall;
    }

    AddCircle(circle) {
        let apicall = APIServices.POST(API.url + "Circle", circle);

        return apicall;
    }

    UpdateCircle(circle) {
        let apicall = APIServices.PUT(API.url + "Circle", circle);
        return apicall;
    }

    DeleteCircle(circle) {
        let apicall = APIServices.DELETE(
            API.url + "Circle?id=" + circle
        );
        return apicall;
    }

}

export default new FetchService();

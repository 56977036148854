import AnalyticsCard from "../AnalyticsCard/AnalyticsCard";
import FetchService from "../../api/FetchService";
import React, { useState, useEffect } from "react";

import "./AnalyticsPage.scss";
import { isLoggedIn } from "../../api/NewLoginService";
import AnalyticsPartnerPage from "../AnalyticsPartnerPage/AnalyticsPartnerPage";
import AnalyticsCouponsPage from "../AnalyticsCouponsPage/AnalyticsCouponsPage";
import AnalyticsActivityPage from "../AnalyticsActivityPage/AnalyticsActivityPage";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import { getAllCities } from "../../commons/activity-filters/Helpers";
import Form from "react-bootstrap/Form";
function AnalyticsPage({ setIsLoading }) {
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [userObject, setUser] = useState(isLoggedIn());
  const [analyticsType, setAnalyticsType] = useState("Partner");

  const cities = getAllCities();

  const [city, setCity] = useState("all");
  //date range period for analytics
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchData = () => {
    const request = { city: city, startDate: startDate, endDate: endDate };

    FetchService.GetAnalytics(request).then((data) => {
      setFilteredPartners(data.data[0]);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <div className="partner_analytics_page">
      <div className="heading_otr">
        <div className="dateRange">
          <p className="heading-lb heading_partner">Analytics</p>
          <div className="dateRange">
            <div className="city">
              <Form.Select
                className="Theme_input_white custom "
                aria-label="Default select example"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              >
                {cities.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Form.Select>
            </div>
            <Input
              InputClass="Theme_input_white form_input"
              Inputype="date"
              label="Fra"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
            />
            <Input
              InputClass="Theme_input_white form_input"
              Inputype="date"
              label="Til"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
            />
            <button
              onClick={fetchData}
              class="Theme_btn_primary partner-page-button custom"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="statistics_main">
        <div className="row row_custom">
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-money-dollar-circle-fill"
              CardHeading={filteredPartners.TotalEarning}
              CardName="Total inntekt"
              PercentText=""
              TextColor="green_text"
            />
          </div>

          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-shopping-bag-fill"
              CardHeading={filteredPartners.Orders}
              CardName="Ordre"
              PercentText=""
              TextColor="green_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-user-fill"
              CardHeading={filteredPartners.Customers}
              CardName="Kunder"
              PercentText=""
              TextColor="red_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-file-list-fill"
              CardHeading={filteredPartners.ActivityActive}
              CardName="Aktive aktiviteter"
              PercentText=""
              TextColor="green_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-shopping-bag-fill"
              CardHeading={filteredPartners.ActivitySold}
              CardName="Aktiviteter solgt"
              PercentText=""
              TextColor="red_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-wallet-fill"
              CardHeading={filteredPartners.Partners}
              CardName="Partnere"
              PercentText=""
              TextColor="black_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-wallet-fill"
              CardHeading={filteredPartners.CouponsActivated}
              CardName="Kuponger"
              PercentText=""
              TextColor="black_text"
            />
          </div>
          <div className="col-lg-3 col_card_otr">
            <AnalyticsCard
              CardIcon="ri-wallet-fill"
              CardHeading={filteredPartners.Worth}
              CardName="Kuponger verdt"
              PercentText=""
              TextColor="black_text"
            />
          </div>
        </div>
      </div>

      <div className="radio-group">
        <label className="radio" onClick={() => setAnalyticsType("Partner")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={analyticsType === "Partner"}
          />
          {"Partner Analytics"}
        </label>
        <label className="radio" onClick={() => setAnalyticsType("Activity")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={analyticsType === "Activity"}
          />
          {"Aktivitetsanalyse"}
        </label>
        <label className="radio" onClick={() => setAnalyticsType("Coupons")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={analyticsType === "Coupons"}
          />
          {"Kuponger Analize"}
        </label>
      </div>

      {analyticsType === "Partner" && <AnalyticsPartnerPage />}
      {analyticsType === "Activity" && <AnalyticsActivityPage />}
      {analyticsType === "Coupons" && <AnalyticsCouponsPage />}
    </div>
  );
}

export default AnalyticsPage;

import SearchResultCard from "../../components/SearchResultCard/SearchResultCard";
import React, { useEffect, useState } from "react";
import FetchService from "../../api/FetchService";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./PartnerActivities.scss";
import bookingImg from "../../img/booking-confirmation-img.png";
import LoadingOverlay from "react-loading-overlay";
import InnerHeader from "../../components/InnerHeader/InnerHeader";
import Input from "../../components/Input/Input";
import { isLoggedIn } from "../../api/NewLoginService";

function PartnerActivities({ setIsLoading, source }) {
  const location = useLocation();
  const partner = location?.state > 0 ? location.state : localStorage.getItem('partner-aktiviteter');
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const [visible, setVisible] = useState(8);

  const [search, setSearch] = useState("");

  const fetchData = async () => {
    setIsLoading(true);

    let apicall = FetchService.GetAllActivitiesByPartnerId(partner);

    return await apicall
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setActivities(data.filter((activity) => activity?.isDeleted === false));
        setFilteredActivities(
          data.filter((activity) => activity?.isDeleted === false)
        );

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    setFilteredActivities(
      activities.filter((x) => x.name.toLowerCase().includes(search))
    );
  }, [search]);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  return (
    <div className="searchRsult_page">
      <div className="wrapper">
        <div className="filter_main">
          <div className="search_otr">
            <Input
              InputClass="Theme_input_white search_input"
              Inputype="search"
              InputName="search"
              InputPlaceholder="SØK"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
        <h3 className="heading filter_heading">
          Aktivitet (
          {filteredActivities.length > 0 ? filteredActivities.length : 0})
        </h3>
      </div>
      <div className="row row_custom">
        {filteredActivities?.slice(0, visible).map((activity) => (
          <>
            <SearchResultCard
              CardImg={
                activity.images
                  ? JSON.parse(activity.images)?.[0].imageURL
                  : bookingImg
              }
              CardHeading={activity.name}
              CardDesc={activity.description}
              activity={activity}
              partnerId={partner}
              source={source}
            />
          </>
        ))}
      </div>

      {activities.length > 8 && (
        <div className="action_otr">
          <button onClick={showMoreItems} className="Theme_btn_primary">
            {"Last mer"}
          </button>
        </div>
      )}
    </div>
  );
}

export default PartnerActivities;

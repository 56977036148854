import React, { useState, useEffect } from "react";
import Select from "../../components/Select/Select";
import FetchService from "../../api/FetchService";

import "./PartnerOrder.scss";

import ProfileImg from "../../img/profile-img.png";
import { Link } from "react-router-dom";
import { isLoggedIn } from "../../api/NewLoginService";

import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "@material-ui/core";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";

function PartnerOrderPage({ setIsLoading }) {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [search, setSearch] = useState("");
  const userObject = useState(isLoggedIn());
  const [page, setPage] = useState("orders");

  //If this page is called from admin, view partner order, it will have partner id in uselocation state
  //but if this page is openend from partner side, the partner id will be in isLoggedIn user object

  let partnerId = useLocation()?.state?.id ?? userObject[0].user.id;

  const fetchData = () => {
    FetchService.GetOrderByPartnerId(partnerId, "partner").then((data) => {
      setOrders(data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
    FetchService.GetCouponsByPartnerId(partnerId).then((data) => {
      setCoupons(data.data);
      setTimeout(() => {

        setIsLoading(false);
      }, 1000);
    });
  };

  const confirmOrder = (order) => {
    FetchService.VerifyOrder(order)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        alert("Kode er brukt");
        fetchData();
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  function deleteCoupon(id){
    FetchService.DeleteCoupon(id).then((response) => {
      return response.data;
    })
    .then((data) => {
      alert("Kupong fjernet");
      fetchData();
    });
  }

  function getCouponsTable(){
    return (
      <table className="data_table">
          <thead className="table_header">
            <tr>
              <th>
                <p className="heading-xsb header_text">Kupong-id</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Aktivitetens navn</p>
              </th>

              <th>
                <p className="heading-xsb header_text">Kode</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Bruk</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Verdt</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Handling</p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {coupons.map((coupons) => (
              <tr className="partner_row">
                <td>
                  <p className="heading-xs body_text">{coupons.id}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{coupons.activityName}</p>
                </td>
                <td>
                  <p className="heading-xsb body_text">{coupons.code}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{coupons.totalHitUsed}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{coupons.worth} nok</p>
                </td>
                <td>
                  <div className="icon_otr">
                    <button
                      onClick={() => {
                        deleteCoupon(coupons.id);
                      }}
                      class=" bg-danger Theme_btn_primary partner-page-button activity py-2 m-lg-2"
                    >
                      Kode brukt
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    )
  }

  function getOrderTable(){
    return (
      <table className="data_table">
          <thead className="table_header">
            <tr>
              <th>
                <p className="heading-xsb header_text">Ordre Nummer</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Kundenavn</p>
              </th>

              <th>
                <p className="heading-xsb header_text">Kode</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Aktivitetsnavn</p>
              </th>
              <th>
                <p className="heading-xsb header_text">By</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Salgssum</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Handling</p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {orders.map((order) => (
              <tr className="partner_row">
                <td>
                  <p className="heading-xs body_text">{order.Id}</p>
                </td>
                <td>
                  <div className="profile_otr">
                    <img className="profile_img" src={ProfileImg} alt="img" />
                    <p className="heading-xs profile_name">
                      {order.FirstName} {order.LastName}
                    </p>
                  </div>
                </td>

                <td>
                  {/* if not verified, show as normal, */}
                  {!order?.Verified && (
                    <p className="heading-xs body_text">{order.Code}</p>
                  )}
                  {/* else, show bold and red */}
                  {order?.Verified && (
                    <p className="heading-xs body_text verifiedOrder">
                      {order.Code}
                    </p>
                  )}
                </td>
                <td>
                  <p className="heading-xsb body_text">{order.ActivityName}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{order.City}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{order.price} nok</p>
                </td>
                <td>
                  <div className="icon_otr">
                    <button
                      onClick={() => {
                        navigate("/bestilt-aktivitet", { state: order.Id });
                      }}
                      class="Theme_btn_primary partner-page-button activity py-2"
                    >
                      Se aktivitet
                    </button>
                    {!order?.Verified && (
                      <button
                        onClick={() => {
                          confirmOrder(order);
                        }}
                        class=" bg-danger Theme_btn_primary partner-page-button activity py-2 m-lg-2"
                      >
                        Kode brukt
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    )
  }

  return (
    <div className="order_page_main partner_order">
      <div className="heading_filter_otr filter_search_otr ">
      <div className="radio-group">
        <label className="radio" onClick={() => setPage("orders")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={page === "orders"}
          />
          {"Bestillinger"}
        </label>
        <label className="radio" onClick={() => setPage("coupons")}>
          <input
            type="radio"
            name="filter"
            defaultChecked={page === "coupons"}
          />
          {"Kuponger"}
        </label>
      </div>
        <p className="heading_activity heading-lb">{page=="orders"? "Ordre" : "Kuponger"}</p>

 {/* TODO: add this in future
        <div className="filter_search_otr">
          <div className="search_otr">
            <Input
              InputClass="Theme_input_white search_input"
              Inputype="search"
              InputName="search"
              InputPlaceholder="SØK"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div> */}
      </div>
      <div className="order_table">
      {
        page=="orders"? getOrderTable() : getCouponsTable()
      }
      </div>
    </div>
  );
}

export default PartnerOrderPage;

import FetchService from "../../api/FetchService";
import React, { useState, useEffect } from "react";

import "./AnalyticsPartnerPage.scss";
import Input from "../../components/Input/Input";
import { getPartnerAnalyticsSortFilters } from "../../commons/activity-filters/Helpers";
import Select from "../../components/Select/Select";
import { API } from "../../api/API-Url";

function AnalyticsPartnerPage() {
  const [allData, setAllData] = useState([]);

  const [filteredPartners, setFilteredPartnerss] = useState([]);
  const [search, setSearch] = useState("");

  const sortFilters = getPartnerAnalyticsSortFilters();
  const [sortFilter, setSortFilter] = useState("default");

  const fetchData = () => {
    FetchService.GetAnalyticsPartner().then((data) => {
      setFilteredPartnerss(data.data);
      setAllData(data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filter = allData.filter((x) =>
      x.PartnerName.toLowerCase().includes(search)
    );
    setFilteredPartnerss(filter);
  }, [search]);

  useEffect(() => {
    let result = allData;

    if (sortFilter === "ordersAsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return a.TotalOrders - b.TotalOrders;
        }),
      ];
    } else if (sortFilter === "ordersDsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return b.TotalOrders - a.TotalOrders;
        }),
      ];
    } else if (sortFilter === "salesAsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return a.TotalSales - b.TotalSales;
        }),
      ];
    } else if (sortFilter === "salesDsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return b.TotalSales - a.TotalSales;
        }),
      ];
    } else if (sortFilter === "activitiesAsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return a.TotalActivities - b.TotalActivities;
        }),
      ];
    } else if (sortFilter === "activitiesDsc") {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return b.TotalActivities - a.TotalActivities;
        }),
      ];
    } else {
      result = [
        ...filteredPartners.sort(function (a, b) {
          return a.PartnerId - b.PartnerId;
        }),
      ];
    }

    setFilteredPartnerss(result);
  }, [sortFilter]);

  return (
    <div className="order_page_main partner_order">
      <div className="heading_filter_otr"></div>
      <div className="searchContainer">
        <h1 className=" heading-lb">Partnere</h1>
        <div className="filter_search_otr">
          <div className="search_otr">
            <div className="sort_filter_container">
              <Input
                InputClass="Theme_input_white search_input"
                Inputype="search"
                InputName="search"
                InputPlaceholder="SØK"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Select
                options={sortFilters}
                defaultText="Acitivity Type"
                value={sortFilter}
                setValue={setSortFilter}
              />

              <form id="frm">
                <div className="downloadButton">
                  <div className="analytics_card_inr">
                    <a
                      href={
                        API.url +
                        "DownloadAnalytics?type=AllPartner&key=www.funtlers.com/analytics"
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className={`card_icon ri-file-list-fill`}></i>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="order_table ">
        <table className="data_table">
          <thead className="table_header">
            <tr>
              <th>
                <p className="heading-xsb header_text">Partner-ID</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Partnernavn</p>
              </th>
              <th>
                <p className="heading-xsb header_text">By</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Totale bestillinger</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Totalt salg (NOK)</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Totale aktiviteter</p>
              </th>
              <th>
                <p className="heading-xsb header_text">Partner siden</p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {filteredPartners.map((partner) => (
              <tr className="partner_row">
                <td>
                  <p className="heading-xs body_text">{partner.PartnerId}</p>
                </td>

                <td>
                  <p className="heading-xs body_text">{partner.PartnerName}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{partner.City}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">{partner.TotalOrders}</p>
                </td>
                <td>
                  <p className="heading-xsb body_text">{partner.TotalSales}</p>
                </td>
                <td>
                  <p className="heading-xs body_text">
                    {partner.TotalActivities}
                  </p>
                </td>
                <td>
                  <p className="heading-xs body_text">
                    {partner?.PartnerSince?.slice(0, 10)}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AnalyticsPartnerPage;

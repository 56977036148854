export function getAllActivityCategories() {
  const categories = [
    {
      value: "default",
      label: "Kategori",
      description: ""
    },
    {
      value: "all",
      label: "Alle",
      description: ""
    },
    {
      value: "vennegjeng",
      label: "Vennegjeng",
      description: "Ønsker du å skape gode minner med dine nærmeste venner? Her finner du mange spennende aktiviteter som vil fylle minnebanken. Utforsk vårt utvalg i dag og skap uforglemmelige stunder sammen med din vennegjeng!"
    },
    {
      value: "datenight",
      label: "Datenight",
      description: "Skap magiske øyeblikk med den perfekte datenight-opplevelsen. Her finner du unike og romantiske aktiviteter som vil gi din kjære en minneverdig date som de garantert vil verdsette!"
    },
    // {
    //   value: "barnebursdag",
    //   label: "Barnebursdag",
    // },
    {
      value: "bursdag",
      label: "Bursdag",
      description: "Gjør bursdagen minneverdig med vårt utvalg av gøye bursdagsaktiviteter og gaver. Uansett om du skal feire din egen bursdag, eller arrangere barnebursdag, vil du finne gode forslag til en uforglemmelig bursdagsfeiring. Her finner du også gode ideer til flotte gavekort!"
    },
    {
      value: "familiedag",
      label: "Familiedag",
      description: "Skap verdifulle øyeblikk med en spesiell familiedag full av glede og latter. Her finner du familievennlige aktiviteter som vil sørge for at dere skaper minner for livet. Utforsk våre alternativer i dag og gi din familie en dag de vil elske og huske!"
    },
    {
      value: "egentid",
      label: "Egentid",
      description: "Prioriter deg selv med fokus på velvære og avslapning i ditt eget selskap. Vårt utvalg av aktiviteter er skreddersydd for å gi deg alenetid, hjelpe deg lade opp og ta vare på din egen mentale helse. Invester i kvalitetstid med deg selv for indre ro og balanse!"
    },
    {
      value: "dagsutflukt",
      label: "Dagsutflukt",
      description: "Planlegg den perfekte dagsutflukten med våre spennende aktiviteter og destinasjoner. Enten du søker eventyr i naturen eller kulturelle opplevelser, vil du finne gode alternativ som passer for deg. Utforsk våre dagsutflukt-muligheter i dag og skap minnerike eventyr med venner og familie!"
    },
    {
      value: "teambuilding",
      label: "Teambuilding",
      description: "Det er viktig å ha det gøy på jobb. Styrk felleskapet og produktiviteten med våre skreddersydde teambuilding-aktiviteter. Vårt utvalg av morsomme og utfordrende opplevelser vil hjelpe ditt team med å vokse sammen og nå nye høyder. En god latter forlenger (arbeids)livet!"
    },
    {
      value: "sommerfest",
      label: "Julebord",
      description: "Gjør årets julebord til en minnerik fest ved å kombinere et utvalg av festlige aktiviteter og gode middagssteder. Her finner du unike julebord opplevelser som vil skape en varm og festlig atmosfære for deg og ditt selskap. Utforsk våre julebord alternativer i dag og sikre deg en feiring fylt med mye latter og glede!"
    },
    {
      value: "afterWork",
      label: "After work",
      description: "Her finner du gode ideer som vil hjelpe deg og kollegene dine å slappe av og lade opp etter en travel arbeidsdag. Utforsk våre afterwork muligheter i dag og skap minner utenfor kontoret!"
    },
    // {
    //   value: "julebord",
    //   label: "Julebord",
    // },
    {
      value: "utdrikningslag",
      label: "Utdrikningslag",
      description: "Gjør utdrikningslaget til en uforglemmelig opplevelse med vårt varierte utvalg av aktiviteter og middagssteder. Uansett om dere søker etter eventyr eller avslapning, har vi alternativene som vil gjøre dagen spesiell for bruden eller brudgommen. Utforsk våre ulike forslag til utdrikningslag i dag og skap minner for livet!"
    },
    {
      value: "digitalt",
      label: "Digitalt",
      description: "Jobber teamet digitalt, men ønsker fremdeles å ha det sosialt sammen? Her finner du vårt utvalg av morsomme digitale aktiviteter og opplevelser. Uansett om teamet ønsker å utforske nye muligheter for læring eller underholdning, har vi alternativene som vil styrke samholdet i teamet deres!"
    },
    {
      value: "gratis",
      label: "Gratis",
      description: "Utforsk vårt utvalg av gratis opplevelser og tilbud i din by som vil berike din hverdag - uten å belaste lommeboken. Oppdag og se det fine som finnes tilgjengelig rundt om i byen din, uten at det trenger å koste deg en krone. Nyt dagen!"
    },
    {
      value: "dagsevent",
      label: "Dagsevent",
      description: "Skal du arrangere et event eller business breakfast med jobben, og lurer på hva du skal fylle dagsplanen med? En god miks av business and pleasure er alltid en sikker vinner. Gjør dagen spesiell med en kombinasjon av møterom og aktiviteter i fin harmoni. Gi partnerne eller kundene dine en opplevelse de sent vil glemme!"
    },
    {
      value: "kurs & konferanse",
      label: "Kurs & Konferanse",
      description: "Enten du søker faglig utvikling, teambygging eller kunnskapsdeling har vi mange spennende alternativer for deg. Utforsk våre kurs og konferansemuligheter i dag og invester i en mer kunnskapsrik og gøyal opplevelse for ditt team!"
    },
  ];

  return categories;
}
export function getAllActivityTypes() {
  const types = [
    {
      value: "default",
      label: "Type",
    },
    {
      value: "all",
      label: "Alle",
    },
    {
      value: "fysisk",
      label: "Fysisk",
    },
    {
      value: "digital",
      label: "Digital",
    },
  ];

  return types;
}

export function getAllCities2() {
    const cities = [
        {
            value: "default",
            label: "By",
        },
        {
            value: "all",
            label: "Alle",
        },
        {
            value: "oslo",
            label: "Oslo",
        },
        {
            value: "bergen",
            label: "Bergen",
        },
        {
            value: "digitalt",
            label: "Digitalt",
        },
        // {
        //   value: "stavanger",
        //   label: "Stavanger",
        // },
        // {
        //   value: "trondheim",
        //   label: "Trondheim",
        // },
        // {
        //   value: "tromsø",
        //   label: "Tromsø",
        // },
    ];
    return cities;
}


export function getAllCities() {
  const cities = [
    {
      value: "default",
      label: "Sted",
    },
    {
      value: "all",
      label: "Alle",
    },
    {
      value: "oslo",
      label: "Oslo",
    },
    {
      value: "bergen",
      label: "Bergen",
    },
    {
      value: "digitalt",
      label: "Digitalt",
    },
    // {
    //   value: "stavanger",
    //   label: "Stavanger",
    // },
    // {
    //   value: "trondheim",
    //   label: "Trondheim",
    // },
    // {
    //   value: "tromsø",
    //   label: "Tromsø",
    // },
  ];
  return cities;
}



export function getBudgetOptions() {
  const budgetOptions = [
    {
      value: "default",
      label: "Pris",
    },
    {
      value: "all",
      label: "Alle",
    },
    { value: "0", label: "Gratis" },
    {
      value: "1",
      label: "1 - 99 NOK",
    },
    {
      value: "2",
      label: "100 - 499 NOK",
    },
    {
      value: "3",
      label: "500 - 999 NOK",
    },
    {
      value: "4",
      label: "1000+ NOK",
    },
  ];

  return budgetOptions;
}




export function getBudgetOptions2() {
    const budgetOptions = [
        {
            value: "default",
            label: "Pris",
        },
        {
            value: "all",
            label: "Alle",
        },
        { value: "0", label: "Gratis" },
        {
            value: "1",
            label: "1 - 499  NOK",
        },
        {
            value: "2",
            label: "500 - 999  NOK",
        },
        {
            value: "3",
            label: "1000 - 1499  NOK",
        },
        {
            value: "4",
            label: "1500+ NOK",
        },
    ];

    return budgetOptions;
}


export function getNumberOfPeopleOptions() {
  const peopleOptions = [
    {
      value: "default",
      label: "Antall",
    },
    {
      value: "all",
      label: "Alle",
    },
    {
      value: "0",
      label: "1 Person",
    },
    {
      value: "1",
      label: "2 Personer",
    },
    {
      value: "2",
      label: "3 - 9 Personer",
    },
    {
      value: "3",
      label: "10+ Personer",
    },
  ];

  return peopleOptions;
}

export function getNumberOfPeopleOptions2() {
    const peopleOptions = [
        {
            value: "default",
            label: "Antall",
        },
        {
            value: "all",
            label: "Alle",
        },
        {
            value: "0",
            label: "1 - 9 Personer",
        },
        {
            value: "1",
            label: "10 - 29  Personer",
        },
        {
            value: "2",
            label: "30 - 99  Personer",
        },
        {
            value: "3",
            label: "100+ Personer",
        },
    ];

    return peopleOptions;
}

export function getFilters(type) {
  let filterModal = {
    categories: "",
    types: "",
      cities: "",
      cities2:"",
      peopleNumber: "",
      peopleNumber2: "",

      budgetOptions: "",
      budgetOptions2: "",

  };

  filterModal.types = getAllActivityTypes();
  filterModal.categories = getAllActivityCategories();
    filterModal.budgetOptions = getBudgetOptions().filter(x => x.label != "Gratis");
    filterModal.budgetOptions2 = getBudgetOptions2().filter(x => x.label != "Gratis");

    filterModal.peopleNumber = getNumberOfPeopleOptions();
    filterModal.peopleNumber2 = getNumberOfPeopleOptions2();

    filterModal.cities = getAllCities();
    filterModal.cities2 = getAllCities2();


  //Removing all type pf option if we are add any activity
  if (type === "add") {
    filterModal.types = filterModal.types.filter(
      (x) => x.value !== "all" && x.value !== "default"
    );
    filterModal.categories = filterModal.categories.filter(
      (x) => x.value !== "all" && x.value !== "default"
    );
    filterModal.budgetOptions = filterModal.budgetOptions.filter(
      (x) => x.value !== "all" && x.value !== "default"
    );
    filterModal.peopleNumber = filterModal.peopleNumber.filter(
      (x) => x.value !== "all" && x.value !== "default"
    );
    filterModal.cities = filterModal.cities.filter(
      (x) => x.value !== "all" && x.value !== "default"
    );
  }

  return filterModal;
}

export function getFilteredActivities(activities, filterModal) {
  const [minPeople, maxPeople] = getMinMax(filterModal.peopleNumber);
  const [minBudget, maxBudget] = getBudget(filterModal.budget);

  const filteredActivities = activities.filter(
    (activity) =>
      (filterModal.city === "all" ||
        filterModal.city === "default" ||
        activity.city === filterModal.city) &&
      (filterModal.type === "all" ||
        filterModal.type === "default" ||
        activity.activityType === filterModal.type) &&
      (filterModal.category === "all" ||
        filterModal.category === "default" ||
        activity.category === filterModal.category) &&
      (filterModal.peopleNumber === "all" ||
        filterModal.peopleNumber === "default" ||
        (activity.minPerson >= minPeople && activity.maxPerson <= maxPeople)) &&
      (filterModal.budget === "all" ||
        filterModal.budget === "default" ||
        (activity.price >= minBudget && activity.price <= maxBudget))
  );

  return filteredActivities;
}

export function getMinMax(peopleNumber) {
  let min = 0;
  let max = 0;

  if (peopleNumber === "default" || peopleNumber === "all" || !peopleNumber)
    return [-1, -1];

  if (peopleNumber === "0") {
    min = 1;
    max = 1;
  } else if (peopleNumber === "1") {
    min = 2;
    max = 2;
  } else if (peopleNumber === "2") {
    min = 3;
    max = 9;
  } else {
    min = 10;
    max = 999;
  }

  return [min, max];
}



export function getMinMax2(peopleNumber) {
    let min = 0;
    let max = 0;

    if (peopleNumber === "default" || peopleNumber === "all" || !peopleNumber)
        return [-1, -1];

    if (peopleNumber === "0") {
        min = 1;
        max = 9;
    } else if (peopleNumber === "1") {
        min = 10;
        max = 29;
    } else if (peopleNumber === "2") {
        min = 30;
        max = 99;
    } else {
        min = 100;
        max = 999;
    }

    return [min, max];
}





export function getBudget(budget) {
  let min = 0;
  let max = 0;

  if (budget === "default" || budget === "all" || !budget) return [-1, -1];

  if (budget === "0") {
    min = 0;
    max = 0;
  } else if (budget === "1") {
    min = 1;
    max = 99;
  } else if (budget === "2") {
    min = 100;
    max = 499;
  } else if (budget === "3") {
    min = 500;
    max = 999;
  } else {
    min = 1000;
    max = 999999;
  }

  return [min, max];
}


export function getBudget2(budget) {
    let min = 0;
    let max = 0;

    if (budget === "default" || budget === "all" || !budget) return [-1, -1];

    if (budget === "0") {
        min = 0;
        max = 0;
    } else if (budget === "1") {
        min = 1;
        max = 499;
    } else if (budget === "2") {
        min = 500;
        max = 999;
    } else if (budget === "3") {
        min = 1000;
        max = 1499;
    } else {
        min = 1499;
        max = 999999;
    }

    return [min, max];
}

export function getLabelByValue(filterArray, value) {
  if (!filterArray || filterArray.length === 0) return;
  else {
      const label = filterArray.find((x) => x.value.toLowerCase() === value.toLowerCase())?.label;
    return label;
  }
}

export function getDescriptionByValue(filterArray, value) {
  if (!filterArray || filterArray.length === 0) return;
  else {
    const description = filterArray.find((x) => x.value === value)?.description;
    return description;
  }
}

//This method will return all the activities that are not expired and fall during the live date
export function getValidActivities(activities) {
  const validActivities = activities.filter((x) => x.isLive && x.isValid);

  return validActivities;
}

// for sorting in sales history page
export function getActivityAnalyticsSortFilters() {
  const types = [
    {
      value: "default",
      label: "Default",
    },
    {
      value: "viewsAsc",
      label: "Antall Visninger(lavest-høyest)",
    },
    {
      value: "viewsDsc",
      label: "Antall Visninger(høyest-lavest)",
    },

    {
      value: "ordersAsc",
      label: "Bestillinger(lavest-høyest)",
    },
    {
      value: "ordersDsc",
      label: "Bestillinger(høyest-lavest)",
    },
    {
      value: "salesAsc",
      label: "Totalt salg(lavest-høyest)",
    },
    {
      value: "salesDsc",
      label: "Totalt salg(høyest-lavest)",
    },
  ];

  return types;
}
export function getPartnerAnalyticsSortFilters() {
  const types = [
    {
      value: "default",
      label: "Default",
    },

    {
      value: "ordersAsc",
      label: "Bestillinger(lavest-høyest)",
    },
    {
      value: "ordersDsc",
      label: "Bestillinger(høyest-lavest)",
    },
    {
      value: "salesAsc",
      label: "Totalt salg(lavest-høyest)",
    },
    {
      value: "salesDsc",
      label: "Totalt salg(høyest-lavest)",
    },
    {
      value: "activitiesAsc",
      label: "Totale aktiviteter(lavest-høyest)",
    },
    {
      value: "activitiesDsc",
      label: "Totale aktiviteter(høyest-lavest)",
    },
  ];

  return types;
}
export function getOrderAnalyticsSortFilters() {
  const types = [
    {
      value: "default",
      label: "Default",
    },

    {
      value: "priceAsc",
      label: "Pris(lavest-høyest)",
    },
    {
      value: "priceDsc",
      label: "Pris(høyest-lavest)",
    },
  ];

  return types;
}

import React from "react"
import './Payments.scss';


function Payments() {


    return (

        <>
            <div>Payments work!</div>
        </>
    )

}



export default Payments;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import InnerHeader from "../InnerHeader/InnerHeader";
import Input from "../Input/Input";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import { register } from "../../api/LoginService";
import FetchService from "../../api/FetchService";
import LoadingOverlay from "react-loading-overlay";
import ImageUploading from "react-images-uploading";
import { ImageUploader } from "../../utility-components/ImageUploader/ImageUploader";
import { getFilters } from "../../commons/activity-filters/Helpers";
import deleteImg from "../../img/remove.png"
import {getAllActivityCategories} from "../../commons/activity-filters/Helpers"

import Form from "react-bootstrap/Form";
import "./AddActivity.scss";
import AttachActivity from "../../Dashboard/AttachActivity/AttachActivity";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const priceModelArray = [
  { label: "Per person", value: "person" },
    { label: "Per aktivitet", value: "activity" },
    { label: "Per time", value: "time" },

];

let activity = {
  name: "",
  city: "",
  price: 0,
  originalPrice: 0,
  validPeriodEnd: "",
  validPeriodStart: "",
  liveDate: "",
  terms: "",
    description: "",
    childoriginalPrice: 0,
    childprice: 0,
  //imagePath: "",
  minPerson: 0,
  maxPerson: 0,
  discountPercent: 0,

  addDate: new Date().toISOString().slice(0, 10),

  instagram: null,
  facebook: null,
  youtube: null,
  priceModel: "person",
};

function AddActivity({ setIsLoading }) {
  const [images, setImages] = useState([]);

  const [name, setName] = useState("");
  const [type, setType] = useState("fysisk");
  const [city, setCity] = useState(["oslo"]);

    const [childoriginalPrice, setchildOriginalPrice] = useState(0);
    const [childprice, setChildPrice] = useState(0);


  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);

  const [priceModel, setPriceModel] = useState("person");

  const [validPeriodStart, setValidPeriodStart] = useState("");
  const [validPeriodEnd, setValidPeriodEnd] = useState("");
  const [liveDate, setLiveDate] = useState("");

  const [description, setDescription] = useState("");
  const [terms, setTerms] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");

  const [minPerson, setMinPerson] = useState(0);
  const [maxPerson, setMaxPerson] = useState(0);

  const [category, setCategory] = useState("vennegjeng");
  const [categories, addCategory] = useState(["vennegjeng"])

  const [response, setResponse] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [partnerId, setPartnerID] = useState(0);
    const [partnerName, setPartnerName] = useState("");


// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
  "meirio",
  "cursive",
  "gothic"
];
Quill.register(Font, true);

  const modules = {
    toolbar: [
      [{ 'font': ['Inter','Roboto','monospace'] }],
      [{ 'align': [false, 'center', 'right'] }],
      [{ 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ 'size': ['small', false, 'large', 'huge'] }]
    ],
  };

  const formats = [
    'font',
    'align',
    'list',
    'bold',
    'italic',
    'underline',
    'strike',
    'link',
    'size'
  ];
    const filters = getFilters("add");
    const [childPriceOption, setChildPriceOption] = useState(activity.childStripePaymentID ? 'allowed' : 'notAllowed');

    const handleChildPriceChange = (event) => {
        setChildPriceOption(event.target.value);
    };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (images === null || images.length == 0) {
        alert("Error: Images cannot be empty");
        setIsLoading(false);


    } else {
        activity.name = name;
        let cityE = "";
        city.forEach((categoryE, index) => {
            if (index !== 0) {
                cityE += ", " + categoryE;
            } else {
                cityE += categoryE;
            }
        })

        activity.city = cityE;
        
      activity.price = +price;

      activity.originalPrice = +originalPrice;

        if (childPriceOption == "notAllowed") {
            activity.childprice = 0;
            activity.childoriginalPrice = 0;
            activity.childStripePaymentID = activity.childstripePaymentID;
            activity.childStripeProductID = activity.childStripeProductID;
        }
        else {
            activity.childprice = +childprice;
            activity.childoriginalPrice = +childoriginalPrice;
            activity.childStripePaymentID = activity.childstripePaymentID;
            activity.childStripeProductID = activity.childStripeProductID;

        }



      activity.validPeriodEnd = validPeriodEnd;
      activity.validPeriodStart = validPeriodStart;
      activity.liveDate = liveDate;

      activity.description = description;
      activity.terms = terms;

      activity.minPerson = +minPerson;
      activity.maxPerson = +maxPerson;
      let newCat = "";
      categories.forEach((categoryE,index)=>{
        if(index!==0){
          newCat += ", " + categoryE;
        }else{
          newCat += categoryE;
        }
      })
      activity.category = newCat;
      activity.activityType = type;
      activity.discountPercent = ((originalPrice - price) / originalPrice) * 100 || 0;
      activity.ownerID = partnerId;

      //  setIsLoading(true);
      activity.images = images.map((x) => x.data_url);

      activity.instagram = instagram ?? null;
      activity.facebook = facebook ?? null;
      activity.youtube = youtube ?? null;

      activity.priceModel = priceModel;

      //console.table(activity);


      FetchService.AddActivity(activity).then((response) => {
          if (response) {
              setSession(null);

          setResponse("Success");
          clearForm();
          window.scrollTo(0, 0);
          setIsLoading(false);
        } else {
          setResponse("Failed");
          window.scrollTo(0, 0);
          setIsLoading(false);
        }
      });
    }
  };

    function setSession(obj) {
        sessionStorage.setItem("SearchResultAdmin", JSON.stringify(obj));
    }

  function clearForm() {
    setName("");
    setPrice(0);
    setOriginalPrice(0);
    setMinPerson(0);
    setMaxPerson(0);
    setValidPeriodEnd("");
    setValidPeriodStart("");
    setTerms("");
    setDescription("");
    setLiveDate("");
    setPartnerID(0);
    setPartnerName(0);
    setImages([]);
    setFacebook("");
    setInstagram("");
    setYoutube("");
  }

  const handleTerms = (props) =>{
    setTerms(props)
  }

  const handleDesc = (props) =>{
    setDescription(props)
  }



  function getCategoryName(categoryVal){
    const categoriesLabels = getAllActivityCategories();
    const nameCategory = categoriesLabels.filter(categoryLabel => categoryLabel.value === categoryVal);
    return nameCategory[0].label;
  }

  const deleteCategory = (props) =>{
      const delCategory = props.target.dataset.category
      if(categories.length<=1){
        return 0;
      }
      return addCategory(categories.filter(categoryF => categoryF !== delCategory))
  }

  return (
    <>
      <div className="Register_page">
        {/* Todo: might need to change this in future for better design
        <InnerHeader HeaderHeading="Activity" PageText="Add Activity" />
     */}

        <section className="">
          <div className="container">
            <div className="row_custom">
              <div className="col_form_otr">
                <div className="col_form_inr">
                  <h3 className="heading-h3 form_heading">
                    Legg til aktivitiet
                  </h3>
                  {response === "Failed" && (
                    <div className="error_message">
                      {
                        "Feil: Systemet kunne ikke lagre aktiviteten. Vær så snill, prøv på nytt."
                      }
                    </div>
                  )}
                  {response === "Success" && (
                    <div className="success_message">
                      {"Aktiviteten er lagt til."}
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="form_main">
                    <div className="row row_custom_inr row_custom_inr1">
                      <div className="col-lg-6 col_input_otr">
                        <div className="col_input_inr">
                          <div className="Input_otr">
                            <Input
                              InputClass="Theme_input_white form_input"
                              Inputype="text"
                              InputName="name"
                              label="Aktivitetsnavn"
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                            />
                          </div>
                          <div className="Input_otr">
                            {"Kategori"}
                            <Form.Select
                              className="Theme_input_white form_input"
                              aria-label="Default select example"
                              value={category}
                              onChange={(event) => {
                                if(!categories.includes(event.target.value)) {
                                  addCategory(categories.concat(event.target.value));
                                  setCategory(event.target.value);
                                }
                              }}
                            >
                              {filters.categories.map((option) => (
                                <option value={option.value}>
                                      {categories.includes(option.value) ? "✔" : (<p>&nbsp;&nbsp;&nbsp;</p>)} {option.label.replace("Dagsevent", "Pa kontoret").replace("Julebord", "Firmafest")}
                                </option>
                              ))}
                            </Form.Select>
                            {categories.map(categoryOne=>(
                              <div className="my-1">
                                <img src={deleteImg} alt="delete" onClick={deleteCategory} key={categoryOne} data-category={categoryOne} className="delete_category_button"/>{getCategoryName(categoryOne)}
                              </div>
                            ))}
                          </div>
                          <div className="Input_otr">
                            {"By"}
                            <Form.Select
                              className="Theme_input_white form_input"
                              aria-label="Default select example"
                               value={city[city.length-1]}
                                                          onChange={(event) => {
                                                              if (!city.includes(event.target.value)) {
                                                                  setCity([...city, event.target.value]); 
                                                              }
                                  

                              }}
                            >
                              {filters.cities.map((option) => (
                                <option value={option.value}>
                                      {city.includes(option.value) ? "✔" : (<p>&nbsp;&nbsp;&nbsp;</p>)}  {option.label}
                                </option>
                              ))}
                               </Form.Select>
                                {city.map(categoryOne => (
                                    <div className="my-1">
                                        <img src={deleteImg} alt="delete" onClick={(event) => {
                                            setCity(city.filter(cityf => cityf !== categoryOne));

                                        }} key={categoryOne} data-category={categoryOne} className="delete_category_button" />{categoryOne}
                                    </div>
                                ))}
                          </div>
                          <div className="Input_otr">
                            {"Aktivitetstype"}
                            <Form.Select
                              className="Theme_input_white form_input"
                              aria-label="Default select example"
                              value={type}
                              onChange={(event) => {
                                setType(event.target.value);
                              }}
                            >
                              {filters.types.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                                                  </div>
                                                  <div>
                                                      <h3>Alternativ for barnas pris:</h3>
                                                      <label>
                                                          <input
                                                              type="radio"
                                                              name="childPrice"
                                                              value="notAllowed"
                                                              checked={childPriceOption === 'notAllowed'}
                                                              onChange={handleChildPriceChange}
                                                          />
                                                          Barnas pris ikke tillatt
                                                      </label>
                                                      <br />
                                                      <label>
                                                          <input
                                                              type="radio"
                                                              name="childPrice"
                                                              value="allowed"
                                                              checked={childPriceOption === 'allowed'}
                                                              onChange={handleChildPriceChange}
                                                          />
                                                          Barnas pris tillatt
                                                      </label>



                                                  </div>
                                                  <br />
                                                  <div className="row">
                                                      <h5>Antall voksen:</h5>
                                                  </div>
                          <div className="row">
                            <div className="Input_otr col ">
                              <Input
                                InputClass="Theme_input_white form_input"
                                Inputype="number"
                                InputName="number"
                                label="Pris"
                                value={price}
                                onChange={(event) =>
                                  setPrice(event.target.value)
                                }
                              />
                            </div>
                            <div className="Input_otr col ">
                              <Input
                                InputClass="Theme_input_white form_input"
                                Inputype="number"
                                InputName="number"
                                label="Orginalpris"
                                value={originalPrice}
                                onChange={(event) =>
                                  setOriginalPrice(event.target.value)
                                }
                              />
                                                      </div>
                                                      {childPriceOption === 'allowed' && (
                                                          <>
                                                              <div className="row">
                                                                  <h5>Antall barn:</h5>
                                                              </div>
                                                              <div className="row">
                                                                  <div className="Input_otr col">
                                                                      <Input
                                                                          InputClass="Theme_input_white form_input"
                                                                          Inputype="number"
                                                                          InputName="number"
                                                                          label="Pris"
                                                                          value={childprice}
                                                                          onChange={(event) => setChildPrice(event.target.value)}
                                                                      />
                                                                  </div>
                                                                  <div className="Input_otr col">
                                                                      <Input
                                                                          InputClass="Theme_input_white form_input"
                                                                          Inputype="number"
                                                                          InputName="number"
                                                                          label="Orginalpris"
                                                                          value={childoriginalPrice}
                                                                          onChange={(event) =>
                                                                              setchildOriginalPrice(event.target.value)
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </>
                                                      )}

                            <div className="Input_otr ">
                              {"Pristype"}
                              <Form.Select
                                className="Theme_input_white form_input"
                                aria-label="Default select example"
                                value={priceModel}
                                onChange={(event) => {
                                  setPriceModel(event.target.value);
                                }}
                              >
                                {priceModelArray.map((option) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                                                  </div>
                                                

                        </div>
                      </div>
                      <div className="col-lg-6 col_img_otr">
                        <div className="col_img_inr">
                          <div className="image-uploader">
                            <ImageUploader setImagesCallBack={setImages} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="number"
                          InputName="number"
                          label="Minimumspersoner"
                          value={minPerson}
                          onChange={(event) => setMinPerson(event.target.value)}
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="number"
                          InputName="number"
                          label="Maksimumspersoner"
                          value={maxPerson}
                          onChange={(event) => setMaxPerson(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Gyldig fra"
                          value={validPeriodStart}
                          onChange={(event) =>
                            setValidPeriodStart(event.target.value)
                          }
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Gyldig Til"
                          value={validPeriodEnd}
                          onChange={(event) =>
                            setValidPeriodEnd(event.target.value)
                          }
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Live fra"
                          value={liveDate}
                          onChange={(event) => setLiveDate(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="facebook"
                        label="Facebook (Valgfri)"
                        value={facebook}
                        onChange={(event) => setFacebook(event.target.value)}
                      />
                    </div>

                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="youtube"
                        label="Instagram (Valgfri)"
                        value={instagram}
                        onChange={(event) => setInstagram(event.target.value)}
                      />
                    </div>
                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="youtube"
                        label="Youtube (Valgfri)"
                        value={youtube}
                        onChange={(event) => setYoutube(event.target.value)}
                      />
                    </div>
                    <div className="Input_otr">
                      <label className="label_input heading-xs">
                        {" "}
                        Beskrivelse
                      </label>

                      {/* <textarea
                        className="Theme_input_white form_input description"
                        name="description"

                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      /> */}

                        <ReactQuill
                          className="Theme_input_white form_input description"
                          modules={modules}
                          formats={formats}
                          onChange={handleDesc}
                          name="description"
                          value={description}
                        />
                    </div>
                    <div className="Input_otr">
                      <label className="label_input heading-xs"> Vilkår</label>

                      {/* <textarea
                        className="Theme_input_white form_input description"
                        type="text"
                        name="terms"
                        value={terms}
                        onChange={(event) => setTerms(event.target.value)}
                      /> */}

                        <ReactQuill
                          className="Theme_input_white form_input description"
                          modules={modules}
                          formats={formats}
                          onChange={handleTerms}
                          name="terms"
                          value={terms}
                        />
                    </div>

                    <div>
                      {/* Add activity button if the partner is not attached. */}
                      {partnerId !== 0 && (
                        <>
                          <div className="Input_otr">
                            <p>
                              <b>Partner Id: </b> {partnerId}
                            </p>
                            <br />
                            <p>
                              <b>Partnernavn: </b> {partnerName}
                            </p>
                          </div>
                          <div className="Input_otr action_otr">
                            <button
                              type="submit"
                              className="Theme_btn_primary form_btn"
                            >
                              Legg til aktivitet
                            </button>
                          </div>
                        </>
                      )}
                      {partnerId === 0 && (
                        <button
                          type="button"
                          className="Theme_btn_primary form_btn"
                          onClick={() => {
                            setShowPopup(true);
                          }}
                        >
                          Knytt til partner
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {showPopup && (
        <div className="backdrop">
          <div className="backdrop-container">
            <AttachActivity
              setShowPopup={setShowPopup}
              setPartnerID={setPartnerID}
              setPartnerName={setPartnerName}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddActivity;

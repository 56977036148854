import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from 'react-router-dom';

import "./OrderPlan.css";
import Select from "../../components/Select/Select";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { Modal, Alert, Toast, ToastContainer } from 'react-bootstrap'
import LoadingOverlay from "react-loading-overlay";
import dayjs from 'dayjs';
import 'dayjs/locale/nb'; // Import Norwegian locale file // Import dayjs

import {
    getFilters,
    getMinMax,
    getMinMax2,
    getBudget,
    getBudget2,
    getNumberOfPeopleOptions,
    getFilteredActivities,
    getLabelByValue,
    getValidActivities,
    getDescriptionByValue
} from "../../commons/activity-filters/Helpers";
import FetchService from "../../api/FetchService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import OrderPlanSearchResultCard from "../SearchResultCard/OrderPlanSearchResultCard ";

import OrderPlanSearchResultCardRemove from "../SearchResultCard/OrderPlanSearchResultCardRemove";


import CalenderComponent from "../CalenderComponent/CalenderComponent";


let filterModel = {
    category: "all",
    type: "all",
    city: "all",
    peopleNumber: "all",
    budget: "all",
};
function OrderPlan({ searchQuery, setSearchQuery }) {

    


    dayjs.locale('nb'); // Set the locale to Norwegian Bokmål

    let filter = JSON.parse(sessionStorage.getItem("filter"));

    let state = useLocation().state;
    const searchFilter = state ? state : filter;
    const [selectedDate, setSelectedDate] = useState(searchFilter?.selectedDate);
    const [selectedDateVELG, setSelectedDateVELG] = useState(null);

    const navigate = useNavigate();
    const locat = useLocation()
    const filters = getFilters();
    const [city, setCity] = useState(searchFilter?.city2);
    const [queryData, setQueryData] = useState([]);
    const [type, setType] = useState(searchFilter?.type);
    const [one, setOne] = useState(true);
    const [two, setTwo] = useState(false);
    const [four, setFour] = useState(false);
    const [six, setSix] = useState(false);
    const [twelve, setTwelve] = useState(false);
    const [gettedFromSession, setGettedFromSession] = useState(false)
    const [activityHover, setActivityHover] = useState();
    let categoryLabel =
        getLabelByValue(filters.categories, searchFilter?.category != "" && searchFilter?.category != undefined && searchFilter?.category != null ? searchFilter?.category : "gratis") ?? "";

    let categoryDescription = getDescriptionByValue(filters.categories, searchFilter?.category != "" ? searchFilter?.category : "gratis") ?? "";

    const category = searchFilter?.category ?? "";
    const [peopleNumber, setPeopleNumber] = useState(searchFilter?.peopleNumber);
    const [budget, setBudget] = useState(searchFilter?.budget);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showCalender, setShowCalender] = useState(false);
    const [showCalenderVELG, setShowCalenderVELG] = useState(false);

    const [showModalVELG, setShowModalVELG] = useState(false);
    const [showModalRmv, setShowModalRmv] = useState(-1);

    const [openCircle, setOpenCircle] = useState(1);
    const [activities, setActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [updateState, setUpdateState] = useState(-1);

    const [visible, setVisible] = useState(8);
    const [isLoading, setIsLoading] = useState(true);

    const [pageNumber, setPageNumber] = useState(1);
    const [activitiesCount, setActivitiesCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const { action } = useParams();
    const [isHovered, setIsHovered] = useState(false);

    const [text, setText] = useState('');

    // Event handler to update the text state when the textbox value changes
    const handleChange = (event) => {
        setText(event.target.value);
    };


    useEffect(() => {
        // Your function here
        widget.filter((x, i) => { tranformWidget(i) });
    }, [one, two, four, six, twelve]); // This effect will re-run whenever one of these states changes



    const handleReset = (event) => {

        clearSession();
        event.preventDefault();
        setSelectedDate(null);

        setType("default");
        setCity("default");
        setBudget("default");
        setPeopleNumber("default");
        
        /***************************************************/
        setIsScrolled(false);
        setShowCalender(false);
        setOpenCircle(1);
        setActivities([]);
        setFilteredActivities([]);
        setUpdateState(-1);
        /***************************************************/

        setVisible(8);
        setIsLoading(true);

        setPageNumber(1);
        setActivitiesCount(0);
        setTotalPages(0);
        
      

        getPaginatedActivities();
        setWidget([]);
        const updatedActivities = activities?.map((obj) => {
            return { ...obj, isVelgClick: null, isVelgDisable: null };
        });

        setActivities(updatedActivities);

        setIsLoading(true);


    };


    const handleActivityEditClick = (activity) => {

        setShowModalVELG(activity);
        setSelectedDateVELG(activity.addDate);
    
    };


    const handleActivityDelClick = (activityId) => {
        setShowModalRmv(showModalRmv-1);
        const filtered = widget.filter((val) => val.id !== activityId);
        const updatedActivities = activities.map((obj) => {
            if (obj.id === activityId) {
                return { ...obj, isVelgClick: null, isVelgDisable: null };
            }
            return obj;
        });

        setActivities(updatedActivities);
        setWidget(filtered);
        setIsHovered(false);
    };

    const handleMouseHover = (data) => {
        setIsHovered(true);
        setActivityHover(data);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const [widget, setWidget] = useState([])
    const handleDrag = (e, type) => {
        
        e.dataTransfer.setData("WidgetType", JSON.stringify(type))

    }
    const handleOnDrop = (e) => {
        if ((one === true && widget.length < 1) || (two === true && widget.length < 2) || (four === true && widget.length < 4) || (six === true && widget.length < 6) || (twelve === true && widget.length < 12)) {
            const type = e.dataTransfer.getData("WidgetType");
            const widgetObject = JSON.parse(type);


            setShowModalVELG(false);
            activities.map((obj) => {
                if (obj.id == widgetObject.id) {
                    obj.isVelgClick = true;
                    obj.totPersons = totalPersons - widgetObject.minPerson;
                }
            });
            setActivities(prevActivities => {
                const updatedActivities = [...prevActivities]; // Create a copy of the previous state
                // Perform any updates to the updatedActivities array here
                return updatedActivities; // Return the updated state
            });




            setWidget([...widget, widgetObject])
        }
    }

    const simulateDragging = (type) => {
        
        if ((one === true && widget.length < 1) || (two === true && widget.length < 2) || (four === true && widget.length < 4) || (six === true && widget.length < 6) || (twelve === true && widget.length < 12)) {
            const widgetObject = type;
            setWidget([...widget, widgetObject])
        }

    }



    const checkdowngrade = (newvalue)=>
    {
        widget.filter((x, i) => { tranformWidget(i) });

        
        let value = 0;
        if (one == true) {
            value = 1;
        }
        else if (two == true) {
            value = 2;
        }
        else if (four === true) {
            value = 4;
        }
        else if (six === true) {
            value = 6;
        }
        else {
            value = 12;
        }

        if (value > newvalue) {

            return value - newvalue;
        }


        return 0;




    }


    const handleVelgClick = (e, activity) => {
        
        e.preventDefault();
        if ((one === true && widget.length < 1) || (two === true && widget.length < 2) || (four === true && widget.length < 4) || (six === true && widget.length < 6) || (twelve === true && widget.length < 12))
        {

            setTotalPersons(activity.minPerson);
            setShowModalVELG(activity)
        }
        //activities.map((obj) => {
        //    if (obj.id == activity.id) {
        //        obj.isVelgClick = true;
        //    }
        //});
        //setActivities(prevActivities => {
        //    const updatedActivities = [...prevActivities]; // Create a copy of the previous state
        //    // Perform any updates to the updatedActivities array here
        //    return updatedActivities; // Return the updated state
        //});

    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const scrollToActivity = () => {
        const element = document.querySelector('.scrollToActivity');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // Możesz zmienić na 'end', jeśli chcesz przewinąć do końca elementu
            });
        }
    };

    //useMemo function that will set page number to 1 if any filter is changed.
    // because then we want the activities array to be reset to zero.

    const filterChanged = useMemo(
        () => {
            
            if ((city != undefined && city != "") || (type != undefined && type != "") || category != "" || (peopleNumber != undefined && peopleNumber != "") || (budget != undefined && budget != "")) {
                const sessionCheck = JSON.parse(sessionStorage.getItem("SearchResult"));

                if (sessionCheck?.pages[0].category.category == category || category=="") return;
                clearSession();
                setPageNumber(1);
            }
        },
        [city, type, category, peopleNumber, budget]
    );

    function getPaginatedActivities() {
        ReactGA.event({
            action: 'Search_action',
            category: 'Search_category',
            label: 'Search_label',
            value: "Click on Search Button"
        })



        let session = getSession()
        if (session && !gettedFromSession) {
            setGettedFromSession(true)
            session.activity.map((val) => val.totPersons = 0)
            setActivities(session.activity);
            setActivitiesCount(session.pages[0]?.total);
            setTotalPages(session.pages[0]?.totalPages);
            setVisible(session.activity.length + 1);
            setPageNumber(session.pageNo);

            setIsLoading(false);
            if (!isScrolled) {
                setIsScrolled(true);
                setTimeout(function () {
                    scrollToActivity();
                }, 500);
            }
        }
        else {
            setIsLoading(true);

            
            const filter = getFilterRequestModel();
            let apicall2 = FetchService.getActivitiesByPagination(filter);

            apicall2
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    
                    setActivitiesCount(data.pages[0]?.total);
                    setTotalPages(data.pages[0]?.totalPages);
                    //if pagenumber is 1, that means we need to emppty array instead of concat
                    if (pageNumber === 1) {
                        data.activity.map((val)=>val.totPersons=0)
                        setActivities(data.activity);
                    } else {
                        //concat array on load more button click instead of creating a new list
                        data.activity = activities.concat(data.activity);
                        // Remove duplicates based on the 'id' property
                        data.activity = data.activity.filter((activity, index, self) =>
                            index === self.findIndex((a) => a.id === activity.id)
                        );





                        data.activity.map((val) => val.totPersons = 0)
                        setActivities(data.pages[0]?.total>0 ? data.activity : []);
                    }



                    data.pageNo = pageNumber;
                    data.pages[0].category = filter;
                    setSession(data);
                    setIsLoading(false);
                });
        }

    }

    function clearSession() {
        setSession(null);
    }

    function setSession(obj) {
        sessionStorage.setItem("SearchResult", JSON.stringify(obj));
        sessionStorage.setItem("filter", JSON.stringify({ "selectedDate": selectedDate, "city2": city, "category": category, "type": type, "peopleNumber": peopleNumber, "budget": budget }));
    }

    function getSession() {
        return JSON.parse(sessionStorage.getItem("SearchResult"));
    }
    function setFilterModel() {
        filterModel.category = category === "" ? "all" : category;
        filterModel.type = type === "" ? "all" : type;
        filterModel.city2 = city === "" ? "all" : city;
        filterModel.peopleNumber = peopleNumber === "" ? "all" : peopleNumber;
        filterModel.budget = budget === "" ? "all" : budget;
    }
    //This method return a filter object that will be needed to be passed to the backend
    //to return paginated data.
    function getFilterRequestModel() {
        //getting updated filtermodel from the respective states
        setFilterModel();

        let filter = {
            pageNo: 1,
            rowCountPerPage: 8,
            isValid: true,
            isLive: true,
            city: null,
            type: null,
            category: "teambuilding,afterWork,sommerfest,dagsevent,digitalt,kurs & konferanse",
            minPrice: -1,
            maxPrice: -1,
            minPerson: -1,
            maxPerson: -1,
            checkDate: null,
            isB2B:1
        };

        //filter.category = filterModel.category;
        filter.checkDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
        filter.type = filterModel.type;
        filter.city = filterModel.city2;

        [filter.minPerson, filter.maxPerson] = getMinMax2(filterModel.peopleNumber);
        [filter.minPrice, filter.maxPrice] = getBudget2(filterModel.budget);

        filter.pageNo = pageNumber;
        filter.minPrice = 1;


        return filter;
    }
    useEffect(() => {
        
        getPaginatedActivities("filter");
        console.log(searchFilter?.category)
    }, [city, type, category, peopleNumber, budget, pageNumber, selectedDate]);


    useEffect(() => {
        widget.filter((x, i) => { tranformWidget(i) });
    }, [widget]);



    const handlePrint = () => {
        var printContents = document.getElementById('pdfDivMain').innerHTML;
        var originalContent = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    const matchActFilter = (inputDateString) => {
        //const inputs = "2023-11-23T00:00:00";
        const date = new Date(inputDateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it starts from 0
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }
    const getMonthDate = (val) => {
        const date = new Date(val);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return month
    }

    const [totalPersons, setTotalPersons]=useState(0)
    const [totalPrice, setTotalPrice]=useState(0)
    const totalGetting = () => {
        widget.map((val) => {
            var a = val.price * (val.minPerson + val.totPersons)
            var b = totalPrice + a
            console.log(totalPrice)
            setTotalPrice((prev)=>prev+a)
        })
    }

    const showLessItems = () => {
        clearSession();
        setPageNumber(pageNumber - 1);
        setVisible((prevValue) => prevValue - 8);
    };

    const showMoreItems = () => {
        clearSession();
        setPageNumber(pageNumber + 1);
        setVisible((prevValue) => prevValue + 8);
    };
    const pdfRef = useRef()
    const downloadPDF = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfheight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfheight / imgHeight);
            const imgx = (pdfWidth - imgWidth * ratio) / 2;
            const imgy = 30;
            pdf.addImage(imgData, 'PNG', imgx, imgy, imgWidth * ratio, imgHeight * ratio);
            pdf.save('funtlers.pdf');
        });
    }

    const [showModalCircle, setShowModalCircle] = useState(false);
    const [circleType, setCircleType] = useState('single');
    const [circleStatus, setCircleStatus] = useState('single');
    const [circleData, setCircleData] = useState([]);
    const [circleName, setCircleName] = useState("");
    const [reqType, setReqType] = useState("");
    const handleCloseModal = () => {
        setShowModalCircle(false)
        setReqType("")
        setTotalPrice(0)
        setTotalPersons([])
    }


    const tranformWidget = (i) => {

        
        // Assuming widget is an array of DOM elements
        let firstElement = document.getElementsByClassName('hoverid' + widget[0].id);
        let secondElement = document.getElementsByClassName('hoverid' + widget[i].id);

        let finalelemtn = document.getElementsByClassName('hoverid' + widget[i].id +'-header');

        // Check if elements are found
        if (firstElement.length === 0 || secondElement.length === 0) {
            return "translate(0px, 0px)";
        }

        // Get positions of both elements
        let firstRect = firstElement[0].getBoundingClientRect();
        let secondRect = secondElement[0].getBoundingClientRect();

        // Calculate differences in X and Y coordinates
        let diffX = secondRect.left - firstRect.left;
        let diffY = secondRect.top - firstRect.top;

        // Create translation strings
        let translateX = `translateX(${diffX}px)`;
        let translateY = `translateY(${diffY}px)`;

        // If you want to combine both translations into a single string
        let combinedTranslation = `translate(${diffX}px, ${diffY}px)`;

        // Now you can use translateX, translateY, or combinedTranslation wherever needed
        finalelemtn[0].style.transform = combinedTranslation;


    }
    const handleNotifyAdmin = () => {
                var requestt = {
                name: users.firstName + " " + users.lastName,
                email: "",
                subject: "Ny B2B forespørsel",
                body: `<h3 className="text-center" >Kunde e-post: ${users.email}</h3><br/>
                <div className="row m-4 d-flex justify-content-center align-items-end" >
                        ${widget.map((val) => `<div className=" my-3 col-md-6" >
                <div className='searchResultContent' >
                    <small><b>AKTIVITETSNAVN: ${val.name}</b></small><br/>
                    <small>DATO FORESPURT:  ${matchActFilter(val.addDate)}</small><br/>
                    <small>ANTALL PERSONER:  ${val.minPerson + val.totPersons}</small><br/>
                    <small>PRIS PER PERSON: ${val.price}</small><br/>
                    <small> <b>TOTAL PRIS AKTIVITET 1:${val.price * (val.minPerson + val.totPersons)}</b></small><br/>
                </div>
                <hr />
                </div>`)
                }
                    </div>
                    <h3 className="mx-4 text-center fw-bold" >SUMMERT ALLE AKTIVITETER</h3>
                    <div className="m-4" >
                    ${widget.map((val, i) =>
                    `<div className=" my-3 col-md-6" >
                        <div className='searchResultContent' >
                            <small>Aktivitet ${i + 1}: ${val.price * (val.minPerson + val.totPersons)}</small>
                        </div>
                    </div>`)}


                        <hr />
                        <strong >Total pris: ${totalPrice}</strong>
                    </div>
                        <br />
                        <div><b>Tilleggsinformasjon</b>: ${text}</div>

                `
                }
            setText('');
            FetchService.SendEmailRequest(requestt).then((val) => {
                console.log(val)
            }).catch((e) => console.log(e))
        
    }

    
    const handleCircle = async (status) => {

        
        var CircleDetails = []
        setIsLoading(true)
        widget.map((val) => {
            const { id } = val;
            var circleObj = {
                ActivityId:id
            }

            CircleDetails.push(circleObj)
        })
        var Circle = {
                circleName:circleName,
                circleStatus: status,
                circleType: circleType,
        }
        var obj = {
            Circle: Circle,
            circleDetails: CircleDetails
        }
        FetchService.AddCircle(obj).then((val) => {
            handleCloseModal()
            setCircleName("")
            CircleDetails = []
            setWidget([])
            setIsLoading(false)
            const updatedActivities = activities.map((obj) => {
                    return { ...obj, isVelgClick: null, isVelgDisable: null };
            });

            setActivities(updatedActivities);


            if (status === "pending") {
            handleNotifyAdmin()
            setShowModalDone(true)
            }
        }).catch((e) => {
            setIsLoading(false)
            console.log(e)
        })
    }
    const handleDeleteCircle = async (id) => {
        FetchService.DeleteCircle(id).then((val) => {

            const filteredData = circleData.filter((data) => data.circleId !== id)
            setCircleData(filteredData)
            console.log(val)
        }).catch((e) => console.log(e))
    }
    const getCircles = async (status) => {
            setCircleData([])
        FetchService.getAllCircles(status, circleType).then((val) => {
            console.log(val.data)
            setCircleData(val.data) 
        }).catch((e) => console.log(e))
    }
    const getCircleById = async () => {
        setWidget([])
        setIsLoading(true)
        FetchService.GetCircleById('saved', circleType, "id", locat.state['cirId']).then((val) => {
            setWidget(val.data)
            setCircleName(val.data[0]['circleName'])
            console.log(val.data)
            setIsLoading(false)
            if (val.data.length === 1) {
                setOne(true)
                setTwo(false)
                setFour(false)
                setSix(false)
                setTwelve(false)
            }
            if (val.data.length === 2) {
                setOne(false)
                setTwo(true)
                setFour(false)
                setSix(false)
                setTwelve(false)
            }
            if (val.data.length === 4) {
                setOne(false)
                setTwo(false)
                setFour(true)
                setSix(false)
                setTwelve(false)
            }
            if (val.data.length === 6) {
                setOne(false)
                setTwo(false)
                setFour(false)
                setSix(true)
                setTwelve(false)
            }
            if (val.data.length === 12) {
                setOne(false)
                setTwo(false)
                setFour(false)
                setSix(false)
                setTwelve(true)
            }
        }).catch((e) => {

        setIsLoading(false)
            console.log(e)
        })
    }
    const handleCircleUpdate = async () => {
        var CircleDetails = []
        widget.map((val) => {
            const { id, circleDetailId, circleId } = val;
            var circleObj = {
                circleId: locat.state['cirId'],
                ActivityId: id
            }

            CircleDetails.push(circleObj)
        })
        var Circle = {
            Id: locat.state['cirId'],
            circleName:circleName,
            circleStatus: 'saved',
            circleType: circleType
        }
        var obj = {

            Circle: Circle,
            circleDetails: CircleDetails
        }
        FetchService.UpdateCircle(obj).then((val) => {
            handleCloseModal()
            setCircleName("")
            navigate(-1);
            getCircles("saved")
            setUpdateState(-1)
            setWidget([])
        }).catch((e) => console.log(e))
    }

    useEffect(() => {
        if (action && (action === 'edit2' || action === 'add2')) {
            setCircleType("multiple")
            setOne(false)
            setTwo(true)
            setFour(false)
            setSix(false)
            setTwelve(false)
        }
        if(action==="edit1") {
            setCircleType("single")
        }
    }, [])
    useEffect(() => {
            getCircles("saved")
    }, [circleType,action])
    useEffect(() => {
        if (locat.state !== null) {
            getCircleById()
            setUpdateState(1)
        }
        if (locat.state === null) {
            setUpdateState(-1)
        }
    }, [locat])
    var circlesArr = []
    var circlesArr2 = []
    var circlesIds = []
    var circleIdArr=0
    var count = 0
    const [hoverActivity, setHoverActivity] = useState({})
    const [showModalReqBooking, setShowModalReqBooking] = useState(false)
    const [showModalReqBooking2, setShowModalReqBooking2] = useState(false)

    const [showModalDone, setShowModalDone] = useState(false)
    const [users,setUsers]=useState()
    useEffect(() => {
        const userObject = sessionStorage.getItem("userObject");
        const { user } =JSON.parse(userObject);
        setUsers(user)
    }, [])
    useEffect(() => {
        var dataa = circleData.filter((val) => val.circleName.toLowerCase().includes(searchQuery.toLowerCase()))
        setQueryData(dataa)
    }, [searchQuery])
    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text="Behandler forespørselen din"
        >
            {action && action === "add1" && <><h1 style={{ fontSize: "3rem" }} className="text-center admin_heading fw-bold">FINN ENGANGSAKTIVITET</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">HER FINNER DERE OPPLEVELSER SOM PASSER TIL NESTE TEAMBUILDING, AFTER WORK, JOBBFEST ELLER EN GØY AKTIVITET INTERNT PÅ KONTORET. <br />BRUK FILTERET UNDER, OG PLANLEGG DERES NESTE SOCIAL!
                    </p>
                </div></>}
            {action && action === "edit1" && <><h1 className="text-center admin_heading fw-bold">REDIGER ENGANGSAKTIVITET</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">HER FINNER MAN EN OVERSIKT OVER ALLE ENGANGSAKTIVITETENE DERE VURDERER Å BOOKE. <br />MAN KAN ENKELT JUSTERE ANTALL PERSONER, BUDSJETT ELLER ENDRE SELVE AKTIVITETEN. <br />KLIKK PÅ AKTIVITETEN DU ØNSKER Å REDIGERE!
                    </p>
                </div></>}
            {action && action === "add2" && <><h1 style={{ fontSize: "3rem" }} className="text-center admin_heading fw-bold">LAG SOSIAL KALENDER</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">SKREDDERSY EN SOSIAL KALENDER SOM PASSER BEDRIFTEN DERES. <br />VELG HYPPIGHET, ANTALL PERSONER OG BUDSJETT - OG FYLL ÅRET MED HYGGELIGE OPPLEVELSER.<br />VÅRT TIPS ER Å LAGE EN MIX AV ULIKE OPPLEVELSER: TEAMBUILDING, AFTER WORK, JOBBFEST ELLER EN GØY AKTIVITET INTERNT PÅ KONTORET.
                    </p>
                </div></>}
            {action && action === "edit2" && <><h1 className="text-center admin_heading fw-bold">REDIGER SOSIAL KALENDER</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">HER INNER MAN EN OVERSIKT OVER ALLE SOSIALE ÅRSHJUL DERE HAR SKAPT. <br />MAN KAN ENKELT JUSTERE HYPPIGHET, ANTALL PERSONER, BUDSJETT ELLER ENDRE AKTIVITETER. <br />KLIKK PÅ ÅRSHJULET DU ØNSKER Å REDIGERE.
                    </p>
                </div></>}
        <div>
            <div className="row">
                    <div className="col-lg-6 ">
                        {action && (action === "add1" || action === "add2") &&
                            <h3 className="text-center fw-bold">DRA AKTIVITETER INN i HJULET</h3>
                        }
                    <div className="d-flex flex-column align-items-center">
                    {/*1 button*/}
                            {action && (action === 'add1') && <div className="d-flex justify-content-center align-items-center row w-100" >
                                <div className="col-6 my-2" >
                                    <button className="btnOrder my-3 w-100">ENGANGSAKTIVITET</button>
                                </div>
                            </div>}
                        {/*2 buttons*/}
                        {action && (action === 'add2' ) && <>
                            <div className="row d-flex align-items-center justify-content-center mt-3 w-100">
                                <div className="col-6">
                                        <button style={{ backgroundColor: twelve && "#FC9765" }} onClick={() => {
                                            let chkd = checkdowngrade(12);
                                            if (chkd) {

                                               if (widget.length > 12) {
                                                    setShowModalRmv(widget.length - 12);
                                                }
                                          


                                            }
                                        setOne(false)
                                        setTwo(false)
                                        setFour(false)
                                        setSix(false)
                                        setTwelve(true)


                                    }}
                                            className="btnOrder w-100 my-2">HVER MÅNED</button>

                                </div>
                                <div className="col-6">
                                        <button style={{ backgroundColor: six && "#FC9765" }} onClick={() => {
                                            let chkd = checkdowngrade(6);
                                            if (chkd) {

                                             if (widget.length > 6) {
                                                    setShowModalRmv(widget.length - 6);
                                                }
                                              


                                            }
                                        setOne(false)
                                        setTwo(false)
                                        setFour(false)
                                        setSix(true)
                                        setTwelve(false)
                                        }} className="btnOrder w-100 my-2">ANNENHVER MÅNED</button>

                                </div>                         
                                <div className="col-6">
                                        <button style={{ backgroundColor: four && "#FC9765" }} onClick={() => {
                                            let chkd = checkdowngrade(4);
                                            if (chkd) {

                                                if (widget.length > 4) {
                                                    setShowModalRmv(widget.length - 4);
                                                }
                                             


                                            }
                                        setOne(false)
                                        setTwo(false)
                                        setFour(true)
                                        setSix(false)
                                        setTwelve(false)
                                        }} className="btnOrder w-100 my-2">HVERT KVARTAL</button>
                                </div>
                                <div className="col-6">
                                        <button style={{ backgroundColor: two && "#FC9765" }} onClick={() => {
                                            let chkd = checkdowngrade(2);
                                            if (chkd) {
                                          if (widget.length > 2) {
                                                    setShowModalRmv(widget.length-2);
                                                }
                                        
                                               

                                            }
                                        setOne(false)
                                        setTwo(true)
                                        setFour(false)
                                        setSix(false)
                                        setTwelve(false)
                                        }} className="btnOrder w-100 my-2">EN GANG I HALVÅRET</button>
                                </div>
                                </div>
                            </>
                        }

                        {/*Circle for save */}
                        {action && (action === 'add1' || action === 'add2') &&
                            //Circle
                            <>
                                <div className='d-flex justify-content-center w-100 align-items-center mx-4' >
                                    <div id="pdfDivMain" className="w-100">
                                        <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle my-4">
                                            <div onDrop={handleOnDrop} onDragOver={handleDragOver} className="iconCircle">
                                                {widget.map((activity, i) => {
                                                    return <>
                                                     
                                                    <div onMouseEnter={() => handleMouseHover(activity)} onMouseLeave={handleMouseLeave} onMouseOver={() => setHoverActivity(activity)} key={i} class="imgBx" style={{
                                                        width: (one && "210px") || (two && "150px") || (four && "90px") || (six && "90px") || (twelve && "70px"),
                                                        height: (one && "210px") || (two && "150px") || (four && "90px") || (six && "90px") || (twelve && "70px"),
                                                        transform: `rotate(calc(360deg /${((one || two) && 2) || (four && 4) || (six && 6) || (twelve && 12)}* ${i}))`,
                                                        transformOrigin:two?"260px":"235px"
                                                        }}>
                                                        <div style={{
                                                            transform: `rotate(calc(-360deg /${((one || two) && 2) || (four && 4) || (six && 6) || (twelve && 12)}* ${i}))`
                                                            }} className='iconHidden w-100  h-100    ' >

                                                            <i style={{ padding: (one && "8px 12px") ||(two && "3px 6px") || ((four || six) && "1px 3px") || (twelve && "1px 2px"), fontSize:twelve&&"1rem" }} onClick={() => {
                                                                    const filtered = widget.filter((val) => val.id !== activity.id);
                                                                    activities.map((obj) => {
                                                                    if (obj.id == activity.id) {
                                                                        obj.isVelgClick = null;
                                                                        obj.isVelgDisable = null;
                                                                    }
                                                                    });
                                                                        setActivities(prevActivities => {
                                                                            const updatedActivities = [...prevActivities]; // Create a copy of the previous state
                                                                            // Perform any updates to the updatedActivities array here
                                                                            return updatedActivities; // Return the updated state
                                                                        });
                                                                    setWidget(filtered);
                                                                    setIsHovered(false)
                                                            }} class="ri-delete-bin-7-line "></i>

                                                            <i style={{ padding: (one && "8px 12px") || (two && "3px 6px") || ((four || six) && "1px 3px") || (twelve && "1px 2px"), fontSize: twelve && "1rem" }} onClick={() => handleActivityEditClick(activity)} class="ri-edit-2-fill"></i>

                                                            {/*<i style={{ padding: (one && "8px 12px") || (two && "3px 6px") || ((four || six) && "1px 3px") || (twelve && "1px 2px"), fontSize: twelve && "1rem" }} onClick={() => handleActivityDelClick(activity.id)}  class="ri-add-line"></i>*/}
                                                            {/*<i style={{ padding: (one && "8px 12px") || (two && "3px 6px") || ((four || six) && "1px 3px") || (twelve && "1px 2px"), fontSize: twelve && "1rem" }} onClick={() => {*/}
                                                            {/*        const ad=activity.minPerson+activity.totPersons*/}
                                                            {/*        if (activity.minPerson < ad) {*/}

                                                            {/*        var xd = activity.totPersons - 1;*/}
                                                            {/*        setTotalPersons(xd)*/}
                                                            {/*        activity.totPersons = xd*/}
                                                            {/*        setHoverActivity(activity)*/}
                                                            {/*        }*/}
                                                            {/*      }} class="ri-subtract-line"></i>*/}
                                                            </div>

                                                     
                                                            <img className={'hoverid'+activity.id}  style={{
                                                            transform: `rotate(calc(-360deg /${((one || two) && 2) || (four && 4) || (six && 6) || (twelve && 12)}* ${i}))`
                                                        }} src={activity.images && (activity.images
                                                                ? JSON.parse(activity.images)?.[0].imageURL
                                                                    : 'bookingImg').replace("{baseApiURL}", "https://funtlers.com/") || activity.imageURL && (activity.imageURL.replace("{baseApiURL}", "https://funtlers.com/"))} alt="Pic" />

                                                          
                                                        </div>

                                                        <h5 className={'header-circle ' + 'hoverid' + activity.id + '-header'} style={{
                                                            top: (two && "111px") || ((four) && "135px") || ((six) && "136px") || (twelve && "134px"), fontSize: twelve && "1rem"
                                                        }}>
                                                            <b >{dayjs(activity.addDate).locale('nb').format('MMMM')}</b>
                                                        </h5>
                                                    </>
                                                })}
                                    </div>
                                            <div class="contentCircle">
                                                {isHovered && <div className='searchResultContent' >
                                                    <small  class="circleBx" >Aktivitet: {hoverActivity.name}</small>
                                                    <small  class="circleBx">Dato:  {matchActFilter(hoverActivity.addDate)}</small>
                                                    <small class="circleBx">Antall personer:  {(hoverActivity.minPerson + hoverActivity.totPersons)}</small>
                                                    <small  class="circleBx">Pris per person: {hoverActivity.price}</small>
                                                    <small  class="circleBx">Total pris aktivitet 1: {hoverActivity.price * (hoverActivity.minPerson + hoverActivity.totPersons)}</small>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                                {action && action === 'edit1' && <div className="row">
                                    <div className=" col-6 d-flex justify-content-center" >
                                        <i class="ri-edit-box-line" style={{ fontSize: '2.4rem', cursor: 'pointer' }} ></i>
                                        <i class="ri-delete-bin-6-line" style={{ fontSize: '2.4rem', cursor: 'pointer' }} ></i>
                                    </div>
                                    {action && action === 'edit2' &&
                                        <div className="col-6 d-flex justify-content-center" >
                                            <i class="ri-delete-bin-6-line" style={{ fontSize: '2.4rem', cursor: 'pointer' }} ></i>
                                            <i class="ri-edit-box-line" style={{ fontSize: '2.4rem', cursor: 'pointer' }} ></i>
                                        </div>}
                                </div>}
                                <div class="ins">
                                Hold over aktiviteten for å se valgte detaljer.<br/> Juster riktig antall personer ved å trykke på + & - ikonet, for å se total pris.
                                </div>
                                <div className="row d-flex align-items-center justify-content-center w-100 my-3" style={{ width: "108%", important: true }}>
                                    <div className="col-2">
                                    {updateState === -1 ? <button onClick={() => setShowModalCircle(true)} className="btnOrder w-100">LAGRE</button> :
                                        <button onClick={() => {
                                            setShowModalCircle(true)
                                            }} className="btnOrder w-100">REDIGERE</button>}

                                </div>
                                    <div className="col-3">
                                    <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                                    </div>
                                    <div className="col-3">
                                        <button onClick={() => {
                                            totalGetting()
                                            setReqType('pending')
                                            setShowModalReqBooking2(true)
                                        }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                                    </div>
                                    <div className="col-4">
                                        <button onClick={() => {
                                            totalGetting()
                                        setReqType('pending')
                                        setShowModalReqBooking(true)
                                    }} className="btnOrder w-100">FORESPØR BOOKING</button>

                                    </div>
                                </div>
                            </>
                        }

                    </div>
                    </div>


                    {action && (action === 'add1' || action === 'add2') &&
                    ///2nd part Calender
                    <div className="col-lg-6">
                        <div>
                                <h3 className="text-center fw-bold">VELG AKTIVITETER ({activitiesCount})</h3>   
                            <div className="">
                                <form className="d-flex">
                                    <div className="activity_main  align-items-center flex-center">
                                        <ul className="activity_ul d-flex ">
                                            <li className="activity_li" >
                                                <div style={{ cursor: "pointer",paddingRight:   '10px' }} onClick={() => setShowCalender(!showCalender)} className="dropdownCal Theme_select" >
                                                        <span>{selectedDate ? dayjs(selectedDate).format('YY/M/D') : "Dato"}</span>
                                                    <i class="ri-arrow-down-s-line"></i>
                                                </div>
                                                {showCalender && <div className='dropdown-contentCal' >
                                                        <CalenderComponent setSelectedDate={setSelectedDate} selectedDate={selectedDate} setShowCalender={setShowCalender } />
                                                </div>}
                                                </li>
                                            {/*    <li className="activity_li" >*/}
                                            {/*    <Select*/}
                                            {/*        options={filters.types}*/}
                                            {/*        defaultText="Acitivity Type"*/}
                                            {/*        value={type}*/}
                                            {/*        setValue={setType}*/}
                                            {/*    />*/}
                                            {/*</li>*/}
                                            <li className="activity_li" >
                                                <Select
                                                    value={city}
                                                    setValue={setCity}
                                                    options={filters.cities2}
                                                    defaultText="Cities"
                                                    disabled={type === "digital"}
                                                />
                                            </li>
                                            <li className="activity_li" >
                                                <Select
                                                    value={budget}
                                                    setValue={setBudget}
                                                        options={filters.budgetOptions2}
                                                    defaultText="Budget Options"
                                                />
                                            </li>
                                            <li className="activity_li" >
                                                <Select
                                                    setValue={setPeopleNumber}
                                                    value={peopleNumber}
                                                    options={filters.peopleNumber2}
                                                    defaultText="Number of people"
                                                />
                                            </li>
                                        </ul>
                                        <div className=" btnSearchIcon search_icon_otr_local">
                                            <button
                                                className=" text-white ri-search-2-line search_icon_local search-button"
                                                type="submit"
                                            ></button>
                                            </div>


                                            <div class="btnSearchIcon search_icon_otr_local">
                                                <button class="text-white refresh-icon search-button"
                                                onClick={(event) => { handleReset(event); handleReset(event); }}                                                ></button>
                                            </div>
                                    </div>
                                </form>
                                {/* todo: hide it for now */}
                                {false && (
                                    <div className="filter_otr">
                                        <Select defaultText="Flere filtere" />
                                    </div>
                                )}
                            </div>
                                {activities && activities.length>0 && <div className="row row_custom">
                                    {activities?.slice(0, visible).slice(-8).map((activity) => (
                                        <OrderPlanSearchResultCard

                                            isVelgDisable={((one === true && widget.length < 1) || (two === true && widget.length < 2) || (four === true && widget.length < 4) || (six === true && widget.length < 6) || (twelve === true && widget.length < 12)) }
                                            clickFire={(e) =>handleVelgClick(e,activity) }
                                            handleDrag={(e) => handleDrag(e, activity)}
                                            CardImg={
                                                activity.images
                                                    ? JSON.parse(activity.images)?.[0].imageURL
                                                    : 'bookingImg'
                                            }
                                            CardRabat={activity.discountPercent}
                                            CardHeading={activity.name}
                                            CardDesc={
                                                activity.price > 0 ?
                                                    activity.price +
                                                    (activity.priceModel === "time" ? "kr per time" :  activity.priceModel === "person"
                                                        ? "kr per person"
                                                        : "kr per aktivitet") : "Gratis"
                                            }
                                            Data={activity}
                                            source="customer"
                                            peopleNumber={peopleNumber}
                                            city={city}
                                            activityType={type}
                                            price={budget}
                                        />

                                    ))}
                                    {totalPages && totalPages > 1 && activitiesCount > 6 && (
                                        <div className="Theme_btn_primary custom-btn">
                                            {"Side"} {pageNumber} av {totalPages}
                                            <div class="funtlers-cards-container2 ">
                                                <div
                                                    className={`swiper-button-prev d-sm-flex d-none ${pageNumber === 1 ? 'disabled' : ''}`}
                                                    tabIndex="0"
                                                    role="button"
                                                    aria-label="Previous slide"
                                                    onClick={pageNumber === 1 ? null : showLessItems}
                                                ></div>
                                                <div className={`swiper-button-next d-sm-flex d-none ${pageNumber === totalPages ? 'disabled' : ''}`}
                                                    tabindex="-1" role="button" aria-label="Next slide" onClick={showMoreItems}></div>
                                            </div>
                                        </div>
                                    )}


                                </div>}

                                {activities && activities.length == 0 && <div className="row row_custom"><div class="d-flex justify-content-center">
                                    <p class="ins">
                                        <p className="ins" style={{ backgroundColor: "transparent" }}>


                                            Vi hjelper dere gjerne med rabatter og booking. Ettersom dere ønsker noe spesifikt som per nå ikke dukker opp i verktøyet, ber vi deg ta kontakt med oss på <a href="mailto:contact@funtlers.com">contact@funtlers.com</a> slik at vi kan skreddersy en kjekk opplevelse for dere.
                                            
                                        </p>

                                    </p>
                                </div></div>}
                        </div>
                    </div>
                }
                {/*Single Circle for Edit */}
                <div className='row' >
                    {action && action === 'edit1' && circleData.length > 0 && circleData.reverse().filter((val) => val.circleName.toLowerCase().includes(searchQuery.toLowerCase())).filter((val) => val.circleType === "single").map((val,i) =>
                    //Circle
                    (<div className='col-lg-6 my-2'>

                        <div className='d-flex align-items-center' >
                            <div id="pdfDivMain" className='w-100 '>
                                <div className="nameDiv">
                                <h4>{val.circleName}</h4>
                                </div>

                                <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle ">
                                    <div onDrop={handleOnDrop} onDragOver={handleDragOver} className="iconCircle">
                                        <div class="imgBx" style={{ width: "210px", height: "210px" }} >
                                            <img  src={val.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                    </div>
                                    </div>
                                </div>

                                <strong>SKAPT: {val.createdDate !== undefined && val.createdDate !== null && matchActFilter(val.createdDate)}</strong>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center w-100 my-3">
                            <div className="col-6 my-2">
                                <button onClick={() => navigate('/orderplan/add1', { state: { cirId: val.circleId } })} className="btnOrder w-100">REDIGERE</button>

                            </div>
                            <div className="col-6 my-2">
                                <button onClick={() => handleDeleteCircle(val.circleId)} className="btnOrder w-100">SLETTE</button>

                            </div>
                            <div className="col-6 my-2">
                                <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                            </div>

                            <div className="col-6 my-2">
                                <button onClick={() => {
                                    totalGetting()
                                    setReqType('pending')
                                    setShowModalReqBooking2(true)
                                }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                            </div>
                            <div className="col-6 my-2">
                                <button onClick={() => {
                                    totalGetting()
                                    setReqType('pending')
                                    setShowModalReqBooking(true)
                                }} className="btnOrder w-100">FORESPØR BOOKING</button>

                            </div>
                       
                        </div>

                    </div>
                    ))
                    }
                </div>
                {/*Multiple Circle for Edit */}
                    { searchQuery===""&&action && action === 'edit2' && circleData.length > 0 && circleData.reverse().filter((val) => val.circleType === "multiple").map((val,i) =>
                    //Circle
                    {

                        count++
                        if (circleIdArr === 0) {
                            circlesArr = []
                            circlesArr = circlesArr2;
                            circlesArr2 = []
                            circleIdArr=val.circleId
                            
                        }
                        if (circleIdArr === val.circleId) {
                            circlesArr.push(val)
                            var length = circleData.length
                            if (count === length) {
                                return (
                                    <div className='col-lg-6 my-2' key={circleData[i-1]['cicleId'] }>
                                    <div className='d-flex align-items-center' >
                                            <div id="pdfDivMain" className='w-100 '>
                                                <div className="nameDiv">
                                                    <h4>{circleData[i - 1]['circleName']}</h4>
                                                </div>

                                                <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle my-5"  >
                                                    <div onDrop={handleOnDrop} onDragOver={handleDragOver}  className="iconCircle">
                                                        {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                            width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                            height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                            transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                            transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                        }}>
                                                            <img style={{
                                                                transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                            }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                        </div>)}
                                                       
                                                    </div>
                                                </div>
                                                <strong>SKAPT: {val.createdDate !== undefined && val.createdDate !== null && matchActFilter(circleData[i - 1]['createdDate'])}</strong>

                                        </div>
                                    </div>
                                        <div className="row d-flex align-items-center justify-content-center w-100 my-3">
                                            <div className="col-6 my-2">
                                                <button onClick={() => navigate('/orderplan/add2', { state: { cirId: circleData[i - 1]['circleId'] } })} className="btnOrder w-100">REDIGERE</button>

                                        </div>
                                            <div className="col-6 my-2">
                                                <button className="btnOrder w-100" onClick={() => handleDeleteCircle(circleData[i - 1]['circleId'])} >SLETTE</button>

                                        </div>
                                            <div className="col-6 my-2">
                                            <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                                            </div>
                                            <div className="col-6 my-2">
                                                <button onClick={() => {
                                                    totalGetting()
                                                    setReqType('pending')
                                                    setShowModalReqBooking2(true)
                                                }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                                            </div>
                                            <div className="col-6 my-2">
                                                <button onClick={() => {
                                                    totalGetting()
                                                    setReqType('pending')
                                                    setShowModalReqBooking(true)
                                                }} className="btnOrder w-100">FORESPØR BOOKING</button>

                                        </div>
                                    </div>

                                </div>
                                )

                            }
                        }
                        else {
                            circlesArr2.push(val)
                            circleIdArr = 0
                            return (<div className='col-lg-6 my-2' key={circleData[i - 1]['cicleId']} >

                                <div className='d-flex align-items-center' >
                                    <div id="pdfDivMain" className='w-100 '>
                                        <div className="nameDiv">
                                            <h4>{circleData[i - 1]['circleName']}</h4>
                                        </div>

                                        <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle my-5">
                                            <div onDrop={handleOnDrop} onDragOver={handleDragOver} className="iconCircle">
                                                {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                    width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                    height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                    transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                    transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                }}>
                                                    <img style={{
                                                        transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                    }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                </div>)}

                                            </div>
                                        </div>
                                        <strong>SKAPT: {val.createdDate !== undefined && val.createdDate !== null && matchActFilter(circleData[i - 1]['createdDate'])}</strong>

                                    </div>
                                </div>
                                <div className="row d-flex align-items-center justify-content-center w-100 my-3">
                                    <div className="col-6 my-2">
                                        <button onClick={() => navigate('/orderplan/add2', { state: { cirId: circleData[i - 1]['circleId'] } })} className="btnOrder w-100">REDIGERE</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button className="btnOrder w-100" onClick={() => handleDeleteCircle(circleData[i - 1]['circleId'])} >SLETTE</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button onClick={() => {
                                            totalGetting()
                                            setReqType('pending')
                                            setShowModalReqBooking2(true)
                                        }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button onClick={() => {
                                            totalGetting()
                                            setReqType('pending')
                                            setShowModalReqBooking(true)
                                        }} className="btnOrder w-100">FORESPØR BOOKING</button>

                                    </div>
                                </div>

                            </div>
                            )
                        }
       
                    })
                    }

                    {/*Search Multiple Circle for Edit */}

                        {searchQuery !== "" && action && action === 'edit2'&& queryData.reverse().filter((val) => val.circleType === "multiple").map((val,i) =>
                    //Circle
                    {

                        count++
                        if (circleIdArr === 0) {
                            circlesArr = []
                            circlesArr = circlesArr2;
                            circlesArr2 = []
                            circleIdArr=val.circleId
                            
                        }
                        if (circleIdArr === val.circleId) {
                            circlesArr.push(val)
                            var length = queryData.length
                            if (count === length) {
                                return (
                                    <div className='col-lg-6 my-2' key={queryData[i-1]['cicleId'] }>
                                    <div className='d-flex align-items-center' >
                                            <div id="pdfDivMain" className='w-100 '>
                                                <div className="nameDiv">
                                                    <h4>{queryData[i - 1]['circleName']}</h4>
                                                </div>

                                                <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle my-5"  >
                                                    <div onDrop={handleOnDrop} onDragOver={handleDragOver} className="iconCircle">
                                                        {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                            width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                            height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                            transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                            transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                        }}>
                                                            <img style={{
                                                                transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                            }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                        </div>)}

                                                    </div>
                                                </div>
                                          
                                                <strong>SKAPT: {val.createdDate !== undefined && val.createdDate !== null && matchActFilter(queryData[i - 1]['createdDate'])}</strong>

                                        </div>
                                    </div>
                                    <div className="row w-100 my-3">
                                            <div className="col-6 my-2">
                                                <button onClick={() => navigate('/orderplan/add2', { state: { cirId: queryData[i - 1]['circleId'] } })} className="btnOrder w-100">REDIGERE</button>

                                        </div>
                                            <div className="col-6 my-2">
                                                <button className="btnOrder w-100" onClick={() => handleDeleteCircle(queryData[i - 1]['circleId'])} >SLETTE</button>

                                        </div>
                                            <div className="col-6 my-2">
                                            <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                                            </div>
                                            <div className="col-6 my-2">
                                                <button onClick={() => {
                                                    totalGetting()
                                                    setReqType('pending')
                                                    setShowModalReqBooking2(true)
                                                }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                                            </div>
                                        <div className="col-6 my-2">
                                                <button onClick={() => {
                                                    totalGetting()
                                                    setReqType('pending')
                                                    setShowModalReqBooking(true)
                                                }} className="btnOrder w-100">FORESPØR BOOKING</button>

                                        </div>
                                    </div>

                                </div>
                                )

                            }
                        }
                        else {
                            circlesArr2.push(val)
                            circleIdArr = 0
                            return (<div className='col-lg-6 my-2' key={queryData[i - 1]['cicleId']} >

                                <div className='d-flex align-items-center' >
                                    <div id="pdfDivMain" className='w-100 '>
                                        <div className="nameDiv">
                                            <h4>{queryData[i - 1]['circleName']}</h4>
                                        </div>

                                        <div ref={pdfRef} onDrop={handleOnDrop} onDragOver={handleDragOver} id="pdfContent" className="containerCircle my-5"  >
                                            <div onDrop={handleOnDrop} onDragOver={handleDragOver} className="iconCircle">
                                                {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                    width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                    height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                    transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                    transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                }}>
                                                    <img style={{
                                                        transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                    }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                </div>)}
                                            </div>
                                        </div>
                                        <strong>SKAPT: {val.createdDate !== undefined && val.createdDate !== null && matchActFilter(queryData[i - 1]['createdDate'])}</strong>

                                    </div>
                                </div>
                                <div className="row w-100 my-3">
                                    <div className="col-6 my-2">
                                        <button onClick={() => navigate('/orderplan/add2', { state: { cirId: queryData[i - 1]['circleId'] } })} className="btnOrder w-100">REDIGERE</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button className="btnOrder w-100" onClick={() => handleDeleteCircle(queryData[i - 1]['circleId'])} >SLETTE</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button className="btnOrder w-100" onClick={handlePrint} >PRINT UT/LAST NED</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button onClick={() => {
                                            totalGetting()
                                            setReqType('pending')
                                            setShowModalReqBooking2(true)
                                        }} className="btnOrder w-100">  {"Forhåndsvis".toUpperCase()}</button>

                                    </div>
                                    <div className="col-6 my-2">
                                        <button onClick={() => {
                                            totalGetting()
                                            setReqType('pending')
                                            setShowModalReqBooking(true)
                                        }} className="btnOrder w-100">FORESPØR BOOKING</button>

                                    </div>
                                </div>


                            </div>
                            )
                        }
       
                    })
                    }
                </div>


                <Modal centered show={showModalRmv>-1} onHide={() => setShowModalRmv(-1)}>
                    <Modal.Header >
                        <Modal.Title> FJERN AKTIVITETER
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <h4>Det er for mange aktiviteter i kalenderen. Vennligst fjern {showModalRmv} aktiviteter for å fortsette</h4>
                        <br />

                        <div class="row row_custom">
                        {widget?.map((activity) => (
                            <OrderPlanSearchResultCardRemove

                                isDelete={showModalRmv > 0}
                                Delete={(e) => handleActivityDelClick(activity.id)}
                                CardImg={
                                    activity.images
                                        ? JSON.parse(activity.images)?.[0].imageURL
                                        : 'bookingImg'
                                }
                                CardRabat={activity.discountPercent}
                                CardHeading={activity.name}
                                CardDesc={
                                    activity.price > 0 ?
                                        activity.price +
                                        (activity.priceModel === "time" ? "kr per time" : activity.priceModel === "person"
                                            ? "kr per person"
                                            : "kr per aktivitet") : "Gratis"
                                }
                                Data={activity}
                                source="customer"
                                peopleNumber={peopleNumber}
                                city={city}
                                activityType={type}
                                price={budget}
                            />

                        ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex justify-content-center' >
                            <button
                                onClick={() => {
                                    setShowModalRmv(-1)
                                }
                                } className={`btnOrder w-100 ${showModalRmv > 0 ? 'disabled' : ''}`}>JUSTER
                            </button>
                        </div>

                    </Modal.Footer>
                </Modal>



                <Modal centered show={showModalVELG} onHide={() => setShowModalVELG(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title> AKTIVITETSDETALJER
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <div className='my-f' >
                            <div style={{ padding: '10px', width:'71px' }}><strong>Dato</strong></div>
                            
                            <div style={{ cursor: "pointer", paddingRight: '10px', width: '76%' }} onClick={() => setShowCalenderVELG(!showCalenderVELG)} className="dropdownCal Theme_select" >
                                <span>{selectedDateVELG ? dayjs(selectedDateVELG).format('YY/M/D') : "Velg dato"}</span>
                                <i class="ri-arrow-down-s-line"></i>
                            </div>
                            {showCalenderVELG && <div className='dropdown-contentCal' >
                                <CalenderComponent setSelectedDate={setSelectedDateVELG} selectedDate={selectedDateVELG} setShowCalender={setShowCalenderVELG} />
                            </div>}

                          

                        </div>
                        <br/>
                        <div className="VELG-sec" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <div style={{ padding: '10px' }}><strong>Antall</strong></div>
                            <div class="text input-block">
                                 <div class="input-group">
                                <button class="btn" type="button" onClick={() =>
                                        totalPersons > showModalVELG.minPerson
                                            ? setTotalPersons(totalPersons - 1)
                                            : setTotalPersons(totalPersons)
                                }>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="2" viewBox="0 0 24 2">
                                        <g fill="#fff" stroke="#707070" stroke-width="1" opacity="0.45">
                                            <rect width="24" height="2" stroke="none"></rect>
                                            <rect x="0.5" y="0.5" width="23" height="1" fill="none"></rect>
                                        </g>
                                    </svg>
                                </button>
                                    <input type="text" class="form-control bg-transparent" aria-describedby="button-addon" value={totalPersons} />
                                <button class="btn" type="button" onClick={() =>
                                        totalPersons >= showModalVELG.minPerson
                                            ? setTotalPersons(totalPersons + 1)
                                            : setTotalPersons(totalPersons)
                                }>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                        <g transform="translate(-1475.5 -614.076)">
                                            <rect width="17" height="2" transform="translate(1475.5 621.076)" fill="#707070" opacity="0.45"></rect>
                                            <rect width="17" height="2" transform="translate(1485.5 614.076) rotate(90)" fill="#707070" opacity="0.45"></rect>
                                        </g>
                                    </svg>
                                </button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex justify-content-center' >
                            <button
                                onClick={() => {
                                setShowModalVELG(false);
                                activities.map((obj) => {
                                if (obj.id == showModalVELG.id) {
                                    obj.isVelgClick = true;
                                    obj.totPersons = totalPersons - showModalVELG.minPerson;
                                    obj.addDate = selectedDateVELG;
                                }
                                });
                                setActivities(prevActivities => {
                                const updatedActivities = [...prevActivities]; // Create a copy of the previous state
                                // Perform any updates to the updatedActivities array here
                                return updatedActivities; // Return the updated state
                                });
                                simulateDragging(showModalVELG);
                                setSelectedDateVELG(null);
                            }
                            } className={`btnOrder w-100 ${!selectedDateVELG ? 'disabled' : ''}`}>INKLUDER
                            </button> 
                        </div>

                    </Modal.Footer>
                </Modal>


            <Modal centered show={showModalCircle} onHide={handleCloseModal } >
                <Modal.Header closeButton>
                    <Modal.Title>
                        UTKASTET DITT LAGRES
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className='my-4' >
                            <strong>Velg et navn</strong>
                            <input className="form-control" value={circleName} onChange={(e) => setCircleName(e.target.value)} placeholder="Enter Velg et navn" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-center' >
                            {updateState === -1 ? <button onClick={() => {
                                reqType === "pending" ? handleCircle("pending") : 
                                    handleCircle("saved")
                                }
                            } className="btnOrder w-100">
                                {reqType === "pending" ? "FORESPØR BOOKING" :"LAGRE"}
                                </button> :
                                <button onClick={handleCircleUpdate} className="btnOrder w-100">REDIGERE</button>}
                    </div>

                </Modal.Footer>
                </Modal>

                <Modal size="lg" centered show={showModalReqBooking2} onHide={() => {
                    setTotalPrice(0)
                    setShowModalReqBooking2(false)
                }
                } >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <b>Forhåndvisning av aktivitetsdetaljer & priser</b>
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="row m-4 d-flex justify-content-center align-items-end" >
                            {
                                widget.map((val) => <div className=" my-3 col-md-6" >
                                    <div className='searchResultContent' >
                                        <small><b>AKTIVITETSNAVN: {val.name}</b></small>
                                        <small>DATO FORESPURT:  {matchActFilter(val.addDate)}</small>
                                        <small>ANTALL PERSONER:  {val.minPerson + val.totPersons}</small>
                                        <small>PRIS PER PERSON: {val.price}</small>
                                        <small> <b>TOTAL PRIS AKTIVITET 1:{val.price * (val.minPerson + val.totPersons)}</b></small>
                                    </div>
                                    <hr />
                                </div>)
                            }
                        </div>
                        <h3 className="mx-4 text-center fw-bold" >SUMMERT ALLE AKTIVITETER</h3>
                        <div className="m-4" >
                            {
                                widget.map((val, i) =>
                                    <div className=" my-3 col-md-6" >
                                        <div className='searchResultContent' >
                                            <small>Aktivitet {i + 1}: {val.price * (val.minPerson + val.totPersons)}</small>

                                        </div>
                                    </div>
                                )
                            }
                            <hr />
                            <strong >Total pris: {totalPrice}</strong>
                        </div>
                        {/*<p className="text-center my-3" >*/}
                        {/*    Ved å klikke på knappen under, vil teamet til Funtlers motta en kopi av den forespurte bestillingen din. Merk at bestillingen ikke er godkjent før du får en bestillingsbekreftelse på din e-post.*/}
                        {/*</p>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex justify-content-center' >
                            <button onClick={() => {

                                setShowModalReqBooking2(false)
                            }}
                                className="btnOrder w-100">LUKK</button>
                        </div>

                    </Modal.Footer>
                </Modal>


                <Modal size="lg" centered show={showModalReqBooking} onHide={() => {
                    setTotalPrice(0)
                    setShowModalReqBooking(false)
                }
                } >
                <Modal.Header closeButton >
                        <h3 className="mx-4 text-center fw-bold f-1">OPPSUMMERING AV AKTIVITETER</h3>

                </Modal.Header>
                    <Modal.Body>
                        <div className="row m-4 d-flex justify-content-center align-items-end" >
                            {
                                widget.map((val) => <div className=" my-3 col-md-6" >
                                 <div className="d-flex">
                                        <div>
                                            <img style={{
                                                width: '109px',
                                                height: '100px',
                                                marginRight: '13px'
                                            }}
                                                src={val.images && (val.images
                                                ? JSON.parse(val.images)?.[0].imageURL
                                                : 'bookingImg').replace("{baseApiURL}", "https://funtlers.com/") || val.imageURL && (val.imageURL.replace("{baseApiURL}", "https://funtlers.com/"))} alt="Pic" />
                                        </div>
                                    <div className='searchResultContent' >
                                        <small><b>{val.name}</b></small>
                                        <small>DATO FORESPURT:  {matchActFilter(val.addDate)}</small>
                                        <small>ANTALL PERSONER:  {val.minPerson + val.totPersons}</small>
                                        <small>PRIS PER PERSON: {val.price}</small>
                                        <small> <b>TOTAL PRIS AKTIVITET 1:{val.price * (val.minPerson + val.totPersons)}</b></small>
                                    </div>
                                    </div>
                                </div>)
                            }
                        </div>
                        <hr />

                        <h3 className="mx-4 text-center fw-bold" >OPPSUMMERING AV KOSTNADER</h3>
                        <div className="m-4" >
                        {
                            widget.map((val, i) => 
                                <div className=" my-3 col-md-6" >
                                <div className='searchResultContent' >
                                        <small>Aktivitet {i + 1}: {val.price * (val.minPerson + val.totPersons)}</small>

                                </div>
                            </div>
                            )
                            }
                            <hr />
                            <strong >Total pris: {totalPrice}</strong>
                        </div>
                        <p className="text-center my-3" >
                            Ved å klikke på knappen under, vil teamet til Funtlers motta en kopi av den forespurte bestillingen din.<br />Merk at bestillingen ikke er godkjent før du får en bestillingsbekreftelse på din e-post.
                            <br/>
                            Ved å sende inn denne forespørselen godkjenner du våre{' '}
                            <Link
                                to="/vilkar"
                                style={{
                                    cursor: 'pointer', display: 'contents'}}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                vilkår
                            </Link>.                        </p>

                        <hr />

                        <h3 className="mx-4 text-center fw-bold m-2" >TILLEGGSINFORMASJON</h3>
                        <textarea
                            value={text}
                            onChange={handleChange}
                            className="text-box"
                            placeholder="Fyll inn eventuelle forespørsler, allergier eller kommentarer ..."
                            rows={4} // Adjust the number of rows as needed
                            cols={50} // Adjust the number of columns as needed
                        />


                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-center' >
                            <button onClick={() => {

                                reqType === "pending" ? handleCircle("pending") :
                                    handleCircle("saved");
                                setShowModalReqBooking(false)
                            }}
                                className="btnOrder w-100">FORESPØR BOOKING</button>
                    </div>

                </Modal.Footer>
                </Modal>

                {showModalDone && <div className="fade modal-backdrop show"></div>}
                <Alert className="col-md-5" style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 7000
                }}
                 show={showModalDone} variant="success">
                    <p className='d-flex' >
                        Takk for din booking forespørsel. Funtlers teamet vil besvare din henvendelse Ila 3-5 arbeidsdager. Dersom du trenger raskere svar, vennligst send en e-post til contact@funtlers.com direkte.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button  className="btnOrder" onClick={() => setShowModalDone(false)} variant="outline-success">
                            LUKK
                        </button>
                    </div>
                </Alert>
                <div
                    aria-live="polite"
                    aria-atomic="true"
                    className="d-flex justify-content-end"
                    style={{position:"fixed",top:"65px",maxWidth:'100vw' }}
                >
                    <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                 </ToastContainer>
        </div>
        </div>

        </LoadingOverlay>

            );
}

export default OrderPlan;
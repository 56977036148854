import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import FetchService from "../../api/FetchService";
import "./CouponsController.scss";
import "../PartnersPage/PartnersPage.scss";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
import Input from "../Input/Input";
import Form from "react-bootstrap/Form";
import "../Input/Input.scss";

function CouponsController({ setIsLoading }) {
  const [allCoupons, setAllCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [validFrom, setValidFrom] = useState(new Date().toISOString().slice(0, 10));
  const [validTo, setValidTo] = useState(new Date().toISOString().slice(0, 10));
  const [activityId, setActivityId] = useState({id:0,name:"Nei"});
  const [allValidActivities, setAllValidActivities] = useState([{id:0, name:"Nei"}]);
  const [couponAddError, setCouponAddError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [couponId, setCouponId] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [search, setSearch] = useState('');

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  const currentDate = formatDate(new Date());

  useEffect(()=>{
    const res = FetchService.getAllCoupons();

    res.then((data)=>{
      setAllCoupons(data.data);
      return console.log(data);
    })

    const res2 = FetchService.GetAllValidActivities();

    res2.then((data)=>{
      setAllValidActivities(data.data)
    })
  },[])

  const addCoupon = () =>{
    let isValid = true;
    if(activityId.id==0) isValid = false;
    if(couponCode.length<=1) isValid = false;
    if(discount==0) isValid = false;
    if(validFrom=='dd.mm.rrrr'||validTo=='dd.mm.rrrr') isValid = false;

    if(!isValid){
      setCouponAddError("Fyll inn dataene som skal legges til korrekt");
      return setTimeout(function(){
        setCouponAddError('');
      }, 3000);
    }
      let request = {
      activityId: activityId.id,
      code: couponCode,
      discount: discount,
      limit: parseInt(limit),
      validFrom: validFrom,
      validTo: validTo
    }
    if(!isEditing){
    const res = FetchService.AddNewCoupon(request);
    res.then((data)=>{
      request.activity_Id = request.activityId;
      delete request.activityId;
      request.valid_From = formatDate(new Date(request.validFrom));
      delete request.validFrom;
      request.valid_To = formatDate(new Date(request.validTo));
      delete request.validTo;
      request.totalHitUsed = 0;
      request.activityName = activityId.name;
      request.couponId = data.data;
      const updatedCoupons = [...allCoupons, request]
      setAllCoupons(updatedCoupons);
      setCouponAddError("Kupong lagt til");
      return setTimeout(function(){
        setCouponAddError('');
      }, 3000);
    })
    }else{
    request.couponId = couponId;
    const res = FetchService.EditCoupon(request);
    res.then((data)=>{
      const updatedCoupons = [...allCoupons, request]
      setCouponAddError("Suksessfull redigering");
       setTimeout(function(){
        setCouponAddError('');
      }, 3000);
      setAllCoupons(prevCoupons => prevCoupons.map(coupon => {
        if (coupon.couponId === couponId) {
          return { ...coupon, ...{
            activity_Id: activityId.id,
            activityName: activityId.name,
            valid_From: formatDate(new Date(request.validFrom)),
            valid_To: formatDate(new Date(request.validTo)),
            discount: discount,
            limit: limit
          } };
        }
        return coupon;
      }));
    })
  }
  }

  function handleEdit(coupon){
    setCouponId(coupon.couponId)
    setIsEditing(true);
    setCouponCode(coupon.code);
    setDiscount(coupon.discount);
    setLimit(coupon.limit);
    const validF = new Date(coupon.valid_From)
    const formattedDateFrom = validF.toISOString().slice(0, 10);
    setValidFrom(formattedDateFrom);
    const validT = new Date(coupon.valid_To)
    const formattedDateTo = validT.toISOString().slice(0, 10);
    setValidTo(formattedDateTo);
    setActivityId({id:coupon.activity_Id, name:coupon.activityName})
  }

  function handleDelete(couponId){
    const res = FetchService.DeleteCoupon(couponId);
    res.then(()=>{
      const updatedCoupons = allCoupons.filter((coupon)=>coupon.couponId!=couponId);
      setAllCoupons(updatedCoupons);
    })
  }

  function returnToEdit(){
    setCouponId(0)
    setIsEditing(false);
    setCouponCode("");
    setDiscount(0);
    setLimit(-1);
    setValidFrom(formatDate(new Date()));
    setValidTo(formatDate(new Date()));
    setActivityId({id:allValidActivities[0].id, name:allValidActivities[0].name})
  }

  function getCouponTable(){
    let couponsLeft = isActive
    ? allCoupons.filter(
        (coupon) =>
          (coupon.limit == -1 && currentDate >= coupon.valid_From &&
          currentDate <= coupon.valid_To ||
            (coupon.totalHitUsed < coupon.limit &&
              currentDate >= coupon.valid_From) &&
              currentDate <= coupon.valid_To))
    : allCoupons.filter(
        (coupon) =>
          (coupon.totalHitUsed >= coupon.limit &&
            (currentDate < coupon.valid_From ||
              currentDate > coupon.valid_To))
      );
      if(search.length>0){
        couponsLeft = couponsLeft.filter((coupon) =>(coupon.code.toLowerCase().includes(search.toLowerCase()) || coupon.activityName.toLowerCase().includes(search.toLowerCase())))
      }
    return couponsLeft.map((coupon, index) => (
      <tr className="partner_row">
        <td style={{position: "relative"}}>
          <span className="couponNumber">{index+1}</span>
          <p className="heading-xs body_text">{coupon.activityName}</p>
        </td>
        <td>
          <p className="heading-xs body_text" style={{textAlign:"left"}}>{coupon.code}</p>
        </td>
        <td>
          <p className="heading-xs body_text">{coupon.discount}</p>
        </td>
        <td>
          <p className="heading-xs body_text">{coupon.limit==-1? "Ingen grense" :  coupon.limit}</p>
        </td>
        <td>
          <p className="heading-xsb body_text">{coupon.totalHitUsed}</p>
        </td>
        <td>
          <p className="heading-xs body_text">{coupon.valid_From.split(' ')[0]}</p>
        </td>
        <td>
          <p className="heading-xs body_text">{coupon.valid_To.split(' ')[0]}</p>
        </td>
        <td>
          <p className="heading-xs body_text">{coupon.worth}</p>
        </td>
        <td>
          <div className="action_otr">
            <ThemeBtn
              BtnClass="Theme_btn_primary partner-page-button edit"
              BtnText="Rediger"
              onClick={() => {handleEdit(coupon)}}
            />
            <ThemeBtn
              BtnClass="Theme_btn_primary partner-page-button delete"
              BtnText="Slett"
              onClick={() => {handleDelete(coupon.couponId)}}
            />
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <div className="search_edit_main">
      <div className="couponsHeader">
        <div className="topFloor">
          <h1>Kuponger</h1>
          <div className="filter_search_otr">
            <div className="search_otr">
              <Input
                InputClass="Theme_input_white search_input"
                Inputype="search"
                InputName="search"
                InputPlaceholder="SØK"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="radio-group">
        <label className="radio" onClick={() => setIsActive(true)}>
          <input
            type="radio"
            name="filter"
            defaultChecked={isActive}
          />
          {"Aktive kuponger"}
        </label>
        <label className="radio" onClick={() => setIsActive(false)}>
          <input
            type="radio"
            name="filter"
            defaultChecked={!isActive}
          />
          {"Utgåtte kuponger"}
        </label>
      </div>
      </div>
      <div className="couponsContent">
        <div className="leftCouponsContent">
          <div className="partner_table_inr">
          <table className="data_table" style={{width: "100%"}}>
            <thead className="table_header">
              <tr>
                <th>
                  <p className="heading-xsb header_text">Activity Name</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Code</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Rabat(%)</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Begrensning</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Antall bruksområder</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Aktiv fra</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Aktiv til</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Verdt</p>
                </th>
                <th>
                  <p className="heading-xsb header_text">Handling</p>
                </th>
              </tr>
            </thead>
            <tbody className="table_body">
              {getCouponTable()}
            </tbody>
          </table>
        </div>
        </div>
        <div className="rightCouponsContent">
          <div className="addCouponForm">
            <h5 style={{textAlign:"center"}}>{!isEditing? "Legg til en ny kupong" : "Redigere kupong"}</h5>
            <div className="Input_otr">
            <label class="label_input heading-xs">Aktivitet</label>
              <Form.Select
                className="Theme_input_white form_input"
                aria-label="Default select example"
                value={activityId.id}
                onChange={(event) => {
                  const activityChange = allValidActivities.find((activitiy)=>activitiy.id==event.target.value);
                  setActivityId(activityChange);
                }}
              >
                {allValidActivities.map((activity, index) => (
                  <option key={index} value={activity.id}>
                    {activity.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="Input_otr">
              <Input
                InputClass="Theme_input_white form_input"
                Inputype="text"
                InputName="code"
                label="Kode"
                value={couponCode}
                onChange={(event) => {
                  setCouponCode(event.target.value);
                }}
              />
            </div>
            <div className="Input_otr">
                <div className="input-container">
                      <label className="label_input heading-xs">Rabatt (%)</label>
                      <input
                        className={'Theme_input_white form_input'}
                        type="text"
                        placeholder={'Rabatt'}
                        value={discount}
                        onChange={(event) => {
                          const enteredValue = event.target.value;
                          return enteredValue!=''? !isNaN(enteredValue)? setDiscount(parseFloat(enteredValue)) : "" : setDiscount(0)
                        }}
                      />
                    </div>
            </div>
            <div className="Input_otr">
              <Input
                InputClass="Theme_input_white form_input"
                Inputype="text"
                InputName="limit"
                label="Begrensning (-1 betyr ubegrenset)"
                value={limit}
                onChange={(event) => setLimit(event.target.value)}
              />
            </div>
            <div className="Input_otr col">
              <Input
                InputClass="Theme_input_white form_input"
                Inputype="date"
                InputName="valid_from"
                label="Gyldig fra"
                value={validFrom}
                onChange={(event) =>
                  setValidFrom(event.target.value)
                }
              />
            </div>
            <div className="Input_otr col">
              <Input
                InputClass="Theme_input_white form_input"
                Inputype="date"
                InputName="valid_to"
                label="Gyldig til"
                value={validTo}
                onChange={(event) =>
                  setValidTo(event.target.value)
                }
              />
            </div>
            <div className="action_otr">
              <ThemeBtn
                style={{width:"100%",marginTop:"20px"}}
                BtnClass="Theme_btn_primary partner-page-button edit w-100 my-3"
                BtnText={!isEditing? "Legg til en ny kupong" : "Redigere kupong"}
                onClick={addCoupon}
              />
            </div>
            {couponAddError.length>0? couponAddError : ""}
            {isEditing?             <div className="action_otr">
              <ThemeBtn
                style={{width:"100%",marginTop:"20px"}}
                BtnClass="Theme_btn_primary partner-page-button edit w-100 my-3"
                BtnText="Legg til en ny kupong"
                onClick={returnToEdit}
              />
            </div> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponsController;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InnerHeader from "../../components/InnerHeader/InnerHeader";
import Input from "../../components/Input/Input";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
import { register } from "../../api/LoginService";
import FetchService from "../../api/FetchService";
import LoadingOverlay from "react-loading-overlay";
import { ImageUploader } from "../../utility-components/ImageUploader/ImageUploader";
import {getAllActivityCategories, getFilters} from "../../commons/activity-filters/Helpers";
import { useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Form from "react-bootstrap/Form";
import "./EditActivity.scss";
import AttachActivity from "../AttachActivity/AttachActivity";
import DialogueBox from "../../components/DialogueBox/DialogueBox";
import { API } from "../../api/API-Url";
import deleteImg from "../../img/remove.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const priceModelArray = [
  { label: "Per person", value: "person" },
{ label: "Per aktivitet", value: "activity" },
{ label: "Per time", value: "time" },

];

let activityRequest = {
  id: 0,
  name: "",
  activityType: "",
  city: "",
  price: 0,
  childoriginalPrice: 0,
  childprice: 0,
originalPrice: 0,
  validPeriodEnd: "",
  validPeriodStart: "",
  liveDate: "",
  terms: "",
  description: "",
  imagePath: "",
  instagram: null,
  facebook: null,
  youtube: null,
  priceModel: "person",
  minPerson: 0,
  maxPerson: 0,
  discountPercent: 0,
  addDate: new Date().toISOString().slice(0, 10),
};

function EditActivity({ setIsLoading }) {
  const location = useLocation();
  const activity = location.state;



    const [giftCardFavPriority, setGiftCardFavPriority] = useState(activity.giftCardFavPriority);
    const [categoryFavPriority, setCategoryFavPriority] = useState(activity.categoryFavPriority);


  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showImagePreview, setShowImagePreview] = useState(false);
  const [name, setName] = useState(activity.name);
    const [type, setType] = useState(activity.activityType);
    let acity;
    if (activity.city === null) {
        acity = [];
    } else {
        acity = activity.city.split(", ");
    }
    const [city, setCity] = useState(acity);

  const [originalPrice, setOriginalPrice] = useState(activity.originalPrice);
  const [price, setPrice] = useState(activity.price);


    const [childoriginalPrice, setchildOriginalPrice] = useState(activity.childOriginalPrice);
    const [childprice, setChildPrice] = useState(activity.childPrice);


  const [priceModel, setPriceModel] = useState(activity.priceModel);

    const [images, setImages] = useState(JSON.parse(activity.images));
    const addImage = (newImage) => {
        setImages((prevImages) => [...prevImages, newImage]);
    };
  const [validPeriodStart, setValidPeriodStart] = useState(
    activity.validPeriodStart?.slice(0, 10)
  );
  const [validPeriodEnd, setValidPeriodEnd] = useState(
    activity.validPeriodEnd?.slice(0, 10)
  );

  const [liveDate, setLiveDate] = useState(activity.liveDate?.slice(0, 10));

  const [instagram, setInstagram] = useState(activity.instagram);
  const [facebook, setFacebook] = useState(activity.facebook);
  const [youtube, setYoutube] = useState(activity.youtube);

  const [terms, setTerms] = useState("");
  const [description, setDescription] = useState(activity.description);

  const [minPerson, setMinPerson] = useState(activity.minPerson);
  const [maxPerson, setMaxPerson] = useState(activity.maxPerson);
  const [category, setCategory] = useState(activity.category);
  let cate;
  if(activity.category===null){
    cate = [];
  }else{
    cate = activity.category.split(", ");
  }
  const [categories, addCategory] = useState(cate);
  const [showPopup, setShowPopup] = useState(false);
  const [partnerId, setPartnerId] = useState(activity?.ownerID);
  const [partnerName, setPartnerName] = useState(activity?.partnerName);
  const modules = {
    toolbar: [
      [{ 'font': ['monospace'] }],
      [{ 'align': [false,'center','right'] }],
      [{ 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],[{ 'size': ['small', false, 'large', 'huge'] }]
    ],
  };
  const formats = [
    'font',
    'align',
    'list',
    'bold', 'italic', 'underline', 'strike',
    'size',
  ];

    const [response, setResponse] = useState("");
    const [childPriceOption, setChildPriceOption] = useState(activity.childStripePaymentID ? 'allowed'  : 'notAllowed');

  const handleChildPriceChange = (event) => {
      setChildPriceOption(event.target.value);
  };

    const filters = getFilters("add");


    async function convertImageToBase64(imageUrl) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


  const handleSubmit = async (e) => {
      e.preventDefault();
    if (images === null || images.length == 0) {
      alert("Error: Images cannot be empty");
    } else {
      setIsLoading(true);
      activityRequest.id = activity.id;
      activityRequest.ownerID = activity.ownerID;
      activityRequest.stripePaymentID = activity.stripePaymentID;
      activityRequest.stripeProductID = activity.stripeProductID;
        activityRequest.name = name;

        let cityE = "";
        city.forEach((categoryE, index) => {
            if (index !== 0) {
                cityE += ", " + categoryE;
            } else {
                cityE += categoryE;
            }
        })

        activityRequest.city = cityE;

      activityRequest.priceModel = priceModel;

      activityRequest.price = +price;
      activityRequest.originalPrice = +originalPrice;

        if (childPriceOption == "notAllowed") {
            activityRequest.childprice = 0;
            activityRequest.childoriginalPrice = 0;
            activityRequest.childStripePaymentID = activity.childstripePaymentID;
            activityRequest.childStripeProductID = activity.childStripeProductID;
        }
        else {
            activityRequest.childprice = +childprice;
            activityRequest.childoriginalPrice = +childoriginalPrice;
            activityRequest.childStripePaymentID = activity.childstripePaymentID;
            activityRequest.childStripeProductID = activity.childStripeProductID;

        }


      activityRequest.validPeriodEnd = validPeriodEnd;
      activityRequest.validPeriodStart = validPeriodStart;

      activityRequest.liveDate = liveDate;

      activityRequest.terms = terms;

      activityRequest.description = description;
      activityRequest.minPerson = +minPerson;
      activityRequest.maxPerson = +maxPerson;
      categories.forEach((categoryE,index)=>{
        if(index!==0){
          activityRequest.category += ", " + categoryE;
        }else{
          activityRequest.category = categoryE;
        }
      })
      activityRequest.activityType = type;
      activityRequest.discountPercent =
        100 - (price / originalPrice) * 100 || 0;

        activityRequest.ownerID = partnerId;



    activityRequest.images = images
        ? await Promise.all(images.map(async (x) => {
            if (x.data_url) {
                return x.data_url;
            } else if (x.imageURL) {
                // Convert imageURL to Base64
                return await convertImageToBase64(
                    x.imageURL.replace(
                        "{baseApiURL}",
                        API.apiURLs)
                );
            }
            return null; // Handle cases where neither data_url nor imageURL exists
        })) : [];

        activityRequest.ImagePath = JSON.parse(activity.images)?.map((x) => x.imageURL?.replace(
            "{baseApiURL}","")).filter(obj=>obj);


      activityRequest.instagram = instagram ?? null;
      activityRequest.facebook = facebook ?? null;
      activityRequest.youtube = youtube ?? null;
      FetchService.UpdateActivity(activityRequest).then((response) => {
        if (response) {
            setSession(null);

          //let session = getSession();
          //if(session)
          //{

          //  let obj = session.activity.filter(x=>x.id==activityRequest.id)[0];
          //  obj.name=name;
          //  obj.images=JSON.stringify(activityRequest.images.map(x=>{return {imageURL:x}})) ;
          //  obj.price=activityRequest.price;
          //  obj.priceModel=obj.priceModel;
          //  setSession(session);
          //}

          setResponse("Success");
          clearForm();
          window.scrollTo(0, 0);
          setIsLoading(false);
        } else {
          setResponse("Failed");
          window.scrollTo(0, 0);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(()=>{
    if(activity?.terms!==null){
      const terms = String(activity.terms);
      setTerms(activity.terms)
    }
  },[activity.terms])

  function clearForm() {
    setName("");
    setPrice(0);
    setOriginalPrice(0);
    setMinPerson(0);
    setMaxPerson(0);
    setValidPeriodEnd("");
    setValidPeriodStart("");
    setDescription("");
    setTerms("");
    setLiveDate("");
    setFacebook("");
    setInstagram("");
    setYoutube("");

    setImages([]);
  }

  const DeleteActivity = () => {
    setIsLoading(true);

    FetchService.DeleteActivity(activity).then((response) => {
      if (response) {
        setSession(null);
        setTimeout(() => {
          setResponse("Success");
          clearForm();

          setIsLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setResponse("Failed");

          setIsLoading(false);
        }, 1000);
      }
    });
  };



  function setSession(obj) {
    sessionStorage.setItem("SearchResultAdmin", JSON.stringify(obj));
  }

  const handleTerms = (props) =>{
    setTerms(props)
  }

  const handleDesc = (props) =>{
    setDescription(props)
  }

  function getCategoryName(categoryVal){
    const categoriesLabels = getAllActivityCategories();
    const nameCategory = categoriesLabels.filter(categoryLabel => categoryLabel.value == categoryVal);
    return nameCategory[0].label;
  }

  const deleteCategory = (props) =>{
    const delCategory = props.target.dataset.category
    if(categories.length<=1){
      return 0;
    }
    return addCategory(categories.filter(categoryF => categoryF !== delCategory))
  }

  function getSession() {
    return JSON.parse(sessionStorage.getItem("SearchResultAdmin"));
  }

  return (
    <>
      <div className="Register_page">
        {/* Todo: might need to change this in future for better design
        <InnerHeader HeaderHeading="Activity" PageText="Add Activity" />
     */}

        <section>
          <div className="container">
            {showConfirmDelete && (
              <DialogueBox
                title={"Bekreft sletting"}
                onConfirm={DeleteActivity}
                onConfirmState={null}
                confirmText={"Slette"}
                cancelText={"Avbryt"}
                open={showConfirmDelete}
                setOpen={setShowConfirmDelete}
                stayOnPage={true}
              >
                Er du sikker på at du vil slette denne aktiviteten?
              </DialogueBox>
            )}
            <div className="row row_custom">
              <div className="col_form_otr">
                <div className="col_form_inr">
                  <h3 className="heading-h3 form_heading">
                    Oppdater aktivitet
                  </h3>
                  {response === "Failed" && (
                    <div className="error_message">
                      {
                        "Feil: Systemet kunne ikke lagre aktiviteten. Vær så snill, prøv på nytt."
                      }
                    </div>
                  )}
                  {response === "Success" && (
                    <div className="success_message">
                      {"Aktiviteten er oppdatert."}
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="row">
                    <div className="col">
                      <div className="Input_otr">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="text"
                          InputName="name"
                          label="Aktivitetsnavn"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </div>
                      <div className="Input_otr">
                        {"Kategori"}
                        <Form.Select
                          className="Theme_input_white form_input"
                          aria-label="Default select example"
                          value={category}
                          onChange={(event) => {
                            if(!categories.includes(event.target.value)) {
                              addCategory(categories.concat(event.target.value));
                              setCategory(event.target.value);
                            }
                          }}
                        >
                          {filters.categories.map((option) => (
                            <option value={option.value}>
                                  {categories.includes(option.value) ? "✔" : (<p>&nbsp;&nbsp;&nbsp;</p>)} {option.label.replace("Julebord", "Firmafest")}
                            </option>
                          ))}
                        </Form.Select>
                        {categories.map((categoryOne)=>(
                            <div className="my-1">
                              <img src={deleteImg} alt="delete" onClick={deleteCategory} key={categoryOne} data-category={categoryOne} className="delete_category_button"/>{getCategoryName(categoryOne)}
                            </div>
                        ))}
                      </div>
                                          <div className="Input_otr">
                                              {"By"}
                                              <Form.Select
                                                  className="Theme_input_white form_input"
                                                  aria-label="Default select example"
                                                  value={city[city.length - 1]}
                                                  onChange={(event) => {
                                                      if (!city.includes(event.target.value)) {
                                                          setCity([...city, event.target.value]);
                                                      }


                                                  }}
                                              >
                                                  {filters.cities.map((option) => (
                                                      <option value={option.value}>
                                                          {city.includes(option.value) ? "✔" : (<p>&nbsp;&nbsp;&nbsp;</p>)}  {option.label}
                                                      </option>
                                                  ))}
                                              </Form.Select>
                                              {city.map(categoryOne => (
                                                  <div className="my-1">
                                                      <img src={deleteImg} alt="delete" onClick={(event) => {
                                                          setCity(city.filter(cityf => cityf !== categoryOne));

                                                      }} key={categoryOne} data-category={categoryOne} className="delete_category_button" />{categoryOne}
                                                  </div>
                                              ))}
                                          </div>
                      <div className="Input_otr">
                        {"Aktivitetstype"}
                        <Form.Select
                          className="Theme_input_white form_input"
                          aria-label="Default select example"
                          value={type}
                          onChange={(event) => {
                            setType(event.target.value);
                          }}
                        >
                          {filters.types.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </Form.Select>
                                          </div>
                                          <div>
                                              <h3>Alternativ for barnas pris:</h3>
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name="childPrice"
                                                      value="notAllowed"
                                                      checked={childPriceOption === 'notAllowed'}
                                                      onChange={handleChildPriceChange}
                                                  />
                                                  Barnas pris ikke tillatt
                                              </label>
                                              <br />
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name="childPrice"
                                                      value="allowed"
                                                      checked={childPriceOption === 'allowed'}
                                                      onChange={handleChildPriceChange}
                                                  />
                                                  Barnas pris tillatt
                                              </label>
                                             
                                            

                                          </div>
                                          <br/>
                    <div className="row">
                     <h5>Antall voksen:</h5>
                    </div>
                     <div className="row">
                        <div className="Input_otr col">
                          <Input
                            InputClass="Theme_input_white form_input"
                            Inputype="number"
                            InputName="number"
                            label="Pris"
                            value={price}
                            onChange={(event) => setPrice(event.target.value)}
                          />
                        </div>
                        <div className="Input_otr col">
                          <Input
                            InputClass="Theme_input_white form_input"
                            Inputype="number"
                            InputName="number"
                            label="Orginalpris"
                            value={originalPrice}
                            onChange={(event) =>
                              setOriginalPrice(event.target.value)
                            }
                          />
                                              </div>
                                          </div>
                                          

                                          { childPriceOption === 'allowed' && (
                                              <>
                                          <div className="row">
                                              <h5>Antall barn:</h5>
                                          </div>
                                          <div className="row">
                                              <div className="Input_otr col">
                                                  <Input
                                                      InputClass="Theme_input_white form_input"
                                                      Inputype="number"
                                                      InputName="number"
                                                      label="Pris"
                                                      value={childprice}
                                                      onChange={(event) => setChildPrice(event.target.value)}
                                                  />
                                              </div>
                                              <div className="Input_otr col">
                                                  <Input
                                                      InputClass="Theme_input_white form_input"
                                                      Inputype="number"
                                                      InputName="number"
                                                      label="Orginalpris"
                                                      value={childoriginalPrice}
                                                      onChange={(event) =>
                                                          setchildOriginalPrice(event.target.value)
                                                      }
                                                  />
                                              </div>
                                          </div>
                                              </>
                                          )}

                      <div className="Input_otr ">
                        {"Pristype"}
                        <Form.Select
                          className="Theme_input_white form_input"
                          aria-label="Default select example"
                          value={priceModel}
                          onChange={(event) => {
                            setPriceModel(event.target.value);
                          }}
                        >
                          {priceModelArray.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>

                    <div className="col-lg-6 col_img_otr">
                      <div className="col_img_inr">
                        <div className="image-uploader">
                          {!showImagePreview && (
                            <Swiper
                              slidesPerView={1}
                              spaceBetween={24}
                              effect={"fade"}
                              pagination={{
                                clickable: true,
                              }}
                              autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              modules={[EffectFade, Autoplay, Pagination]}
                              className="mySwiper hero_swiper campaign_swiper"
                            >
                              {images?.map((image) => (
                                <SwiperSlide>
                                  <div className="img_otr">
                                    <img
                                      className="campaign_img"
                                      height="50"
                                      width="70"
                                      src={image.imageURL.replace(
                                        "{baseApiURL}",
                                        API.apiURLs
                                      )}
                                      alt="img"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          )}
                          <ImageUploader
                            showImagePreview={showImagePreview}
                            setShowImagePreview={setShowImagePreview}
                            setImagesCallBack={setImages}
                            editMode={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="number"
                          InputName="number"
                          label="Minimumspersoner"
                          value={minPerson}
                          onChange={(event) => setMinPerson(event.target.value)}
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="number"
                          InputName="number"
                          label="Maksimumspersoner"
                          value={maxPerson}
                          onChange={(event) => setMaxPerson(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Gyldig fra"
                          value={validPeriodStart}
                          onChange={(event) =>
                            setValidPeriodStart(event.target.value)
                          }
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Gyldig Til"
                          value={validPeriodEnd}
                          onChange={(event) =>
                            setValidPeriodEnd(event.target.value)
                          }
                        />
                      </div>
                      <div className="Input_otr col">
                        <Input
                          InputClass="Theme_input_white form_input"
                          Inputype="date"
                          InputName="number"
                          label="Live fra"
                          value={liveDate}
                          onChange={(event) => setLiveDate(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="facebook"
                        label="Facebook (Valgfri)"
                        value={facebook}
                        onChange={(event) => setFacebook(event.target.value)}
                      />
                    </div>

                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="youtube"
                        label="Instagram (Valgfri)"
                        value={instagram}
                        onChange={(event) => setInstagram(event.target.value)}
                      />
                    </div>
                    <div className="Input_otr">
                      <Input
                        optional={true}
                        InputClass="Theme_input_white form_input"
                        Inputype="text"
                        InputName="youtube"
                        label="Youtube (Valgfri)"
                        value={youtube}
                        onChange={(event) => setYoutube(event.target.value)}
                      />
                    </div>

                    <div className="Input_otr">
                      <label className="label_input heading-xs">
                        Beskrivelse
                      </label>

                      {/* <textarea
                        className="Theme_input_white form_input description"
                        type="text"
                        name="description"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      /> */}
                        <ReactQuill
                          className="Theme_input_white form_input description"
                          modules={modules}
                          formats={formats}
                          onChange={handleDesc}
                          name="description"
                          value={description}
                        />

                    </div>
                    <div className="Input_otr">
                      <label className="label_input heading-xs">Vilkår</label>

                      {/* <textarea
                        className="Theme_input_white form_input description"
                        type="text"
                        name="terms"
                        value={terms}
                        onChange={(event) => setTerms(event.target.value)}
                      /> */}

                      <ReactQuill
                          className="Theme_input_white form_input description"
                          modules={modules}
                          formats={formats}
                          onChange={handleTerms}
                          name="terms"
                          value={terms}
                        />
                    </div>

                    <div className="change-partner">
                      {/* Add activity button if the partner is not attached. */}
                      {partnerId !== 0 && (
                        <div className="Input_otr">
                          <p>
                            <b>Partner Id: </b> {partnerId}
                          </p>
                          <br />
                          <p>
                            <b>Partnernavn: </b> {partnerName}
                          </p>
                        </div>
                      )}
                      <button
                        type="button"
                        className="Theme_btn_primary h-50 bg-danger mt-2"
                        onClick={() => {
                          setShowPopup(true);
                        }}
                      >
                        Bytte partner
                      </button>
                    </div>

                    <div className="m-2">
                      <button
                        type="submit"
                        className="Theme_btn_primary form_btn"
                      >
                        Oppdater aktivitet
                      </button>
                    </div>
                    <div className="m-2">
                      <button
                        type="button"
                        onClick={() => setShowConfirmDelete(true)}
                        className="Theme_btn_primary form_btn bg-danger"
                      >
                        Slette aktivitet
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {showPopup && (
          <div className="backdrop">
            <div className="backdrop-container">
              <AttachActivity
                setShowPopup={setShowPopup}
                setPartnerID={setPartnerId}
                setPartnerName={setPartnerName}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default EditActivity;

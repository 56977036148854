import React, { useEffect, useState, useMemo, useRef } from "react";
import "./OrderPlan.css";
import Select from "../Select/Select";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import LoadingOverlay from "react-loading-overlay";


import {
    getFilters,
    getMinMax,
    getBudget,
    getNumberOfPeopleOptions,
    getFilteredActivities,
    getLabelByValue,
    getValidActivities,
    getDescriptionByValue
} from "../../commons/activity-filters/Helpers";
import FetchService from "../../api/FetchService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import OrderPlanSearchResultCard from "../SearchResultCard/OrderPlanSearchResultCard ";
import CalenderComponent from "../CalenderComponent/CalenderComponent";

function OrderPlan2({ searchQuery, setSearchQuery }) {


    const { action } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [circleData, setCircleData] = useState([]);
    const [queryData, setQueryData] = useState([]);
    const [circleData2, setCircleData2] = useState([]);
    const getCircles = async (status, circleType) => {
        setCircleData([])
        setCircleData2([])
        setIsLoading(true)
        FetchService.getAllCircless(status, circleType).then((val) => {
            setCircleData([])
            setCircleData2([])
            if (action && action === "singlestatus") {
                setCircleData2(val.data.filter((val) => val.circleType === "single" && val.circleStatus !== "saved"))
            }
            if (action && action === "multistatus") {
                setCircleData(val.data.filter((val) => val.circleType === "multiple" && val.circleStatus !== "saved"))
            }
            if (action && action === "allstatus") {
                setCircleData(val.data.filter((val) => val.circleType === "multiple" && val.circleStatus !== "saved"))
                setCircleData2(val.data.filter((val) => val.circleType === "single" && val.circleStatus !== "saved"))
                }
            setIsLoading(false)
        }).catch((e) => {
            setIsLoading(false)
            console.log(e)
        })
    }
    const getCircleById = async (id, circleType) => {
        FetchService.GetCircleById('pending', circleType, "id", id).then((val) => {
            handleCircleUpdate(id, val.data, circleType)
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleCircleUpdate = async (circId, widget, circleType) => {
        var CircleDetails = []

        widget.map((val) => {
            const { id } = val;
            var circleObj = {
                circleId: val.circleId,
                ActivityId: id
            }
            CircleDetails.push(circleObj)
        })

        var Circle = {
            Id: circId,
            circleName: widget[0]['circleName'],
            circleStatus: 'approved',
            circleType: circleType
        }
        var obj = {

            Circle: Circle,
            circleDetails: CircleDetails
        }
        FetchService.UpdateCircle(obj).then((val) => {
            if (action && action === "singlestatus") {
                getCircles("", "single")
            }
            if (action && action === "multistatus") {
                getCircles("", "multiple")
            }
            if (action && action === "allstatus") {
                getCircles("", "")
            }
        }).catch((e) => console.log(e))
    }

    useEffect(() => {
        getCircles("", "")
    }, [action])
    

    var circlesArr = []
    var circlesArr2 = []
    var circleIdArr = 0
    var count = 0
    useEffect(() => {
        var dataa = circleData.filter((val) => val.circleName.toLowerCase().includes(searchQuery.toLowerCase()))
        setQueryData(dataa)
    }, [searchQuery])
    useEffect(() => {
        if (searchQuery === "") {
        setQueryData([])
        }
        console.log(queryData)
        console.log(circleData)
    }, [searchQuery])

    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text="Behandler forespørselen din"
        >

            {action && action === "singlestatus" && <><h1 className="text-center admin_heading fw-bold">SE ENKELT BESTILLINGER</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">HER FINNER DERE EN OVERSIKT OVER ALLE ENKELT BESTILLINGER SOM ER SENDT INN TIL OSS. <br /> MAN SER STATUS PÅ BESTILLINGEN, OG INNHOLDET AV DEN. <br />ALLE FORESPØRSLER VIL BLI BESVART SENEST 5 VIRKEDAGER ETTER INNSENDING. <br />DERSOM DERE HAR NOEN SPØRSMÅL IMELLOMTIDEN, HØRER VI GJERNE FRA DERE PÅ SUPPORT@FUNTLERS.COM.
                    </p>
                </div></>}
            {action && action === "multistatus" && <><h1 className="text-center admin_heading fw-bold">SE ÅRSHJUL BESTILLINGER</h1>
                <div className="d-flex justify-content-center" >
                    <p className="text-center my-4">HER FINNER DERE EN OVERSIKT OVER ALLE SOSIALE ÅRSHJUL SOM ER SENDT INN TIL OSS. <br />MAN SER STATUS PÅ BESTILLINGEN, OG INNHOLDET AV DEN. <br />ALLE FORESPØRSLER VIL BLI BESVART SENEST 5 VIRKEDAGER ETTER INNSENDING. <br />DERSOM DERE HAR NOEN SPØRSMÅL IMELLOMTIDEN, HØRER VI GJERNE FRA DERE PÅ SUPPORT@FUNTLERS.COM.
                    </p>
                </div></>}

            <div className='row  d-flex align-items-end' >
                {action && (action === 'singlestatus' || action === 'allstatus') && circleData2.length > 0 && circleData2.reverse().filter((val) => val.circleName.toLowerCase().includes(searchQuery.toLowerCase())).map((val) =>
                //Circle
                (
                    <div key={val.circleId } className='col-lg-6'>
                        <div className='d-flex align-items-center' >
                            <div id="pdfDivMain" className='w-100 '>
                                <div className="nameDiv">
                                    <h4>{val.circleName}</h4>
                                </div>
                                <div id="pdfContent" className="containerCircle"  >
                                    <div className="iconCircle">
                                        <div className="imgBx" style={{
                                            width: "210px",
                                            height: "210px",
                                        }} >
                                            <img
                                            src={val.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row w-100 my-3 d-flex justify-content-center">
                            {val.circleStatus === 'pending' && <>
                                <div className="col-5">
                                    <button className="btnOrder w-100">VENTER PÅ GODKJENNING</button>

                                </div>
                                {action && action === "singlestatus" && <div className="col-5">
                                    <button className="btnOrder w-100" onClick={() => getCircleById(val.circleId, 'single')} >GODKJENT </button>

                                </div>}
                            </>}
                            {val.circleStatus === 'approved' && <div className="col-6">
                                <button className="btnOrder w-100" >GODKJENT </button>

                            </div>}
                        </div>

                    </div>
                ))
                }
                {/*Multiple Circle for Status */}
                {searchQuery === ""&& action && (action === 'multistatus' || action === 'allstatus') && circleData.length > 0 && circleData.reverse().filter((val) => val.circleType === "multiple").map((val, i) =>
                //Circle
                {
                    count++
                    if (circleIdArr === 0) {
                        circlesArr = []
                        circlesArr = circlesArr2;
                        circlesArr2 = []
                        circleIdArr = val.circleId
                    }
                    if (circleIdArr === val.circleId) {
                        circlesArr.push(val)
                        //circlesIds.push(val.circleIds)
                        var length = circleData.length
                        if (count === length) {
                            return (
                                <div className='col-lg-6' key={val.circleId}>
                                    <div className='d-flex align-items-center' >
                                        <div id="pdfDivMain" className='w-100 '>
                                            <div className="nameDiv">
                                                <h4>{circleData[i - 1]['circleName']}</h4>
                                            </div>


                                            <div id="pdfContent" className="containerCircle my-5"  >
                                                <div className="iconCircle">
                                                    {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                        width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                        height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                        transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                        transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                    }}>
                                                        <img style={{
                                                            transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                        }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row w-100 my-3 d-flex justify-content-center">
                                        {circleData[i - 1]['circleStatus'] === 'pending' && <>
                                            <div className="col-5">
                                                <button className="btnOrder w-100" >VENTER PÅ GODKJENNING</button>

                                            </div>
                                            {action && action === "multistatus" && <div className="col-5">
                                                <button className="btnOrder w-100" onClick={() => getCircleById(circleData[i - 1]['circleId'], 'multiple')} >GODKJENT </button>
                                            </div>}
                                        </>}
                                        {circleData[i - 1]['circleStatus'] === 'approved' && <div className="col-6">
                                            <button className="btnOrder w-100" >GODKJENT </button>

                                        </div>}
                                    </div>

                                </div>
                            )

                        }
                    }
                    else {
                        circlesArr2.push(val)
                        circleIdArr = 0
                        return (<div className='col-lg-6' key={val.circleId}>

                            <div className='d-flex align-items-center' >
                                <div id="pdfDivMain" className='w-100 '>
                                    <div className="nameDiv">
                                        <h4>{circleData[i - 1]['circleName']}</h4>
                                    </div>
                                    <div id="pdfContent" className="containerCircle my-5"  >
                                        <div className="iconCircle">
                                            {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                            }}>
                                                <img style={{
                                                    transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row w-100 my-3 d-flex justify-content-center">
                                {circleData[i - 1]['circleStatus'] === 'pending' && <>
                                    <div className="col-5">
                                        <button className="btnOrder w-100" >VENTER PÅ GODKJENNING</button>

                                    </div>
                                    {action && action === "multistatus" && <div className="col-5">
                                        <button className="btnOrder w-100" onClick={() => getCircleById(circleData[i - 1]['circleId'], 'multiple')} >GODKJENT </button>

                                    </div>}
                                </>}
                                {circleData[i - 1]['circleStatus'] === 'approved' && <div className="col-6">
                                    <button className="btnOrder w-100" >GODKJENT </button>

                                </div>}
                            </div>


                        </div>
                        )
                    }

                })
                }

                {/*Search Circle for Status */}
                {searchQuery !== "" && action && (action === 'multistatus' || action === 'allstatus') && queryData.reverse().filter((val) => val.circleType === "multiple").map((val, i) =>
                //Circle
                {
                    count++
                    if (circleIdArr === 0) {
                        circlesArr = []
                        circlesArr = circlesArr2;
                        circlesArr2 = []
                        circleIdArr = val.circleId
                    }
                    if (circleIdArr === val.circleId) {
                        circlesArr.push(val)
                        var length = queryData.length
                        if (count === length) {
                            return (
                                <div className='col-lg-6' key={val.circleId}>
                                    <div className='d-flex align-items-center' >
                                        <div id="pdfDivMain" className='w-100 '>
                                            <div className="nameDiv">
                                                <h4>{queryData[i - 1]['circleName']}</h4>
                                            </div>


                                            <div id="pdfContent" className="containerCircle my-5"  >
                                                <div className="iconCircle">
                                                    {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                        width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                        height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                        transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                        transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                    }}>
                                                        <img style={{
                                                            transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                        }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row w-100 my-3 d-flex justify-content-center">
                                        {queryData[i - 1]['circleStatus'] === 'pending' && <>
                                            <div className="col-5">
                                                <button className="btnOrder w-100" >VENTER PÅ GODKJENNING</button>

                                            </div>
                                            {action && action === "multistatus" && <div className="col-5">
                                                <button className="btnOrder w-100" onClick={() => getCircleById(queryData[i - 1]['circleId'], 'multiple')} >GODKJENT </button>
                                            </div>}
                                        </>}
                                        {queryData[i - 1]['circleStatus'] === 'approved' && <div className="col-6">
                                            <button className="btnOrder w-100" >GODKJENT </button>

                                        </div>}
                                    </div>

                                </div>
                            )

                        }
                    }
                    else {
                        circlesArr2.push(val)
                        circleIdArr = 0
                        return (<div className='col-lg-6' key={val.circleId}>

                            <div className='d-flex align-items-center' >
                                <div id="pdfDivMain" className='w-100 '>
                                    <div className="nameDiv">
                                        <h4>{queryData[i - 1]['circleName']}</h4>
                                    </div>
                                    <div id="pdfContent" className="containerCircle my-5"  >
                                        <div className="iconCircle">
                                            {circlesArr.map((data, k) => <div className="imgBx" style={{
                                                width: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                height: (circlesArr.length === 2 && "150px") || (circlesArr.length === 4 && "90px") || (circlesArr.length === 6 && "90px") || (circlesArr.length === 12 && "70px"),
                                                transformOrigin: circlesArr.length === 2 ? "260px" : "235px",
                                                transform: `rotate(calc(360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                            }}>
                                                <img style={{
                                                    transform: `rotate(calc(-360deg /${(circlesArr.length <= 2 && 2) || (circlesArr.length === 4 && 4) || (circlesArr.length === 6 && 6) || (circlesArr.length === 12 && 12)}* ${k}))`
                                                }} src={data.imageURL.replace("{baseApiURL}", "https://funtlers.com/")} />
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row w-100 my-3 d-flex justify-content-center">
                                {queryData[i - 1]['circleStatus'] === 'pending' && <>
                                    <div className="col-5">
                                        <button className="btnOrder w-100" >VENTER PÅ GODKJENNING</button>

                                    </div>
                                    {action && action === "multistatus" && <div className="col-5">
                                        <button className="btnOrder w-100" onClick={() => getCircleById(queryData[i - 1]['circleId'], 'multiple')} >GODKJENT </button>

                                    </div>}
                                </>}
                                {queryData[i - 1]['circleStatus'] === 'approved' && <div className="col-6">
                                    <button className="btnOrder w-100" >GODKJENT </button>

                                </div>}
                            </div>


                        </div>
                        )
                    }

                })
                }
            </div>
        </LoadingOverlay>
    );
}

export default OrderPlan2;
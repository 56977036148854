import React, { useState } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import BrandLogo from "../../img/brand-logo-primary.png";
import Input from "../Input/Input";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import FetchService from "../../api/FetchService";

import visa from "../../img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg"
import mastercard from "../../img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
import oneCard from "../../img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg"
import secondCard from "../../img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
import PartnerImg from "../../img/Stripe_icon_-_square.svg";

let request = {
    email: "",
};

function Footer() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        request.email = email;
        request.subject = "Forespørsel om abonnement på nyhetsbrev";

        FetchService.SendEmailNewsletter(request).then((response) => {
            if (response) {
                alert("Takk for at du abonnerer på vårt nyhetsbrev");
                setEmail("");
            } else {
                alert("Feil. Vær så snill, prøv på nytt");
            }
        });
    };
    return (
        <>
            <footer>
                <div class="container-fluid fn-container">
                    <div class="footer-container">
                        <div class="footer-list">
                            <div class="logo-container">
                                <img src={BrandLogo} alt="flunters" />
                            </div>
                            <div class="info-block">
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                        <path d="M30.726,5.27A18,18,0,1,0,36,18,18,18,0,0,0,30.726,5.27Zm-3.466,19.8,0,0V25.07l-.912.906a4.8,4.8,0,0,1-4.53,1.3A15.949,15.949,0,0,1,17.184,25.2,21.4,21.4,0,0,1,13.53,22.25a21.565,21.565,0,0,1-2.73-3.312,16.8,16.8,0,0,1-2.052-4.152,4.8,4.8,0,0,1,1.2-4.9l1.068-1.068a.762.762,0,0,1,1.078,0l0,0,3.372,3.372a.762.762,0,0,1,0,1.078l0,0-1.98,1.98a1.614,1.614,0,0,0-.168,2.1,23.156,23.156,0,0,0,2.37,2.766,23.015,23.015,0,0,0,3.12,2.622,1.626,1.626,0,0,0,2.076-.18L22.8,20.612a.762.762,0,0,1,1.078,0l0,0L27.258,24A.762.762,0,0,1,27.26,25.073Z" transform="translate(0 0)" fill="#f68446"></path>
                                    </svg>
                                </span>
                                <span class="text-lbl">930 276 367</span>
                            </div>
                            <div class="info-block">
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                        <path d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm1.436,26.774a1.794,1.794,0,0,1-2.872,0c-2.111-2.821-5.806-8.166-5.806-11.024a7.242,7.242,0,1,1,14.484,0C25.242,18.608,21.547,23.953,19.436,26.774Z" fill="#f68446"></path>
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.527" cy="1.527" r="1.527" transform="translate(16.473 13.183)" fill="#f68446"></circle>
                                    </svg>
                                </span>
                                <span class="text-lbl">Boligveien 1, 3470 Slemmestad</span>
                            </div>
                            <div class="payment-methods">
                                <div class="img-container">
                                    <img src={visa} alt="Visa" />
                                    <img src={mastercard} alt="Mastercard" />
                                    <img src={oneCard} alt="First Card" />
                                    <img src={secondCard} alt="Second Card" />
                                </div>
                                <p class="text mb-0">Powered By Stripe</p>
                            </div>
                        </div>
                        <div class="footer-list">
                            <p class="text-title">FØLG OSS:</p>
                            <div class="social-links-container">
                                <ul class="list-inline">
                                    <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        href={"https://www.facebook.com/Funtlers-103331339270078"}
                                        className="social_a"
                                    >
                                        <li class="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                                <path id="Path_182" data-name="Path 182" d="M53.751,26.876A26.876,26.876,0,1,0,26.876,53.751c.157,0,.315,0,.472-.011V32.828H21.574V26.1h5.774V21.144c0-5.743,3.506-8.871,8.63-8.871a46.9,46.9,0,0,1,5.176.262V18.54H37.626c-2.782,0-3.328,1.323-3.328,3.265v4.283h6.666l-.871,6.729H34.3V52.712A26.884,26.884,0,0,0,53.751,26.876Z" fill="#f68446"></path>
                                            </svg>
                                        </li>
                                    </a>
                                    <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        href={"https://www.instagram.com/funtlers/?hl=en"}
                                        className="social_a"
                                    >
                                        <li class="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                                <circle cx="5.289" cy="5.289" r="5.289" transform="translate(21.586 21.586)" fill="#f68446"></circle>
                                                <path d="M32.876,6A26.876,26.876,0,1,0,59.751,32.876,26.876,26.876,0,0,0,32.876,6Zm16.6,33.706a10.169,10.169,0,0,1-2.709,6.917A10.356,10.356,0,0,1,39.78,49.3H25.972a10.353,10.353,0,0,1-6.991-2.68,10.168,10.168,0,0,1-2.709-6.917V26.045a10.169,10.169,0,0,1,2.709-6.917,10.355,10.355,0,0,1,6.991-2.68H39.78a10.353,10.353,0,0,1,6.991,2.68,10.169,10.169,0,0,1,2.709,6.917Z" transform="translate(-6 -6)" fill="#f68446"></path>
                                                <path d="M151.2,131.559c-3.571-.086-10.717-.086-14.288,0a8.1,8.1,0,0,0-5.288,1.7,6.26,6.26,0,0,0-1.948,4.619c-.093,3.078,0,12.317,0,12.317a6.29,6.29,0,0,0,1.948,4.619,8.1,8.1,0,0,0,5.288,1.7c3.571.086,10.717.086,14.288,0a8.1,8.1,0,0,0,5.288-1.7,6.26,6.26,0,0,0,1.948-4.619V137.88a6.26,6.26,0,0,0-1.948-4.619A8.1,8.1,0,0,0,151.2,131.559Zm-7.144,20.678c-5.129,0-9.287-3.671-9.287-8.2s4.158-8.2,9.287-8.2,9.287,3.671,9.287,8.2S149.189,152.237,144.059,152.237Zm9.327-14.7a1.653,1.653,0,1,1,1.858-1.64,1.759,1.759,0,0,1-1.858,1.64Z" transform="translate(-117.181 -117.163)" fill="#f68446"></path>
                                            </svg>
                                        </li>
                                    </a>

                                    <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        href={"https://www.linkedin.com/company/funtlers/"}
                                        className="social_a"
                                    >
                                        <li class="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="53.751" height="53.751" viewBox="0 0 53.751 53.751">
                                                <path id="Path_186" data-name="Path 186" d="M26.876,0A26.876,26.876,0,1,0,53.751,26.876,26.879,26.879,0,0,0,26.876,0Zm-7.81,40.628H12.52V20.936h6.545ZM15.793,18.247h-.043a3.411,3.411,0,1,1,.086-6.8,3.413,3.413,0,1,1-.043,6.8ZM42.667,40.628H36.123V30.094c0-2.648-.948-4.453-3.316-4.453a3.583,3.583,0,0,0-3.358,2.394,4.482,4.482,0,0,0-.215,1.6v11H22.688s.086-17.845,0-19.692h6.545v2.788a6.5,6.5,0,0,1,5.9-3.25c4.306,0,7.535,2.814,7.535,8.863Zm0,0" fill="#f68446"></path>
                                            </svg>
                                        </li>
                                    </a>

                                </ul>
                            </div>
                        </div>
                        <div class="footer-list">
                            <h4 class="title">Abonner på nyhetsbrev</h4>
                            <div class="contact-container">
                                <form onSubmit={handleSubmit} className="form_otr">
                                    <input class="form-control" Inputype="email"
                                        InputName="email"
                                        InputPlaceholder="Din mail her"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)} placeholder="Din mail he" />
                                    <button class="btn btn-primary">Abonner her</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/*
      <section className="footer_main">
        <div className="container">
          <div className="row row_custom">
            <div className="col-lg-5 col-md-6 col-sm-6 col_logo_otr">
              <div className="col_logo_inr">
                <Link to="/" className="logo_otr">
                  <img className="logo" src={BrandLogo} alt="logo" />
                </Link>

                <p className="desc2 heading-m">
                  Din markedsplass for sosiale aktiviteter
                </p>
                <p className="desc2 company-data">
                  FUNTLERS AS
                  <br />
                  Org.nr 930 276 367
                  <br />
                  Orestien 30C, 1387 Asker
                  <br />
                </p>
               
                <div className="payment_options">
                  <div className="d-flex flex-row">
                    <img src={visa} alt="Visa" />
                    <img src={mastercard} alt="Mastercard" />
                    <img src={oneCard} alt="First Card" />
                    <img src={secondCard} alt="Second Card" />
                  </div>
                  <p>DRIFTET AV STRIPE </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col_link_otr">
              <div className="col_link_inr">
                              <h3 className="heading-h3 heading">FØLG OSS</h3>
                              <ul className="social_ul">
                                  <li className="social_li">
                                      <a
                                          target={"_blank"}
                                          rel="noreferrer"
                                          href={"https://www.facebook.com/Funtlers-103331339270078"}
                                          className="social_a"
                                      >
                                          <i class="ri-facebook-circle-fill social_icon"></i>
                                      </a>
                                  </li>
                                  <li className="social_li">
                                      <a
                                          target={"_blank"}
                                          rel="noreferrer"
                                          href={"https://www.instagram.com/funtlers/?hl=en"}
                                          className="social_a"
                                      >
                                          <i class="ri-instagram-fill social_icon"></i>
                                      </a>
                                  </li>
                                  <li className="social_li">
                                      <Link className="social_a">
                                          <i class="ri-snapchat-fill social_icon"></i>
                                      </Link>
                                  </li>
                                  <li className="social_li">
                                      <a
                                          target={"_blank"}
                                          rel="noreferrer"
                                          href={"https://www.linkedin.com/company/funtlers/"}
                                          className="social_a"
                                      >
                                          <i class="ri-linkedin-fill social_icon"></i>
                                      </a>
                                  </li>
                              </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col_sub_otr">
              <div className="col_sub_inr">
                <h3 className="heading-h3 heading_sub">
                  Abonner på vårt nyhetsbrev!
                </h3>
                <p className="heading-s desc_sub">
                  Få de ferskeste oppdateringene fra oss
                </p>
                <form onSubmit={handleSubmit} className="form_otr">
                  <Input
                    InputClass="Theme_input_light"
                    Inputype="email"
                    InputName="email"
                    InputPlaceholder="Din mail her"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <ThemeBtn BtnClass="Theme_btn_primary" BtnText="Abonner nå" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copy_right_main">
          <div className="container">
            <div className="wrapper">
               <p className="heading-xs cr_text">
                Copyright © 2022{" "}
                <Link to="/" className="heading-xsb cr_text_inr">
                  FUNTLERS
                </Link>{" "}
                .
              </p>
            </div>
          </div>
        </div>
          </section>
      */ }
        </>
    );
}

export default Footer;

import React from "react";
import ThemeBtn from "../../components/ThemeBtn/ThemeBtn";
import { useNavigate } from "react-router-dom";
import "./AdminLandingPage.scss";

function AdminLandingPage() {
  const navigate = useNavigate();

  return (
    <div className="container-landing admin_landingpage">
      <h1 className="text-center admin_heading">Admin Dashboard</h1>
      <div className="body-landing">
        <div className="item-landing">
          <h2 className="text-center card_heading">Partnere</h2>
          <ThemeBtn
            onClick={() => {
              navigate("/legge-til-partner");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Legg til"
          />
          <ThemeBtn
            onClick={() => {
              navigate("/partner-dashbordet");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Rediger"
          />
          <ThemeBtn
            onClick={() => {
              navigate("/partner-dashbordet");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Se alle"
          />
        </div>
        <div className="item-landing">
          <h2 className="text-center card_heading">Aktiviteter</h2>
          <ThemeBtn
            onClick={() => {
              navigate("/add-aktivitet");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Legg til"
          />
          <ThemeBtn
            onClick={() => {
              navigate("/aktivitet-side");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Rediger"
          />
          <ThemeBtn
            onClick={() => {
              navigate("/aktivitet-side");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Se alle"
          />
        </div>
        <div className="item-landing">
          <h2 className="text-center card_heading">Sammendrag</h2>
          <ThemeBtn
            onClick={() => {
              navigate("/rekkefølge");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Salg historikk"
          />
          <ThemeBtn
             onClick={() => {
              navigate("/unike-koder");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Unike koder"
          />
          <ThemeBtn
            onClick={() => {
              alert("No Google Analytics embedded");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Besøksdata"
          />
        </div>
      
        {/* <div className="item-landing">
          <h2 className="text-center card_heading">Bilder</h2>
          <ThemeBtn
            onClick={() => {
              navigate("/bilder");
            }}
            BtnClass="Theme_btn_primary "
            BtnText="Rediger bilder"
          />
        </div> */}
          </div>
          <div className="body-landing">
              <div className="item-landing">
                  <h2 className="text-center card_heading">Nettsiden</h2>
                  <ThemeBtn
                      onClick={() => {
                          navigate("/søke");
                      }}
                      BtnClass="Theme_btn_primary "
                      BtnText="Kategori favoritter"
                  />
                  <ThemeBtn
                      onClick={() => {
                          navigate("/aktivitet-side");
                      }}
                      BtnClass="Theme_btn_primary "
                      BtnText="⁠Gavetips favoritter"
                  />

                  <ThemeBtn
                      onClick={() => {
                          navigate("/kuponger");
                      }}
                      BtnClass="Theme_btn_primary"
                      BtnText="Kuponger"
                  />
              </div>
              <div className="item-landing">
                  <h2 className="text-center card_heading">Bedriftskunder</h2>

                  <ThemeBtn
                      onClick={() => {
                          navigate("/legge-til-bedriftskunde");
                      }}
                      BtnClass="Theme_btn_primary"
                      BtnText="Legg til"
                  />



                  <ThemeBtn
                      onClick={() => {
                          navigate("/partner-dashbordet2");
                      }}
                      BtnClass="Theme_btn_primary"
                      BtnText="Redigere"
                  />
               
              </div>
              <div className="item-landing" style={{ border: '0px' }}></div>
          </div>
    </div>
  );
}

export default AdminLandingPage;